import axios, { AxiosRequestConfig } from 'axios';
import { getToken, parseResponseBody, trackApiResponse } from '../utils';
import { appInsights } from './appInsights';

async function post<TInput, TResponse>(url: string, data?: TInput): Promise<TResponse> {
    const authToken = getToken();

    const isFormData = data instanceof FormData;
    const config: AxiosRequestConfig = {
        method: 'POST',
        url,
        headers: {
            'Authorization': `Bearer ${authToken}`,
            ...(isFormData ? {} : { 'Content-Type': 'application/json' }),
            "Request-Id": appInsights.context?.telemetryTrace?.traceID ?? '',
        },
        data,
    };

    try {
        const response = await axios(config);
        return response.data as TResponse;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            const body = parseResponseBody(error.response);
            trackApiResponse(url, "POST", body?.status, body?.data);
            throw body;
        }

        throw error;
    }
}

export default post;
