import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormGroup, InputGroup, Row } from 'reactstrap';

import { SolidButton } from '../../../../AbstractElement';
import CommonSelect from '../../../../CommonComponent/CommonSelect';
import { useCoreData } from '../../../../Hooks';
import { mutations, queries } from '../../../../api';
import { useLayoutContext } from '../../../../views/LayoutProvider';
import { BusinessAccountInput } from '../../../../Types/Profile';
import useToast from '../../../../views/ToastWrapper';
import { useStateContext } from '../../../../views/stateProvider';

export const Contact = () => {

    const { t } = useTranslation();
    const { showToast } = useToast();
    const { toggleProfileSetting } = useStateContext();

    const { phoneCountryCodeOptions } = useCoreData({ loadCountries: true });
    const { mutate: updateAccount, isLoading: updateLoading } = mutations.useUpdateAccount();

    const [selectedCountryCodeOptions, setSelectedCountryCodeOptions] = useState<string[] | string>();
    const { data: userDetails } = queries.useGetUserDetails();

    const { isMobile } = useLayoutContext()

    const { register, handleSubmit, setValue } = useForm<BusinessAccountInput>({
        defaultValues: {
            firstName: '',
            lastName: '',
            title: '',
            website: '',
            phone: '',
            phoneCountryCode: ''
        }
    });

    useEffect(() => {
        if (userDetails) {
            setValue('firstName', userDetails.firstName!);
            setValue('lastName', userDetails.lastName!);
            setValue('title', userDetails.title!);
            setValue('website', userDetails.website!);
            setValue('phone', userDetails.phone!);
            setValue('phoneCountryCode', userDetails.phoneCountryCode!);
            setSelectedCountryCodeOptions(userDetails.phoneCountryCode!)
        }
    }, [userDetails, setValue]);

    const onSubmit: SubmitHandler<BusinessAccountInput> = async (data) => {
        updateAccount({ ...userDetails, ...data }, {
            onSuccess: () => {
                showToast('success', "ProfileSettings.UpdatedSuccessfully");
            }
        })
    };

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className="setting-main pb-3">
                <Row className='g-sm-4 g-3'>
                    <Col sm="6">
                        <FormGroup className='mb-0'>
                            <label>{t("FirstName")}</label>
                            <input type="text" className="form-control" id="firstName" {...register("firstName")} placeholder={t("EnterFirstName")} autoComplete="off" />
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup className='mb-0'>
                            <label>{t("LastName")}</label>
                            <input type="text" className="form-control" id="lastName" {...register("lastName")} placeholder={t("EnterLastName")} autoComplete="off" />
                        </FormGroup>
                    </Col>
                    {isMobile &&
                        <Col sm="6">
                            <FormGroup className='mb-0'>
                                <label>{t("EmailId")}</label>
                                <input type="email" className="form-control" id="emailAddress" placeholder={t("EnterEmailAddress")} defaultValue={userDetails?.email} autoComplete="off" />
                            </FormGroup>
                        </Col>
                    }
                    <Col sm="6">
                        <FormGroup className='mb-0'>
                            <label>{t("Title")}</label>
                            <input type="text" className="form-control" id="title" {...register("title")} placeholder={t("EnterTitle")} autoComplete="off" />
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup className='mb-0'>
                            <label>{t("Website")}</label>
                            <input type="text" className="form-control" id="website" {...register("website")} placeholder={t("EnterWebSite")} autoComplete="off" />
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup className="phone-detail mb-0">
                            <label>{t("PhoneNumberId")}</label>
                            <InputGroup>
                                <div className="dropdown-box">
                                    <CommonSelect
                                        placeholder={t('Select')}
                                        options={phoneCountryCodeOptions!}
                                        selectedOptions={selectedCountryCodeOptions}
                                        setSelectedOptions={(value) => {
                                            setSelectedCountryCodeOptions(value);
                                            setValue('phoneCountryCode', value);
                                        }}
                                        multiSelect={false}
                                        search={true}
                                    />
                                    <input type="number" className="form-control" id="phone" {...register("phone")} placeholder={t("5005000000")} autoComplete="off" />
                                </div>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            <div className="profile-setting-footer-main">
                <div className="profile-setting-footer">
                    <SolidButton className='btn-gray' onClick={toggleProfileSetting}>{t("Cancel")}</SolidButton>
                    <SolidButton type='submit' loading={updateLoading}>{t("Update")}</SolidButton>
                </div>
            </div>
        </Form>
    )
}