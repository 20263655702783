import Swal from "sweetalert2";
import { SITE_SETTINGS } from "../Constants";

interface ConfirmDialogProps {
    title: string;
    text: string;
    confirmButtonText: string;
    onConfirm: (isConfirmed: boolean, checkValue?: boolean) => void;
    confirmBtnClass?: string;
    showCheckbox?: boolean;
}

const ConfirmationDialog: React.FC<ConfirmDialogProps> = ({ title, text, confirmButtonText, onConfirm, confirmBtnClass = "black", showCheckbox = false }) => {

    Swal.fire({
        title: title,
        html: `<div>
                    <p class="finalize-invoice-text">${text}</p>
                    ${showCheckbox ? `
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="term-check"/>
                            <label for="term-check" class="cursor-pointer">I read and agree to 
                                <a href="${SITE_SETTINGS.SERVICE_CONTRACT}" target='_blank' class='text-primary-color-1'>The Service Contract Terms</a>
                            </label>
                        </div>` : ''}
                </div>`,
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: "Cancel",
        reverseButtons: true,
        customClass: {
            popup: "delete-modal",
            cancelButton: "btn btn-gray custom-cancel",
            closeButton: "custom-close",
            confirmButton: `btn btn-primary ${showCheckbox ? "" : `custom-confirm-${confirmBtnClass}`} disable-check`,
        },
        preConfirm: () => {
            if (showCheckbox) {
                const checkbox = document.getElementById('term-check') as HTMLInputElement;
                return checkbox ? checkbox.checked : false;
            }
            return true;

        }
    }).then((result) => {
        if (result.isConfirmed) {
            onConfirm(true, result.value);
        }
    });

    const checkbox = document.getElementById('term-check') as HTMLInputElement;
    if (checkbox) {
        checkbox.onchange = () => {
            const confirmButton = Swal.getConfirmButton();
            if (checkbox.checked) {
                confirmButton?.classList.add('custom-confirm-orange');
                confirmButton?.classList.remove('disable-check');
            } else {
                confirmButton?.classList.remove('custom-confirm-orange');
                confirmButton?.classList.add('disable-check');
            }
        };
    }
    return null;
};

export default ConfirmationDialog;
