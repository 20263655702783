import { ReactNode } from "react";
import { Client } from "./Client";


export interface SelectProps {
    selectedOption?: number | string;
    options: any[] | undefined;
    onSelect: (option: number | string) => void;
    className?: string;
}

export interface OpenModalProps {
    id?: string;
    mode?: 'edit' | 'view';
    toggle: boolean;
}

export interface ProjectModalProps extends Omit<OpenModalProps, 'mode'> {
}

export type ProjectDocumentModalMode = ObjectValues<'edit' | 'upload'>;
export interface ProjectDocumentModalProps extends Omit<OpenModalProps, 'mode'> {
    projectId?: string;
    mode?: ProjectDocumentModalMode;
}

export interface SettingCanvasProps {
    setting: boolean;
    toggleSetting: VoidFunction;
}

export interface ContextProviderProps {
    children: ReactNode;
}

export interface CommonPaginationProps {
    totalItems: number;
    currentPage: number;
    setCurrentPage: (page: number) => void;
    itemsPerPage: number;
    setItemsPerPage: (value: number) => void;
}


// Define interfaces for the column and action objects
export interface ColumnDataField<T> {
    field: keyof T;
    params?: any;
    class?: string;
    type?: ColumnDataType;
    renderer?: (data: any) => ReactNode;
}

export type Column<T> = {
    title: string;
    sortField?: keyof T;
    dataField: ColumnDataField<T>[];
    class?: string;
    sortable?: boolean;
    sortDirection?: 'asc' | 'desc';
    hidden?: boolean;
}

export interface Action<T = any> {
    label: string;
    actionToPerform?: string;
    icon?: string;
    conditional?: Conditional<T>;
    getNavigateUrl?: (data: T) => string;
}

export interface Conditional<T> {
    field: keyof T;
    condition: '!=' | '?' | 'include' | 'notInclude' | '===';
    conditionValue?: string[];
    actionLabel?: string;
}

export interface ITableConfig<T = any> {
    columns: Column<T>[];
    actionsDropDown: Action<T>[];
    data: T[];
    total: number;
    isColumnChooser: boolean;
}

export type TableConfig<T> = ITableConfig & {
    columns: Column<T>[];
    data: T[];
}

export interface ToastProps {
    message: string;
    className?: string;
}

export interface InvitationToastProps {
    text: string;
    mail?: string;
    className?: string;
}

export const COLUMN_TYPE = {
    Amount: "amount",
    Date: "date",
    TextProfile: "text_profile",
};

export interface TableClickedAction<T> {
    actionToPerform: string;
    data: T;
}

export interface ClientInformationProps {
    title?: string;
    clientData?: Client;
}

export type ColumnDataType = ObjectValues<typeof COLUMN_TYPE> | '';
