export const KEYS = {
    ACCOUNT: "account",
    ACTIVITIES: "activities",
    ARCHIVED_INVOICES: "archived-invoices",
    CALENDAR_ACTIVITIES: "calendar-activities",
    AGREEMENTS: "agreement",
    DASHBOARD_CHART: "dashboard-chart",
    VERIFICATION_TOKEN: "generate-verification-token",
    SEND_EMAIL_VERIFICATION_PIN: "email-verification-pin",
    SEND_PIN: "account-send-pin",
    SEND_PIN_BY_EMAIL: "account-send-pin-email",
    SEND_PASSWORD_MAIL: "forgot-password-request",
    RESET_PASSWORD: "reset-password",
    BANK_ACCOUNT: "bank-account",
    COUNTRIES: "countries",
    CURRENCIES: "currencies",
    CURRENCIES_PAYIN: "currencies-payin",
    CURRENCIES_PAYOUT: "currencies-payout",
    CURRENCIES_PAYINOUT: "currencies-payinout",
    PROFESSIONS: "professions",
    INDUSTRIES: "industries",
    CUSTOMER: "customer",
    CUSTOMERS_PROJECT: "customer-project",
    INVOICES: "invoices",
    INVOICE: "invoice",
    INVOICE_CREATE: "invoice-create",
    INVOICE_UPDATE: "invoice-update",
    CHECK_PROMOTION_CODE: "check-promotion-code",
    INVOICE_HISTORY: "invoice-history",
    INVOICE_CALCULATE_AMOUNT: "invoice-calculate-amount",
    INVOICE_SEND: "invoice-send",
    INVOICE_DELETE: "invoice-delete",
    INVOICE_DOWNLOAD_PDF: "invoice-download-pdf",
    INVOICE_SEND_REMINDER: "invoice-send-reminder",
    INVOICE_LINE: "invoice-line",
    INVOICE_LINE_CREATE: "invoice-line-create",
    INVOICE_LINE_DELETE: "invoice-line-delete",
    INVOICE_LINE_UPDATE: "invoice-line-update",
    INVOICE_UNITS: "invoice-units",
    LANGUAGES: "languages",
    PAYMENT_COMMISION_RATES: "payment-commision-rates",
    SERVICE_FEES: "service-fees",
    TIME_ZONES: "time-zones",
    TRANSACTION: "transaction",
    INVOICE_UNIT_CODES: "invoice-unit-codes",
    UPDATE_PASSWORD: "update-password",
    INVOICE_SCOPE: "invoice-scope",
    INVOICE_SCOPE_BY_ID: "invoice-scope-by-id",
    INVOICE_SCOPE_DOWNLOAD_PDF: "invoice-scope-download-pdf",
    INVOICE_SCOPE_LOG: "invoice-scope-log",
    INVOICE_SCOPE_LOG_LAST_INVOICE: "invoice-scope-log-last-invoice",
    CURRENCY_PAYOUT_TYPES: "currency-payout-types",
    VERIFY_EMAIL: "verify-email",
    INVOICE_PDF: "invoice-pdf",
    INVOICE_SCOPE_PDF: "invoice-scope-pdf",
    INVOICE_SCOPE_DUPLICATE: "invoice-scope-duplicate",
    INVOICE_SCOPE_SEND_REMINDER: "invoice-scope-send-reminder",
    INVOICE_SCOPE_ARCHIVE: "invoice-scope-archive",
    INTERNATIONAL_BRANCH: "international-branch",
    CALCULATE_INTERNATIONAL_BRANCH: "calculate-international-branch",
    INVOICE_SCOPE_FAST: "invoice-scope-fast",
    INVOICE_SCOPE_FAST_PAYMENT: "invoice-scope-fast-payment",
    INVOICE_SCOPE_FAST_DECLINE: "invoice-scope-fast-decline",
    INVOICE_DECLINE_REASONS: "invoice-decline-reasons",
    EXPERTISE_AREAS: "expertise-areas",
    INVOICE_FREQUENCIES: "invoice-frequencies",
    INVOICE_AMOUNT_ESTIMATES: "invoice-amount-estimates",
    CHANGE_SERVICE_FEE_PAYER: "change-service-fee-payer",
    CHANGE_PAYOUT_BANK_ACCOUNT: "change-payout-bank-account",
    DEBIT_CARD: "debit-card",
    IBAN_APPLICATION: "iban-application",
    FX_SIMULATE: "fx-simulate",
    HAS_QUESTIONNAIRE: "has-questionnaire",
    USER_AGREEMENT: "user-agreement",
    USER_AGREEMENT_DOWNLOAD: "user-agreement-download",
    PROJECT: "project",
    PROJECT_DOCUMENTS: "project-documents",
    PROJECT_DOCUMENT: "project-document",
    PROJECT_UPLOAD_DOCUMENT: "project-upload-document",
    PROJECT_DELETE_DOCUMENT: "project-delete-document",
    PROJECT_CATEGORIES: "project-categories",
    PROJECT_ARCHIVE: "project-archive",
    PROJECT_DOCUMENT_DOWNLOAD: "project-document-download",
    FREELANCERS: "freelancers",
    FREELANCER: "freelancer",
    FREELANCER_PROJECT: "freelancer-project",
    FREELANCER_INVITE: "freelancer-invite",
    FREELANCER_ARCHIVE: "freelancer-archive",
    BUSINESS_SIGNUP: "business-signup",
    QUICK_INVOICE: "quick-invoice",
    SHARE_FREELANCERS: "share-freelancers",
    SUGGESTION: "suggestion",
    PROJECT_INVITATION: "project-invitation",
    USER_ACCOUNT_DELETION_REASONS: "user-account-deletion-reasons",
    PAYMENT_AGREEMENTS:"payment-agreements",
    UPLOAD_PROFILE:"upload-profile",
    PROFILE_IMAGE:"profile-image",
    DELETE_PROFILE:"profile-delete"
} as const;
