import { Col, Row } from 'reactstrap';
import { USER_ROLE } from '../../../../Types';
import ImageSection from '../../Widgets/ImageSection';
import { LogoWrapper } from '../../Widgets/LogoWrapper';
import VerifyForm from './VerifyForm';

const VerifyAccount = () => {
    return (
        <div className="log-in-section">
            <Row className="login-content g-0">
                <Col xl="5" lg="6" className="auth-left custom-width-card">
                    <div className="login-main">
                        <LogoWrapper tabBadge />
                        <VerifyForm />
                    </div>
                </Col>
                <ImageSection userName={USER_ROLE.Business} bannerClass='business-banner' />
            </Row>
        </div>
    )
}
export default VerifyAccount;