import React from "react";
import { useTranslation } from "react-i18next";

import { Image } from "../../AbstractElement";
import { queries } from "../../api";
import BusinessCanvas from "../../Components/Profile/Business";
import FreelancerCanvas from "../../Components/Profile/Freelancer/ProfileSettings";
import { SITE_SETTINGS } from "../../Constants";
import { useAuth, useCoreData, useDropdown } from "../../Hooks";
import { USER_ROLE } from "../../Types";
import { dynamicImage } from "../../utils";
import { useLayoutContext } from "../../views/LayoutProvider";
import { useStateContext } from "../../views/stateProvider";

const ProfileSection: React.FC<{ hideSettings?: boolean }> = ({ hideSettings }) => {
  const { t } = useTranslation();
  const { profileSetting, toggleProfileSetting } = useStateContext();
  const { toggleState } = useLayoutContext();
  const { signout, user } = useAuth();
  const { data: userDetails } = queries.useGetUserDetails();
  const { data: ProfileImageData } = queries.useGetProfileImage(userDetails?.hasProfileImage ?? false)
  const { fullName } = useCoreData({ loadUserDetails: true });

  // Define the logout handler
  const logOutHandler = () => {
    signout();
  };

  // Use the custom hook for dropdown
  const profileDropdown = useDropdown<HTMLLIElement>(toggleState);

  const openProfileSettings = () => {
    toggleProfileSetting(1);
  };
  const openBankAccountTab = () => {
    toggleProfileSetting(5);
  };

  return (
    <li className="profile-box onhover-dropdown" ref={profileDropdown.ref}>
      <div>
        <div className={`profile-main-box upload-profile-box ${user?.role === USER_ROLE.Business ? 'business-header' : ''}`} onClick={profileDropdown.toggle}>
          {userDetails?.hasProfileImage && ProfileImageData ?
            <Image className="nav-profile-box" src={`data:image/png;base64, ${ProfileImageData}`} alt="profile" />
            : (<>{fullName ?    
              (<div className="initial-letter">
                <span>{fullName?.split(' ')?.map((letter: string) => letter.charAt(0).toUpperCase())?.join('')?.slice(0, 2)}</span>
              </div>) :
              (<Image src={dynamicImage("icon_user.png")} className="profile-image nav-profile-box" alt="logo" />)
            }</>)
          }
          {user?.role === USER_ROLE.Business && <span className="profile-user-name">{userDetails?.companyName}</span>}
        </div>
      </div>
      {profileDropdown.isOpen && (
        <ul className="profile-box-menu onhover-dropdown-menu open">
          {!hideSettings &&
            <>
              <li onClick={openProfileSettings}>{t("ProfileSetting")}</li>
              {user?.role === USER_ROLE.Freelancer && <li onClick={openBankAccountTab}>{t("BankAccounts")}</li>}
            </>
          }
          <li className="cky-banner-element cursor-pointer">{t("CookiePreferences")}</li>
          <li><a href={SITE_SETTINGS.PRIVACY_POLICY} target='_blank'>{t("PrivacyPolicy")}</a></li>
          <li><a href={SITE_SETTINGS.TERMS_CONDITIONS} target='_blank'>{t("TermsAndConditions")}</a></li>
          <li onClick={logOutHandler}>{t("SignOut")}</li>
        </ul>
      )}
      {profileSetting.toggle && (user?.role === USER_ROLE.Business ? <BusinessCanvas /> : <FreelancerCanvas defaultTab={profileSetting.selectedTab} />)}
    </li>
  );
};

export default ProfileSection;
