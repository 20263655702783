import { STORAGE_KEYS } from "../Constants";
import { Image } from "../AbstractElement";
import { useTranslation } from "react-i18next";
import { InvitationToastProps, ToastProps } from "../Types/CommonComponent";
import React from "react";
import { t } from "i18next";
import { convertToReadableAmount } from "./convertToReadableAmount";
import { convertToReadableDate } from "./convertToReadableDate";
import { getLocale } from "./getLocale";
import { getTimeZone } from "./getTimeZone";
import { parseResponseBody } from "./parseResponseBody";
import { trackApiResponse } from "./trackApiResponse";
import { CombinedErrorResponse, DefaultErrorResponse, FormErrorResponse, Message, ResponseParserWrapper } from "../Types/ErrorResponse";

//dynamic image
const images = require.context(`/public/assets/images`, true);

export const dynamicImage = (image: string) => {
  return images(`./${image}`);
};

//get storage
export const getStorage = () => {
  return localStorage;
}

//get token
export const getToken = () => {
  const storage = getStorage();
  const stringifiedUser = storage.getItem(STORAGE_KEYS.USER);
  if (!stringifiedUser || stringifiedUser === "undefined") return null;
  const user = JSON.parse(stringifiedUser);
  return user.authToken;
}

// Success Toast
export const SuccessToast: React.FC<ToastProps> = ({ message, className }) => {
  const { t } = useTranslation();
  return (
    <div className={`info-note ${className ? className : ""}`}>
      <div className="info-img">
        <Image src={dynamicImage("svg/info-circle.svg")} alt="logo" />
      </div>
      {t(message)}
    </div>
  );
};

// Error Toast
export const ErrorToast: React.FC<ToastProps> = ({ message, className }) => {
  const { t } = useTranslation();

  return (
    <div className={`invalid-note ${className ? className : ""}`}>
      <div className="error-img">
        <Image src={dynamicImage("svg/info.svg")} alt="logo" />
      </div>
      {t(message)}
    </div>
  );
};

// Verified Toast
export const VerifiedToast: React.FC<{ text: string, className?: string }> = ({ text, className }) => {
  const { t } = useTranslation();

  return (
    <div className={`success-note ${className ? className : ""}`}>
      <div className="success-img">
        <Image src={dynamicImage("svg/tick.svg")} alt="logo" />
      </div>
      {t(text)}
    </div>
  );
};

// Complete Toast
export const CompleteToast: React.FC<{ text: string, className?: string }> = ({ text, className }) => {
  const { t } = useTranslation();

  return (
    <div className={`complete-note ${className ? className : ""}`}>
      <div className="complete-img">
        <Image src={dynamicImage("svg/tick.svg")} alt="logo" />
      </div>
      {t(text)}
    </div>
  );
};

// Delete Toast
export const DeleteToast: React.FC<{ text: string, className?: string }> = ({ text, className }) => {
  const { t } = useTranslation();

  return (
    <div className={`delete-note ${className ? className : ''}`}>
      <div className="delete-img">
        <Image src={dynamicImage("svg/tick.svg")} alt="logo" />
      </div>
      {t(text)}
    </div>
  );
};

// Invitation Toast
export const InvitationToast: React.FC<InvitationToastProps> = ({ text, mail, className }) => {
  const { t } = useTranslation();

  return (
    <div className={`warning-note ${className ? className : ""}`}>
      <div className="warning-img">
        <Image src={dynamicImage("svg/tick.svg")} alt="logo" />
      </div>
      <p className="mb-0" dangerouslySetInnerHTML={{ __html: t("InvitationToastText", { text: text, mail: mail }) }} />
    </div>
  );
};

// Get First Two Letter from Name
export const getInitials = (name: string) => {
  const names = name.split(" ");
  return names
    .map((n) => n[0])
    .join("")
    .toUpperCase();
};

// Error Message
export const getMessages = (arr: CombinedErrorResponse | ResponseParserWrapper<Message> | Message[] | null): string => {
  if (!arr) return "Unknown error occurred"; // Handle cases where there's no error

  function getOpts(values?: string[]) {
    if (!values) return;
    return values.reduce((acc, cur, idx) => {
      return {
        ...acc,
        [idx + 1]: cur
      };
    }, {});
  }
  let errors: Message[] = [];

  if (Array.isArray((arr as DefaultErrorResponse).data))
    errors = (arr as DefaultErrorResponse).data;
  else if (Array.isArray(arr))
    errors = arr;

  if (errors.length > 0) {
    return errors.map((item) => t(item.code, getOpts(item.values))).join(", "); // Join both code and message
  }
  // else if ((arr as ResponseParserWrapper<Message>).data != null) {
  //   console.log("single")
  //   return (arr as ResponseParserWrapper<Message>).data?.code;
  // }
  else {
    var errorData = (arr as FormErrorResponse)?.data;
    let formattedMessages: string[] = [];
    for (let key in errorData) {
      if (Array.isArray(errorData[key])) {
        errorData[key].forEach(error => {
          formattedMessages.push(t(`${error.code}`, { 1: t(key) }));
        });
      }
    }
    if (formattedMessages.length > 0)
      return `${formattedMessages?.join('\n')}`
  }

  // something happend on server side
  return "Seems like an unknown error occurred on server side!";
};


//get params
export const getParam = (key: string): string | null => {
  const href = window.location.href;
  const url = new URL(href);
  return url.searchParams.get(key) ?? null;
}

// date & time format
export const formatDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hourCycle: "h23",
  };
  return date.toLocaleDateString('en-US', options);
};

export const formatTime = (date: Date) => {
  return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hourCycle: "h23" });
};

export { convertToReadableAmount, convertToReadableDate, getLocale, getTimeZone, parseResponseBody, trackApiResponse }

export function formatPercentage(value: number): number | string {
  return value % 1 !== 0 ? parseFloat(value.toFixed(0)) : Math.floor(value);
}


export function updateAmount(value: number): number | string {
  return value;
}
