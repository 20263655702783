import React from 'react'
import { TabContent, TabPane } from 'reactstrap'
import Personal from './Personal'
import Password from './Password'
import BankAccounts from './BankAccounts'
import Documents from '../../../Widgets/Documents'
import InvoiceSettings from './InvoiceSettings'
import Address from './Address'

export const Tabs: React.FC<{ active: number }> = ({ active }) => {
    return (
        <TabContent activeTab={active} id="pills-tabContent" className='form-mb-73'>
            <TabPane tabId={1} id="pills-personal" >
                <Personal />
            </TabPane>
            <TabPane tabId={2} id="pills-Address" >
                <Address />
            </TabPane>
            <TabPane tabId={3} id="pills-InvoiceSettings" >
                <InvoiceSettings />
            </TabPane>
            <TabPane tabId={4} id="pills-Documents" className='document-profile-tab' >
                <Documents />
            </TabPane>
            <TabPane tabId={5} id="pills-BankAccounts" className="bank-account-tab">
                <BankAccounts />
            </TabPane>
            <TabPane tabId={6} id="pills-Password" >
                <Password />
            </TabPane>
        </TabContent>
    )
}
