import { getStorage } from ".";
import { STORAGE_KEYS } from "../Constants";

const defaultLocale = { code: "en-US" };
export function getLocale(): string | null {
    const storage = getStorage();

    let value = storage.getItem(STORAGE_KEYS.LOCALE);
    if (!value) {
        const json = JSON.stringify(defaultLocale);
        storage.setItem(STORAGE_KEYS.LOCALE, json);
        value = json;
    }
    const locale = JSON.parse(value);
    return locale.code;
}
