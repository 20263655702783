import { SvgType } from "../../Types/CommonElement";

const SVG = (props: SvgType) => {
  const { iconId, ...res } = props;
  return (
    <svg {...res}>
      <use href={`/assets/spriteSvg/icon-sprite.svg#${iconId}`}></use>
    </svg>
  );
};

export default SVG;
