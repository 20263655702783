import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Container, Form } from 'reactstrap';
import { Image, LinkButton, SolidButton } from '../../../../AbstractElement';
import { mutations } from '../../../../api';
import { ROUTES, SITE_SETTINGS } from '../../../../Constants';
import { useAuth } from '../../../../Hooks';
import { dynamicImage, getMessages, getParam } from '../../../../utils';
import { useStateContext } from '../../../../views/stateProvider';
import useToast from '../../../../views/ToastWrapper';
import InvoiceDetailCanvas from '../../../Projects/Canvas/InvoiceDetailCanvas';
import OTPInput from '../../Widgets/OTPInput';

export const BusinessLoginWithPin = ({ setTabSelect }: any) => {
    const [otpValues, setOtpValues] = useState<string[]>(Array(6).fill(""));
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState(0);
    const { t } = useTranslation();
    const { showToast } = useToast();
    const navigate = useNavigate();

    const { toggleInvoiceDetail, invoiceDetail } = useStateContext();

    const { mutate: requestPin } = mutations.useSendPin()
    const { pinSignin, signout } = useAuth();

    const { handleSubmit } = useForm();

    const isSubmitDisabled = otpValues.some((value) => !value.trim());

    useEffect(() => {
        requestNewPin(false);
    }, []);

    // Timer countdown effect
    useEffect(() => {
        if (timer === 0) return;
        const intervalId = setInterval(() => setTimer((prev) => prev - 1), 1000);
        return () => clearInterval(intervalId);
    }, [timer]);


    // Request new PIN
    const requestNewPin = (showError = true) => {
        requestPin(undefined, {
            onSuccess: () => setTimer(SITE_SETTINGS.RESEND_TIMEOUT),  // Reset timer to 60 seconds
        });
    };

    // Handle submit
    const submitHandler = () => {
        setLoading(true)
        const pin = otpValues.join("");  // Combine OTP input values into a single string
        const invoiceScopeId = getParam("invoiceScopeId");
        pinSignin(
            pin,
            () => {
                setLoading(false)
                if (invoiceScopeId) {
                    toggleInvoiceDetail()
                } else {
                    navigate(ROUTES.Dashboard)
                }
            },
            (error) => {
                setLoading(false)
                setIsError(true)
                showToast("error", getMessages(error));
            }
        );
    };


    return (
        <>
            <div className="review-invoice">
                <div className="auth-main h-100 invoice-review approve-invoice1 custom-scrollbar">
                    <div className="logo-wrapper">
                        <div onClick={() => signout()}>
                            <Image src={dynamicImage("svg/logo/logo.svg")} alt="logo" />
                        </div>
                    </div>
                    <Container>
                        <div className='invoice-approved security-pin'>
                            <div className='main-invoice custom-scrollbar'>
                                <div className="verification-form security-verify">
                                    <Form noValidate onSubmit={handleSubmit(submitHandler)} className="custom-form-w">
                                    <div className="title mx-xxl-5 mx-6">
                                        <h3>{t("PleaseEnterTheSecurityPinWeSentToYourEmailAddress")}</h3>
                                    </div>
                                        <OTPInput val={otpValues} setVal={setOtpValues} isError={isError} setIsError={setIsError} />
                                        <div className="resend-code">
                                            <div>
                                                <p dangerouslySetInnerHTML={{ __html: t("TheCodeWeSentYouWillExpireInSeconds", { timer }) }} />
                                                <p>{t("IfYourPinDoesNotWorkRequestNewOne")}</p>
                                            </div>
                                            {timer === 0 ? (
                                                <span className="resend" onClick={() => requestNewPin()}>{t("ResendCode")}</span>
                                            ) : (
                                                <span>{t("ResendCode", { timer })}</span>
                                            )}
                                        </div>
                                        <div className="action-btn">
                                            <SolidButton type="submit" disabled={isSubmitDisabled} loading={loading}>
                                                {t("LogIn")}
                                            </SolidButton>
                                            <SolidButton className="btn-gray" onClick={() => signout()}>{t("SignOut")}</SolidButton>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
            {invoiceDetail && <InvoiceDetailCanvas />}
        </>
    );
};

