export const PAYMENT_TYPE = {
    Transfer: "WireTransfer",
    Credit: "CreditCard"
};

export type PaymentType = ObjectValues<typeof PAYMENT_TYPE>;

export type CalculateAmountResult = {
    amount: number;
    commissionAmount: number;
    total: number;
    commissionRate: number;
};
