import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Image } from "../../../AbstractElement";
import { dynamicImage } from "../../../utils";
import WaitingList from "../WaitingList";
import SettingCanvas from "./SettingCanvas";
import { appInsights } from "../../../api/appInsights";
import { useStateContext } from "../../../views/stateProvider";

const SidebarMenuBottom = () => {
  const { t } = useTranslation();
  const { sidebarSetting, toggleSidebarSetting } = useStateContext();
  const { dismissAll } = useStateContext();

  const IS_REFERRAL_ENABLED = process.env.REACT_APP_IS_REFERRAL_ENABLED ?? false;
  const AFFILIATE_URL = process.env.REACT_APP_AFFILIATE_URL ?? "";

  return (
    <Fragment>
      <div>
        <ul className="sidebar-links">
          {IS_REFERRAL_ENABLED && <li className="sidebar-list">
            <Link
              className={`sidebar-link`}
              to={AFFILIATE_URL}
              onClick={(event) => {
                event.preventDefault();
                appInsights.trackEvent({
                  name: `Clicked button: Refer to a friend`,
                  properties: { RedirectUrl: AFFILIATE_URL }
                });
                window.open(AFFILIATE_URL, '_blank', 'noopener,noreferrer');
              }}
            >
              <Image src={dynamicImage("svg/admin/refer.svg")} alt="refer" />
              <span>{t("ReferToFriend")}</span>
            </Link>
          </li>}

          <li className="sidebar-list cursor-pointer" onClick={() => { (window as any)?.Beacon('open'); }}>
            <div className="sidebar-link">
              <Image src={dynamicImage("svg/admin/help.svg")} alt="help" />
              <span>{t("Help")}</span>
            </div>
          </li>
          <li className="sidebar-list cursor-pointer" onClick={() => { dismissAll(); toggleSidebarSetting(); }}>
            <div className="sidebar-link">
              <Image src={dynamicImage("svg/admin/settings.svg")} alt="settings" />
              <span>{t("Settings")}</span>
            </div>
          </li>
        </ul>
        <WaitingList />
      </div>
      {sidebarSetting && <SettingCanvas />}
    </Fragment>
  );
};

export default SidebarMenuBottom;
