import { useTranslation } from 'react-i18next';
import { Image } from '../../../../AbstractElement';
import { dynamicImage } from '../../../../utils';
import { Header } from '../../Widgets/Header';
import { Container } from 'reactstrap';
import EmailVerificationForm from './EmailVerificationForm';

export const EmailVerification = () => {
    const { t } = useTranslation();

    return (
        <div className="vh-100">
            <div className="auth-main h-100">
                <Image src={dynamicImage("svg/leaf.svg")} alt="logo" className="leaf" />
                <Header />
                <div className="auth-body h-100">
                    <Container className="h-100">
                        <div className="auth-body-title t-padding b-padding">
                            <div className="auth-sub-title">
                                <h1>{t("Verifications")}</h1>
                            </div>
                        </div>
                        <EmailVerificationForm />
                    </Container>
                </div>
            </div>
        </div>
    );
}
