import { FC } from "react";
import { Link } from "react-router-dom";

import { LinkButtonProps } from "../../Types/CommonElement";

export const LinkButton: FC<LinkButtonProps> = ({ children, className, onClick, to }) => {
  return (
    <Link to={to ? to : ""} onClick={onClick} className={`btn ${className && (className.includes('btn-solid') || className.includes('btn-success'))? '' : 'btn-gray'} ${className ? className : ''}`}>
      {children}
    </Link>
  );
};