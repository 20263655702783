import { createContext, useContext, useState } from "react";
import { InvoiceHistory, InvoiceScope } from "../Types";
import { ContextProviderProps, OpenModalProps, ProjectDocumentModalMode, ProjectDocumentModalProps, ProjectModalProps } from "../Types/CommonComponent";
import { InvoiceLine } from "../Types/InvoiceItem";



type StateContextType = {
    invoiceScope: InvoiceScope | null;
    setInvoiceScope: React.Dispatch<React.SetStateAction<InvoiceScope | null>>;
    invoiceScopeLog: InvoiceHistory[] | null;
    setInvoiceScopeLog: React.Dispatch<React.SetStateAction<InvoiceHistory[] | null>>;
    invoiceLines: InvoiceLine[] | [];
    setInvoiceLines: React.Dispatch<React.SetStateAction<InvoiceLine[] | []>>;

    projectModal: ProjectModalProps;
    toggleProjectModal: (id?: string) => void;
    projectDocumentModal: ProjectDocumentModalProps;
    toggleProjectDocumentModal: (projectId?: string, id?: string, mode?: ProjectDocumentModalMode) => void;
    invoiceDetail: boolean;
    toggleInvoiceDetail: VoidFunction
    viewInvoice: boolean;
    toggleInvoiced: VoidFunction;
    nestedModal: boolean;
    toggleNested: VoidFunction;
    inviteFreelancer: boolean;
    toggleFreelancerInvitation: VoidFunction;
    promoCode: string;
    setPromoCode: (v: any) => void;
    viewFreelancerDetails: boolean;
    toggleFreelancerDetails: VoidFunction;
    accountDeleteReasonModal: boolean;
    setAccountDeleteReasonModal: React.Dispatch<React.SetStateAction<boolean>>;
    toggleAccountDeleteReasonModal: VoidFunction;
    accountDeleteConfirmReason: string;
    accountDeleteConfirmModal: boolean;
    setAccountDeleteConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
    toggleAccountDeleteConfirmModal: (reason?: string) => void;
    editFreelancerDetails: boolean;
    setEditFreelancerDetails: React.Dispatch<React.SetStateAction<boolean>>;
    toggleEditFreelancerDetails: VoidFunction;
    invitationPopup: boolean;
    setInvitationPopup: React.Dispatch<React.SetStateAction<boolean>>;
    toggleInvitationPopup: VoidFunction;
    dismissAll: VoidFunction;
    confirmationDialog: boolean;
    toggleConfirmationDialog: VoidFunction;
    clientDetails: OpenModalProps;
    toggleClientDetails: (id?: string) => void;
    toggleAddNewCustomer: (id?: string) => void;
    profileSetting: { selectedTab: number, toggle: boolean };
    toggleProfileSetting: (selectedTab?: number) => void;
    sidebarSetting: boolean;
    toggleSidebarSetting: VoidFunction;
    emailChangeModal: boolean;
    toggleEmailChangeModal: VoidFunction;
    emailChangeOtpModal: { verificationToken?: string; email?: string; toggle: boolean };
    toggleEmailChangeOtpModal: (verificationToken?: string, email?: string) => void;
    emailChangeSuccessModal: boolean;
    toggleEmailChangeSuccessModal: VoidFunction;
    changeProfilePhotoModal: boolean;
    toggleProfilePhotoModal: VoidFunction;
};

const StateContext = createContext<StateContextType | undefined>(undefined);

export const StateProvider: React.FC<ContextProviderProps> = ({ children }) => {
    const [invoiceScope, setInvoiceScope] = useState<InvoiceScope | null>(null);
    const [invoiceScopeLog, setInvoiceScopeLog] = useState<InvoiceHistory[] | null>(null);
    const [invoiceLines, setInvoiceLines] = useState<InvoiceLine[] | []>([]);
    const [promoCode, setPromoCode] = useState('');

    //client

    const [clientDetails, setClientDetails] = useState<OpenModalProps>({ toggle: false });
    const toggleClientDetails = (id?: string) => setClientDetails((prev) => ({ id: id, mode: 'view', toggle: !!id || !prev.toggle }));
    const toggleAddNewCustomer = (id?: string) => setClientDetails((prev) => ({ id: id, mode: 'edit', toggle: !!id || !prev.toggle }));

    //Project
    const [projectModal, setProjectModal] = useState<ProjectModalProps>({ toggle: false });
    const toggleProjectModal = (id?: string) => setProjectModal((prev) => ({ id: id, toggle: !!id || !prev.toggle }));

    //upload doc
    const [projectDocumentModal, setProjectDocumentModal] = useState<ProjectDocumentModalProps>({ toggle: false });
    const toggleProjectDocumentModal = (projectId?: string, id?: string, mode?: ProjectDocumentModalMode) => setProjectDocumentModal((prev) => ({ projectId: projectId, id: id, mode: mode, toggle: !!id || !prev.toggle }));

    //modal
    const [invoiceDetail, setInvoiceDetail] = useState(false);
    const toggleInvoiceDetail = () => setInvoiceDetail(!invoiceDetail);

    const [viewInvoice, setViewInvoice] = useState(false);
    const toggleInvoiced = () => {
        setViewInvoice(!viewInvoice);
    };

    const [nestedModal, setNestedModal] = useState(false);
    const toggleNested = () => {
        setNestedModal(!nestedModal);
    };

    // For Business User
    const [inviteFreelancer, setInviteFreelancer] = useState(false);
    const toggleFreelancerInvitation = () => setInviteFreelancer(!inviteFreelancer);

    const [viewFreelancerDetails, setViewFreelancerDetails] = useState(false)
    const toggleFreelancerDetails = () => setViewFreelancerDetails(!viewFreelancerDetails);

    const [editFreelancerDetails, setEditFreelancerDetails] = useState(false)
    const toggleEditFreelancerDetails = () => setEditFreelancerDetails(!editFreelancerDetails);

    //Account Delete
    const [accountDeleteReasonModal, setAccountDeleteReasonModal] = useState(false);
    const toggleAccountDeleteReasonModal = () => setAccountDeleteReasonModal(!accountDeleteReasonModal)

    const [accountDeleteConfirmModal, setAccountDeleteConfirmModal] = useState(false);
    const [accountDeleteConfirmReason, setAccountDeleteConfirmReason] = useState('');
    const toggleAccountDeleteConfirmModal = (reason?: string) => {
        if (!accountDeleteConfirmModal && reason) {
            setAccountDeleteConfirmReason(reason!);
            setAccountDeleteConfirmModal(true);
        }
        else
            setAccountDeleteConfirmModal(false)
    }

    //Email Change
    const [emailChangeModal, setEmailChangeModal] = useState(false)
    const toggleEmailChangeModal = () => setEmailChangeModal(!emailChangeModal)

    const [emailChangeOtpModal, setEmailChangeOtpModal] = useState<{ verificationToken?: string; email?: string; toggle: boolean }>({ toggle: false })
    const toggleEmailChangeOtpModal = (verificationToken?: string, email?: string) => setEmailChangeOtpModal({ verificationToken: verificationToken, email: email, toggle: !!verificationToken })

    const [emailChangeSuccessModal, setEmailChangeSuccessModal] = useState(false)
    const toggleEmailChangeSuccessModal = () => setEmailChangeSuccessModal(!emailChangeSuccessModal)

    //Change Profile Photo
    const [changeProfilePhotoModal, setChangeProfilePhotoModal] = useState(false)
    const toggleProfilePhotoModal = () => setChangeProfilePhotoModal(!changeProfilePhotoModal)

    //Invitation to project
    const [invitationPopup, setInvitationPopup] = useState(false)
    const toggleInvitationPopup = () => setInvitationPopup(!invitationPopup)

    //Confirmation Dialog
    const [confirmationDialog, setConfirmationDialog] = useState(false)
    const toggleConfirmationDialog = () => setConfirmationDialog(!confirmationDialog)

    //profile settings
    const [profileSetting, setSetting] = useState<{ selectedTab: number, toggle: boolean }>({ selectedTab: 1, toggle: false });
    const toggleProfileSetting = (selectedTab?: number) => setSetting((prev) => ({ selectedTab: selectedTab ?? 1, toggle: !prev.toggle }));

    //sidebar setting
    const [sidebarSetting, setSidebarSetting] = useState(false);
    const toggleSidebarSetting = () => setSidebarSetting(!sidebarSetting);

    const dismissAll = () => { setViewInvoice(false); setInvoiceDetail(false); setClientDetails({ toggle: false }); setEditFreelancerDetails(false); setViewFreelancerDetails(false); setInviteFreelancer(false); setSetting({ selectedTab: 1, toggle: false }); setProjectModal({ toggle: false }); setSidebarSetting(false); }

    return (
        <StateContext.Provider value={{
            dismissAll, toggleAddNewCustomer, toggleClientDetails, clientDetails, profileSetting, toggleProfileSetting, toggleSidebarSetting, sidebarSetting,
            promoCode, setPromoCode, invoiceLines, setInvoiceLines, invoiceScope, setInvoiceScope, invoiceScopeLog, setInvoiceScopeLog, projectModal, toggleProjectModal, projectDocumentModal, toggleProjectDocumentModal, toggleInvoiceDetail, invoiceDetail, toggleInvoiced, viewInvoice,
            nestedModal, toggleNested, toggleFreelancerInvitation, inviteFreelancer, viewFreelancerDetails, toggleFreelancerDetails, accountDeleteReasonModal,
            setAccountDeleteReasonModal, toggleAccountDeleteReasonModal, accountDeleteConfirmModal, accountDeleteConfirmReason, setAccountDeleteConfirmModal, toggleAccountDeleteConfirmModal, editFreelancerDetails, setEditFreelancerDetails, toggleEditFreelancerDetails, invitationPopup, setInvitationPopup, toggleInvitationPopup, confirmationDialog, toggleConfirmationDialog, emailChangeModal, toggleEmailChangeModal, emailChangeOtpModal, toggleEmailChangeOtpModal, emailChangeSuccessModal, toggleEmailChangeSuccessModal, changeProfilePhotoModal, toggleProfilePhotoModal
        }}>
            {children}
        </StateContext.Provider>
    );
};

export const useStateContext = (): StateContextType => {
    const context = useContext(StateContext);
    if (!context) {
        throw new Error("useStateContext must be used within a StateProvider");
    }
    return context;
};