import { ApexOptions } from "apexcharts";
import { dynamicImage } from "../../utils";

export const suggestionChartOption: ApexOptions = {
  series: [70],
  chart: {
    height: 300,
    type: "radialBar",
  },
  plotOptions: {
    radialBar: {
      hollow: {
        size: "60%",
        image: dynamicImage("svg/bird.svg"),
        imageWidth: 60,
        imageHeight: 60,
        imageClipped: false,
      },
      dataLabels: {
        name: {
          show: false,
          color: "#fff",
        },
        value: {
          show: false,
          color: "#333",
          offsetY: 70,
          fontSize: "22px",
        },
      },
    },
  },
  labels: ["Volatility"],
  colors: ["#16b0c1"],
  responsive: [
    {
      breakpoint: 575,
      options: {
        radialBar: {
          hollow: {
            size: "40%",
            imageClipped: true,
            imageWidth: 40,
            imageHeight: 40,
          },
        },
        chart: {
          width: '160'
        }
      }
    }
  ],
};

export const statusChartOptions: ApexOptions = {
  series: [],
  chart: {
    type: 'bar',
    height: 100,
    toolbar: {
      show: false,
    },
  },
  xaxis: {
    categories: [],
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },

  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 2,
      borderRadiusApplication: 'end',
      borderRadiusWhenStacked: 'all',
      columnWidth: '70%',
      barHeight: '70%',
      distributed: false,
      rangeBarOverlap: true,
      rangeBarGroupRows: false,
      hideZeroBarsWhenGrouped: false,
      isDumbbell: false,
      dumbbellColors: undefined,
      isFunnel: false,
      isFunnel3d: true,
    }
  },
  dataLabels: {
    enabled: false
  },
  legend: {
    show: false,
  },
  grid: {
    show: false,
  }
};

//profile
export const ProfileTabs = [
  { id: 1, title: "Personal" },
  { id: 2, title: "Address" },
  { id: 3, title: "InvoiceSettings" },
  { id: 4, title: "Documents" },
  { id: 5, title: "BankAccounts" },
  { id: 6, title: "JuuliAccount" },
];

//profile
export const BusinessTabs = [
  { id: 1, title: "Contact" },
  { id: 2, title: "BusinessProfileBig" },
  { id: 3, title: "Documents" },
];
