import React, { createContext, useContext, useEffect, useState } from 'react';
import { LayoutContextProps } from '../Types';
import { ContextProviderProps } from '../Types/CommonComponent';

// Create the layout context with a default value
const LayoutContext = createContext<LayoutContextProps>({} as LayoutContextProps);

const LayoutProvider: React.FC<ContextProviderProps> = ({ children }) => {

  const [openSidebar, setOpenSidebar] = useState(window.innerWidth < 1441);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [position, setPosition] = useState<"bottom" | "end">((window.innerWidth < 768) ? "bottom" : "end")
  const [modalPosition, setModalPosition] = useState<"bottom" | "center">((window.innerWidth < 768) ? "bottom" : "center")


  useEffect(() => {

    let previousWidth = window.innerWidth;
    const handleResize = () => {

      const currentWidth = window.innerWidth;

      if (previousWidth >= 1441 && currentWidth < 1441) {
        setOpenSidebar(true);
      } else if (previousWidth < 1441 && currentWidth >= 1441) {
        setOpenSidebar(false);
      }
      setIsMobile(currentWidth < 768);
      if (currentWidth < 768) {
        setPosition("bottom");
        setModalPosition("bottom");
      } else {
        setPosition("end");
        setModalPosition("center");
      }
      previousWidth = currentWidth;
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar)
  };

  const toggleState = () => { };

  const handleSidebarItemClick = () => {
    if (window.innerWidth < 768) {
      setOpenSidebar(!openSidebar)
    }
  };

  useEffect(() => {
    // Define a function to check screen width
    const checkScreenSize = () => {
      if (window.innerWidth < 1441) {
        setOpenSidebar(true)
      }
    };

    // Run this function on initial loadinvoice-approved
    checkScreenSize();

    // Add an event listener for window resize
    window.addEventListener('resize', checkScreenSize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const dropDownOverlay = (dropdownOpen: boolean) => {
    const bgOverlay = document.getElementById('bg-overlay');

    if (window.innerWidth < 768 && dropdownOpen) {
      bgOverlay?.classList.add('active');
    } else {
      bgOverlay?.classList.remove('active');
    }
  };
  

  const [profileBox, setProfileBox] = useState(false);
  const toggleProfileBox = () => setProfileBox((prev) => !prev);

  const [notificationBox, setNotificationBox] = useState(false);
  const toggleNotificationBox = () => setNotificationBox((prev) => !prev);

  const [profileNav, setProfileNav] = useState(1);
  const activeProfileNav = (id: number) => setProfileNav(id);

  const [newPassword, setNewPassword] = useState(false);
  const toggleNewPassword = () => setNewPassword((prev) => !prev);

  const [bankAction, setBankAction] = useState(false);
  const toggleBankAction = () => setBankAction((prev) => !prev);

  //Questionnaire
  const [questionnaireModal, setQuestionnaireModal] = useState(false);
  const toggleQuestionnaireModal = () => setQuestionnaireModal(!questionnaireModal);

  return (
    <LayoutContext.Provider
      value={{
        toggleSidebar,
        toggleProfileBox,
        profileBox,
        notificationBox,
        toggleNotificationBox,
        profileNav,
        activeProfileNav,
        newPassword,
        toggleNewPassword,
        bankAction,
        toggleBankAction,
        setProfileBox,
        toggleState,
        dropDownOverlay,
        questionnaireModal,
        toggleQuestionnaireModal,
        setQuestionnaireModal,
        openSidebar,
        setOpenSidebar,
        isMobile,
        position,
        handleSidebarItemClick,
        modalPosition,

      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;

export const useLayoutContext = (): LayoutContextProps => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayoutContext must be used within a LayoutContextProvider');
  }
  return context;
};