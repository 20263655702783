import React from 'react'
import { UserRole } from '../Types';
import { useAuth } from '../Hooks';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from '../Constants';

const UserRoleWrapper: React.FC<{ role?: UserRole; children: React.ReactElement }> = ({ role, children }) => {
    const { user } = useAuth();
    const location = useLocation();

    if (!user) {
        return <Navigate to={ROUTES.FreelancerLogIn} state={{ from: { location } }} replace={true} />;
    }

    if (user.role !== role && role) {
        return <Navigate to={ROUTES.Home} state={{ from: { location } }} replace={true} />;
    }

    return children;
};

export default UserRoleWrapper;
