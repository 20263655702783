import React from "react";
import { useTranslation } from "react-i18next";
import { Image } from "../../../AbstractElement";
import { ImageSectionProp } from "../../../Types/Auth/Auth";
import { dynamicImage } from "../../../utils";

const ImageSection: React.FC<ImageSectionProp> = ({ userName, bannerClass }) => {
  const { t } = useTranslation();
  return (
    <div className="d-xl-flex d-none auth-right col-xxl-7 col-xl-6" >
      <div className="image-main">
        <div className={`image-contain ${bannerClass ? bannerClass : ""}`}>
          <h2 className="image-title">{t("UserEnjoyingFinancialFreedom", { user: userName })}</h2>
          <Image src={dynamicImage(`svg/banner/${userName === "Freelancers" ? "freelencer" : "business"}-profile.png`)} alt="profile" className="img-fluid freelence-profile" />
          <div className="auth-content">
            <Image src={dynamicImage("svg/quote.svg")} className="quote" alt="quotes" />
            <p>{t("IReceiveMmyPayment")}</p>
            <div className="review">
              <ul className="rate">
                <li>
                  <Image src={dynamicImage("svg/star-white.svg")} alt="white-star" />
                </li>
                <li>
                  <Image src={dynamicImage("svg/star-white.svg")} alt="white-star" />
                </li>
                <li>
                  <Image src={dynamicImage("svg/star-white.svg")} alt="white-star" />
                </li>
                <li>
                  <Image src={dynamicImage("svg/star-white.svg")} alt="white-star" />
                </li>
                <li>
                  <Image src={dynamicImage("svg/star-white.svg")} alt="white-star" />
                </li>
              </ul>
              <span className="review-time">{t("DaysAgo", { day: 2 })}</span>
            </div>
            <div className="divider"></div>
            <div className="trusted">
              <Image src={dynamicImage("svg/star-fill.svg")} alt="fill-star" />
              {t("Trustpilot")}
            </div>
          </div>
          <span className="help-div" >
            <Image src={dynamicImage("svg/icon_help1.svg")} alt="fill-star" onClick={() => { (window as any)?.Beacon('open'); }} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ImageSection;
