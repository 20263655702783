import { getStorage } from "../utils";

const useStorage = () => {
    const setItem = (key: string, value: string) => {
        getStorage().setItem(key, value);
    };

    const getItem = (key: string) => {
        const value = getStorage().getItem(key);
        return value;
    };

    const removeItem = (key: string) => {
        getStorage().removeItem(key);
    };

    return { setItem, getItem, removeItem };
};

export default useStorage;
