import { FC } from 'react'
import { DOCUMENT_STATUS, DocumentStatus } from '../../Types';
import { useTranslation } from 'react-i18next';

export const DocumentStatusBadge: FC<{ documentStatus: DocumentStatus }> = ({ documentStatus }) => {
    const { t } = useTranslation();

    function getClassName(status: DocumentStatus) {
        switch (status) {
            case DOCUMENT_STATUS.NA:
            case DOCUMENT_STATUS.Signed:
                return "signed-badge";
            case DOCUMENT_STATUS.Generating:
                return "active-badge";
            case DOCUMENT_STATUS.Sent:
                return "sent-badge";
            // case DOCUMENT_STATUS.NA:
            //     return "transparent";
            default:
                return "";
        }
    }

    return (
        <div className={`badge text-capitalize justify-content-center ${getClassName(documentStatus)}`}>
            {t(`DocumentStatus.${documentStatus}`)}
        </div>
    )
}
