import { useTranslation } from "react-i18next"

export const LayoutLoader: React.FC<{ text?: string }> = ({ text }) => {

    const { t } = useTranslation()

    return (
        <div className="offcanvas-loader">
            <span className="spinner-loader"></span>
            <p>{t(`${text ? text : "Loading"}`)}</p>
        </div>
    )
}
