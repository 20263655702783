import { ROUTES } from "../../Constants";
import { SidebarListType, USER_ROLE } from "../../Types";

export const MenuList: SidebarListType[] = [
  {
    id: 1,
    title: "Dashboard",
    url: ROUTES.Dashboard,
    icon: "dashboard.svg",
    icon2: "dashboard-active.svg",
    type: "link",
  },
  {
    id: 2,
    title: "Projects",
    url: ROUTES.Project + "?tab=home",
    icon: "project.svg",
    icon2: "project-active.svg",
    type: "link",
  },
  {
    id: 3,
    title: "Invoices",
    url: ROUTES.Project + "?tab=invoices",
    icon: "invoice.svg",
    icon2: "invoice-active.svg",
    type: "link",
    role: USER_ROLE.Freelancer
  },
  {
    id: 4,
    title: "Clients",
    url: ROUTES.Client,
    icon: "client.svg",
    icon2: "client-active.svg",
    type: "link",
    role: USER_ROLE.Freelancer
  },
  {
    id: 4,
    title: "Freelancers",
    url: ROUTES.Freelancer,
    icon: "client.svg",
    icon2: "client-active.svg",
    type: "link",
    role: USER_ROLE.Business
  },
  //TODO:It can be connected later
  // {
  //   id: 5,
  //   title: "Finance",
  //   url: ROUTES.Finance,
  //   icon: "finance.svg",
  //   icon2: "finance-active.svg",
  //   type: "link",
  // },
  // {
  //   id: 6,
  //   title: "Money",
  //   url: ROUTES.Money,
  //   icon: "money.svg",
  //   icon2: "money-active.svg",
  //   type: "link",
  // },
];
