import axios, { AxiosRequestConfig } from "axios";

import { getToken, parseResponseBody, trackApiResponse } from "../utils";
import { appInsights } from "./appInsights";

async function remove<T>(url: string): Promise<T> {
    const token = getToken();
    const config: AxiosRequestConfig = {
        method: "DELETE",
        url,
        headers: {
            Authorization: `Bearer ${token}`,
            "Request-Id": appInsights.context?.telemetryTrace?.traceID ?? '',
        }
    };

    try {
        const resp = await axios(config);
        return resp.data;
    } catch (error: any) {

        // Handle errors from Axios
        if (axios.isAxiosError(error) && error.response) {
            const body = parseResponseBody(error.response);
            trackApiResponse(url, "DELETE", body?.status, body?.data);
            // Throwing the error with status and data from the response
            throw body;
        }

        throw error;
    }
}

export default remove;