import { useTranslation } from 'react-i18next'
import { Container } from 'reactstrap'

import { Image } from '../../../../AbstractElement'
import { dynamicImage } from '../../../../utils'
import { Header } from '../../Widgets/Header'
import UpdatePasswordForm from './UpdatePasswordForm'
import { useLayoutContext } from '../../../../views/LayoutProvider'

const UpdatePassword = () => {

    const { t } = useTranslation();
    const { isMobile } = useLayoutContext()

    return (
        <div className="vh-100">
            <div className="auth-main h-100">
                <Image src={dynamicImage("svg/flower.svg")} alt="logo" className="flower" />
                {!isMobile && <span className="help-div">
                    <Image src={dynamicImage("svg/icon_help1.svg")} alt="fill-star" onClick={() => { (window as any)?.Beacon('open'); }}/>
                </span>}
                <Header />
                <div className="auth-body h-100">
                    <Container className="h-100">
                        <div className="auth-body-title t-padding b-padding">
                            <div className="auth-sub-title">
                                <h1>{t("ResetYourPassword")}</h1>
                            </div>
                        </div>
                        <UpdatePasswordForm />
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default UpdatePassword;
