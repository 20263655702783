import { mutations } from "../../../../api"
import { useEffect, useState } from "react"
import useToast from "../../../../views/ToastWrapper"
import { useNavigate, useParams } from "react-router-dom"
import { Container } from "reactstrap"
import { ROUTES } from "../../../../Constants"

const PaymentAgreement = () => {
    const navigate = useNavigate();
    const { showToast } = useToast()
    const { id: invoiceScopeId } = useParams<{ id: string }>();

    const [agreementUrl, setAgreementUrl] = useState<string | null>(null);
    const { mutate: generateSignatureLink } = mutations.useGenerateSignatureLink();

    // Function to handle the ceremony completed event
    function handleCeremonyCompleted(event: any) {
        // TODO: Invoice detail must be opened.
        navigate(ROUTES.Dashboard);
    }

    // Function to handle the ceremony canceled event
    function handleCeremonyCanceled(event: any) {
        showToast("delete", "Contract was canceled by the user.");
    }

    // Function to handle the ceremony failed event
    function handleCeremonyFailed(event: any) {
        const { error_type, error_message } = event.data;
        showToast('error', `Contract failed with error: ${error_type} - ${error_message}`);
    }

    // Function to handle incoming message events
    function handleMessage(event: any) {
        const { type } = event.data;

        switch (type) {
            case 'ceremony.completed':
                handleCeremonyCompleted(event);
                break;
            case 'ceremony.canceled':
                handleCeremonyCanceled(event);
                break;
            case 'ceremony.failed':
                handleCeremonyFailed(event);
                break;
            default:
                console.warn(`Unknown event type: ${type}`);
        }
    }

    useEffect(() => {
        // Add event listener to window for message events
        window.addEventListener('message', handleMessage, false);
        return () => window.removeEventListener('message', handleMessage);
    }, []);

    useEffect(() => {
        if (invoiceScopeId)
            generateSignatureLink({ id: invoiceScopeId }, {
                onSuccess: (response) => {
                    setAgreementUrl(response.url);
                },
            });
    }, [invoiceScopeId]);

    return (
        <Container fluid>
            <div className="agreement-payment-box">
                <div className="custom-scroll">
                    {agreementUrl && (
                        <iframe
                            id="payment-agreement"
                            title="Payment Agreement"
                            width="100%"
                            height="100%"
                            src={`${agreementUrl}&embedded=true&event_delivery=message&accent_color=ff4a29`}
                            style={{ border: 'none', marginTop: '0px' }}
                        />
                    )}
                </div>
            </div>
        </Container>
    )
}

export default PaymentAgreement
