export const BASE_URL = process.env.REACT_APP_API_URL;

const URL = {
    Auth: {
        FreelancerSignUp: "Auth/SignUp",
        FreelancerLogin: "Auth/Login",
        SendForgotPasswordMail: "Auth/SendTheForgotPasswordMail",
        FreelancerResetPassword: "Auth/ResetPassword",
        SendPinToBusinessByEmail: "Auth/SendPinToBusinessByEmail",
        SendBusinessPin: "Auth/SendPinToBusiness",
        BusinessLogin: "Auth/LoginForBusiness",
        SigninLinkedin: "Auth/ExternalLogin/LinkedIn",
        SigninGoogle: "Auth/ExternalLogin/Google",
        SigninBusiness: "Auth/ExternalLogin/EncryptionLink",
        SigninWithExternalPin: "Auth/ExternalLogin/SignInWithPin",
        BusinessSignUp: "Auth/SignUpForBusiness",
        VerifySignUpForBusiness: "Auth/VerifySignUpForBusiness",
    },
    Account: {
        AccountInfo: "Account",
        FreelancerEmailVerificationPin: "Account/SendPinForEmailVerification",
        FreelancerEmailVerification: "Account/VerifyEmail",
        GenerateVerificationToken: "Account/GenerateVerificationToken",
        Questionnaire: "Account/Questionnaire",
        UpdatePassword: "Account/UpdatePassword",
        DebitCard: "Account/DebitCardApplication",
        DebitCardStatus: "Account/DebitCardApplicationStatus",
        IbanApplicationStatus: "Account/IBANApplicationStatus",
        IbanApplication: "Account/IBANApplication",
        Activities: "Account/Activities",
        CalendarActivities: "Account/CalendarActivities",
        DashboardChart: "Account/Dashboard",
        Suggestion: "Account/Suggestion",
        DeleteAccount: "Account/DeleteAccount",
        SendEmailChangePin: "Account/SendEmailChangePin",
        ChangeEmail: "Account/ChangeEmail"

    },
    UserAgreement: {
        UserAgreement: "UserAgreement",
        MissingAgreements: "UserAgreement/MissingAgreements",
        ApproveAgreement: "UserAgreement/Approve",
    },
    Lookup: {
        Currencies: "Lookup/Currencies",
        Countries: "Lookup/Countries",
        ExpertiseAreas: "Lookup/ExpertiseAreas",
        InvoiceFrequency: "Lookup/InvoiceFrequency",
        InvoiceAmountEstimates: "Lookup/InvoiceAmountEstimates",
        Professions: "Lookup/Professions",

        InvoiceUnitCodes: "Lookup/InvoiceUnitCodes",
        Languages: "Lookup/Languages",
        TimeZones: "Lookup/TimeZones",
        InvoiceStatusReasons: "Lookup/InvoiceStatusReasons",
        ProjectCategories: "Lookup/ProjectCategories",
        Industries: "Lookup/Industries",
        CurrencyPayoutTypes: "Lookup/CurrencyPayoutTypes",
        Customers: "Lookup/Customers",
        UserAccountDeletionReasons: "Lookup/UserAccountDeletionReasons",
    },
    InvoiceScope: {
        InvoiceScope: "InvoiceScope",
    },
    InvoiceScopeLog: {
        InvoiceScopeLogById: "InvoiceScopeLog",
        LastInvoiceLog: "InvoiceScopeLog/LastInvoice"
    },
    DraftInvoice: {
        Invoice: "Invoice",
        QuickInvoice: "Invoice/QuickInvoice",
    },
    Transaction: {
        Transaction: "Transaction"
    },
    Customer: {
        Customer: "Customer"
    },
    BankAccount: {
        BankAccount: 'BankAccount'
    },
    Project: {
        Project: 'Project'
    },
    Freelancer: {
        Freelancer: "Freelancer"
    },
    InvoiceLine: {
        Invoice: "Invoice",
    },
    InvoiceScopeFast: {
        InvoiceScopeFast: "InvoiceScopeFast"
    }


} as const;

// Construct the URL object
export const URL_KEYS: { [K in keyof typeof URL]: { [P in keyof typeof URL[K]]: string } } =
    Object.fromEntries(
        Object.entries(URL).map(([key, subKeys]) => [
            key,
            Object.fromEntries(
                Object.entries(subKeys).map(([subKey, path]) => [
                    subKey,
                    `${BASE_URL}${path}`
                ])
            )
        ])
    ) as { [K in keyof typeof URL]: { [P in keyof typeof URL[K]]: string }
    };
