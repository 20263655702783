import { appInsights } from "../api/appInsights";
import { HTTP_STATUS } from "../Constants";

export function trackApiResponse(url: string, httpMethod: string, status: number, data: any) {
    if (status === HTTP_STATUS.UNAUTHORIZED)
        return;

    try {
        var dataString = !data ? 'An unknown error has occurred. Response is empty' : JSON.stringify(data);
        var properties = { Url: url, HttpMethod: httpMethod, ResultCode: status };

        if (status === HTTP_STATUS.BAD_REQUEST)
            appInsights.trackTrace({
                message: `Api response error: ${dataString}`,
                properties: {
                    ...properties,
                    Data: dataString
                }
            });
        else
            appInsights.trackException({ error: new Error(dataString), properties: properties });

    } catch (error: any) {
        appInsights.trackException({ error: new Error(error) });
    }
};