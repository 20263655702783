import { FC } from "react";
import { Badge } from "reactstrap";

import { BadgesProps } from "../../Types/CommonElement";

export const Badges: FC<BadgesProps> = ({ children, color, ...props }) => {
  return (
    <Badge color={color ? color : "transparent"} {...props}>
      {children}
    </Badge>
  );
};
