import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import getPasswordStrength from "../Hooks/usePasswordStrength";

const LoginSchema = () => {
  const { t } = useTranslation();

  // Business Login Schema
  const EmailSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required(t("IsRequired", { name: "Email" })),
  });

  // Freelancer Login Schema
  const FreelancerLoginSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required(t("IsRequired", { name: "Email" })),
    password: Yup.string().required(t("IsRequired", { name: "Password" })),
  });

  // Create password
  const CreateNewPasswordSchema = Yup.object().shape({
    password: Yup.string().required(t("IsRequired", { name: "Password" })),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password")],
        t("PasswordsMustMatch", { password: "Password" })
      )
      .required(t("IsRequired", { name: "Confirm Password" })),
  });

  //Business signup
  const BusinessSignUpSchema = Yup.object().shape({
    name: Yup.string().required(t("IsRequired", { name: "Business name" })),
    email: Yup.string()
      .email(t("Email.InvalidFormat"))
      .required(t("IsRequired", { name: "Email" })),
    countryCode: Yup.string().required(t("IsRequired", { name: "Country" })),
    state: Yup.string().required(t("IsRequired", { name: "State" })),
    city: Yup.string().required(t("IsRequired", { name: "City" })),
    postalCode: Yup.string().required(t("IsRequired", { name: "Postal code" })),
    addressLine1: Yup.string().required(
      t("IsRequired", { name: "Address line 1" })
    ),
    terms: Yup.bool()
      .oneOf([true], t("YouMustAcceptTheTermsAndConditions"))
      .required(),
  });

  // Freelancer signup
  const FreelancerSignUpSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("Email.InvalidFormat"))
      .required(t("IsRequired", { name: "Email" })),
    password: Yup.string()
      .required(t("IsRequired", { name: "Password" }))
      .min(8, t("PasswordMustBeAtLeast8Characters"))
      .test("password-strength", t("PasswordIsTooWeak"), (value) => {
        const strength = getPasswordStrength(value);
        return strength === "strong" || strength === "medium";
      }),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password")],
        t("PasswordsMustMatch", { password: "Password" })
      )
      .required(t("IsRequired", { name: "Confirm Password" })),
    terms: Yup.bool()
      .oneOf([true], t("YouMustAcceptTheTermsAndConditions"))
      .required(),
  });

  // Update password
  const UpdatePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required(
      t("IsRequired", { name: "Old Password" })
    ),
    newPassword: Yup.string()
      .required(t("IsRequired", { name: "New Password" }))
      .min(8, t("PasswordMustBeAtLeast8Characters"))
      .test("password-strength", t("PasswordIsTooWeak"), (value) => {
        const strength = getPasswordStrength(value);
        return strength === "strong" || strength === "medium";
      }),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("newPassword")],
        t("PasswordsMustMatch", { password: "New password" })
      )
      .required(t("IsRequired", { name: "Confirm Password" })),
  });

  const EmailChangeSchema = Yup.object().shape({
    password: Yup.string().required(
      t("IsRequired", { name: "Current Password" })
    ),
    email: Yup.string()
    .email(t("Email.InvalidFormat"))
    .required(t("IsRequired", { name: "Email" })),
  
  });

  return {
    EmailSchema,
    FreelancerLoginSchema,
    CreateNewPasswordSchema,
    FreelancerSignUpSchema,
    BusinessSignUpSchema,
    UpdatePasswordSchema,
    EmailChangeSchema
  };
};

export default LoginSchema;
