import { useTranslation } from "react-i18next"
import { useLayoutContext } from "../../../../../views/LayoutProvider"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { Image, SolidButton } from "../../../../../AbstractElement"
import { useStateContext } from "../../../../../views/stateProvider"
import { dynamicImage } from "../../../../../utils"

const EmailChangeSuccess = () => {
    const { emailChangeSuccessModal, toggleEmailChangeSuccessModal } = useStateContext()
    const { modalPosition } = useLayoutContext()
    const { t } = useTranslation()

    return (
        <Modal centered dir={modalPosition} isOpen={emailChangeSuccessModal} toggle={toggleEmailChangeSuccessModal} className="theme-modal email-success account-modal">
            <ModalHeader toggle={toggleEmailChangeSuccessModal}>{t("ChangeEmailAddress")}</ModalHeader>
            <ModalBody>
                <div className="deletion">
                    <Image src={dynamicImage("success.png")} className="profile-image" alt="logo" />
                    <p>{t("EmailAddressSuccesfullyChanged")}</p>
                </div>
                <SolidButton className='btn-gray justify-content-center' onClick={toggleEmailChangeSuccessModal}>{t("Close")}</SolidButton>
            </ModalBody>
        </Modal>
    )
}

export default EmailChangeSuccess
