import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormGroup, Row } from 'reactstrap';

import { SolidButton } from '../../../../../AbstractElement';
import { CommonSelect } from '../../../../../CommonComponent';
import { useCoreData } from '../../../../../Hooks';
import { FreelancerAccountInput } from '../../../../../Types/Profile';
import { mutations, queries } from '../../../../../api';
import useToast from '../../../../../views/ToastWrapper';
import { useStateContext } from '../../../../../views/stateProvider';

const Address = () => {

  const { t } = useTranslation();
  const { toggleProfileSetting } = useStateContext();

  const { countryOptions } = useCoreData({ loadCountries: true });

  const { data: userDetails } = queries.useGetUserDetails();
  const { mutate: updateAccount, isLoading: updateLoading } = mutations.useUpdateAccount();
  const { showToast } = useToast()

  const [selectedCountryOptions, setSelectedCountryOptions] = useState<string[] | string>([]);

  const { register, handleSubmit, setValue } = useForm<FreelancerAccountInput>({
    defaultValues: {
      state: '',
      city: '',
      postalCode: '',
      countryCode: '',
      addressLine1: '',
      addressLine2: '',
    }
  });

  useEffect(() => {
    if (userDetails) {
      setValue('addressLine1', userDetails.addressLine1!);
      setValue('addressLine2', userDetails.addressLine2!);
      setValue('state', userDetails.state!);
      setValue('city', userDetails.city!);
      setValue('postalCode', userDetails.postalCode!);
      setValue('countryCode', userDetails.countryCode!);
      setSelectedCountryOptions(userDetails.countryCode!)
    }
  }, [userDetails, setValue]);

  const onSubmit: SubmitHandler<FreelancerAccountInput> = async (data) => {

    updateAccount({ ...userDetails, ...data }, {
      onSuccess: () => {
        showToast("success", "ProfileSettings.UpdatedSuccessfully")
      }
    })

  };

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className="setting-main">
        <Row className='g-sm-4 g-3'>
          <Col md="6">
            <FormGroup className='mb-0'>
              <label>{t("Country")}</label>
              <CommonSelect
                placeholder={t('SelectId')}
                options={countryOptions!}
                selectedOptions={selectedCountryOptions}
                setSelectedOptions={(value) => {
                  setSelectedCountryOptions(value);
                  setValue('countryCode', value);
                }}
                multiSelect={false}
                search={true}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup className='mb-0'>
              <label>{t("State")}</label>
              <input type="text" className="form-control" id="state" {...register("state")} placeholder={t("EnterState")} autoComplete="off" />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup className='mb-0'>
              <label>{t("City")}</label>
              <input type="text" className="form-control" id="city" {...register("city")} placeholder={t("EnterCityLong")} autoComplete="off" />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup className='mb-0'>
              <label>{t("PostalCode")}</label>
              <input type="text" className="form-control" id="postalCode" {...register("postalCode")} placeholder={t("EnterPostalCode")} autoComplete="off" />
            </FormGroup>
          </Col>
          <Col xs="12">
            <FormGroup className='mb-0'>
              <label>{t("Address")}</label>
              <input type="text" className="form-control" id="addressLine1" {...register("addressLine1")} placeholder={t("AddressLine1")} autoComplete="off" />
            </FormGroup>
          </Col>
          <Col xs="12">
            <FormGroup className='mb-0'>
              <input type="text" className="form-control" id="addressLine2" {...register("addressLine2")} placeholder={t("AddressLine2")} autoComplete="off" />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="profile-setting-footer-main">
        <div className="profile-setting-footer">
          <SolidButton className='btn-gray' onClick={toggleProfileSetting}>{t("Cancel")}</SolidButton>
          <SolidButton type='submit' loading={updateLoading}>{t("Update")}</SolidButton>
        </div>
      </div>
    </Form>
  )
}
export default Address;