import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

interface LoaderContextProps {
  isLoading: boolean;
  isButtonLoading: boolean;
  showLoader: () => void;
  hideLoader: () => void;
  showButtonSpinner: () => void;
  hideButtonSpinner: () => void;
}

const LoaderContext = createContext<LoaderContextProps | undefined>(undefined);

export const useLoader = () => {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error('useLoader must be used within a LoaderProvider');
  }
  return context;
};

export const LoaderProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [loadingCount, setLoadingCount] = useState<number>(0);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);

  const MIN_DISPLAY_TIME = 300; // Minimum display time in ms
  const DELAY_TIME = 200; // Delay before showing the loader in ms

  useEffect(() => {
    let displayTimeout: NodeJS.Timeout | undefined;
    let delayTimeout: NodeJS.Timeout | undefined;

    if (isLoading) {
      delayTimeout = setTimeout(() => {
        setIsLoaderVisible(true);
        displayTimeout = setTimeout(() => {
          setIsLoaderVisible(false);
        }, MIN_DISPLAY_TIME);
      }, DELAY_TIME);
    } else {
      if (delayTimeout) clearTimeout(delayTimeout);
      if (displayTimeout) clearTimeout(displayTimeout);
      setIsLoaderVisible(false);
    }

    return () => {
      if (delayTimeout) clearTimeout(delayTimeout);
      if (displayTimeout) clearTimeout(displayTimeout);
    };
  }, [isLoading]);

  const incrementLoadingCount = () => {
    setLoadingCount((count) => count + 1);
    setIsLoading(true);
  };

  const decrementLoadingCount = () => {
    setLoadingCount((prevCount) => {
      const newCount = Math.max(prevCount - 1, 0);

      // If the new count is 0 or less, update isLoading state and remove class
      if (newCount <= 0) {
        setIsLoading(false);
        document.body.classList.remove('no-loader');
      }

      return newCount;
    });
  };

  const showLoaderHandler = () => incrementLoadingCount();
  const hideLoaderHandler = () => decrementLoadingCount();

  const showButtonSpinner = () => setIsButtonLoading(true);
  const hideButtonSpinner = () => setIsButtonLoading(false);

  return (
    <LoaderContext.Provider value={{
      isLoading, 
      isButtonLoading, 
      showLoader: showLoaderHandler, 
      hideLoader: hideLoaderHandler, 
      showButtonSpinner, 
      hideButtonSpinner 
    }}>
      {children}
    </LoaderContext.Provider>
  );
};
