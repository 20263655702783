import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Nav, NavItem, NavLink, Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import { Details } from '../Widgets/Details';
import { BusinessTabs } from '../../../Data/Freelancer/Dashboard';
import { Tabs } from './Tabs';
import { useStateContext } from '../../../views/stateProvider';

const BusinessProfileSettings = () => {
    const { profileSetting, toggleProfileSetting } = useStateContext();
    const { t } = useTranslation();
    const [navActive, setNavActive] = useState<number>(profileSetting.selectedTab ?? 1);

    const handleTabClick = (tabIndex: number) => {
        if (tabIndex >= 1 && tabIndex <= BusinessTabs.length) {
            setNavActive(tabIndex);
        }
    };

    return (
        <Offcanvas isOpen={profileSetting.toggle} toggle={() => toggleProfileSetting()} direction="end" className="offcanvas-profile-setting profile-setting">
            <OffcanvasHeader toggle={() => toggleProfileSetting()}>{t("BusinessProfileSettings")}</OffcanvasHeader>
            <Details />
            <div className="tab-border-tab">
                <Nav pills className="profile-tab custom-scrollbar" id="pills-tab">
                    {BusinessTabs.map(tab => (
                        <NavItem key={tab.id}>
                            <NavLink
                                className={`${navActive === tab.id ? "active" : ""}`}
                                onClick={() => handleTabClick(tab.id)}
                                id={`pills-${tab.title?.toLowerCase()}-tab`}
                            >
                                <span>{t(tab.title)}</span>
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
            </div>
            <OffcanvasBody className="custom-scroll">
                <Tabs active={navActive} />
            </OffcanvasBody>
        </Offcanvas>
    );
};

export default BusinessProfileSettings;