import { useTranslation } from 'react-i18next';
import { Image } from '../AbstractElement';
import { dynamicImage } from '../utils';

export const Info:React.FC<{text: string; className?: string}> = ({text, className}) => {
    
    const { t } = useTranslation();

    return (
        <div className={`address-indicate-note ${className}`}>
            <div className="indicate">
                <div className="more-info">
                    <Image src={dynamicImage("svg/icon_tip.svg")} alt="" className="tip" />
                    <div className="info">
                        <div className="info-icon">
                            <Image src={dynamicImage("svg/info.svg")} alt="" />
                        </div>
                        <p>{t(text)}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
