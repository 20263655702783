import { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { LinkButton, SolidButton } from '../../../../AbstractElement'
import { ROUTES, SITE_SETTINGS } from '../../../../Constants'
import OTPInput from '../../Widgets/OTPInput'
import useToast from '../../../../views/ToastWrapper'
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../../Hooks'
import { getMessages } from '../../../../utils'
import { appInsights } from '../../../../api/appInsights'

const EmailVerificationForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { verifyBusinessPin } = useAuth();
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState<number>(SITE_SETTINGS.RESEND_TIMEOUT);
    const [val, setVal] = useState(Array(6).fill(""));
    const [isError, setIsError] = useState(false);
    const [isResendEnabled, setIsResendEnabled] = useState(false);
    const { email, verificationToken } = location.state || {};
    const isSubmitDisabled = val.some((value) => !value);
    const { showToast } = useToast();
    const { handleSubmit } = useForm();

    useEffect(() => {
        if (timer > 0) {
            const countdown = setTimeout(() => {
                setTimer(timer - 1);
            }, 1000);
            return () => clearTimeout(countdown);
        } else {
            setIsResendEnabled(true);
        }
    }, [timer, showToast]);

    const onSubmit = () => {
        setLoading(true)
        const pin = val.join('');
        verifyBusinessPin(
            { pin: pin, verificationToken },
            () => {
                setLoading(false)
                showToast('success', "EmailVerificationForm.VerificationSuccessful");

                try {
                    (window as any)?.dataLayer.push({ event: "bu_signup" });
                } catch (ex) {
                    appInsights.trackException({
                        error: new Error('Could not trigger bu_signup event'),
                        properties: { error: ex }
                    });
                }

                navigate(ROUTES.Dashboard);
            },
            (error: any) => {
                setLoading(false)
                setIsError(true);
                showToast("error", getMessages(error));
            }
        );
    };

    return (
        <Row>
            <Col xxl="6" lg="7" md="8" xs="12" className="mx-auto mb-4">
                <div className="verification-form">
                    <div className="title mx-4">
                        <h3 dangerouslySetInnerHTML={{ __html: t("PleaseEnterTheVerificationCodeSentToEmail", { email: email }) }}></h3>
                    </div>
                    <Form noValidate className="custom-form-w" onSubmit={handleSubmit(onSubmit)}>
                        <OTPInput val={val} setVal={setVal} isError={isError} setIsError={setIsError} />
                        <div className="action-btn">
                            <SolidButton type="submit" disabled={isSubmitDisabled} loading={loading}>{t("Submit")}</SolidButton>
                            <LinkButton to={ROUTES.FreelancerSignUp}>{t("Back")}</LinkButton>
                        </div>
                        <div className="resend-code">
                            <div>
                                <p dangerouslySetInnerHTML={{ __html: t("TheCodeWeSentYouWillExpireInSeconds", { timer: timer }) }} />
                                <p>{t("IfYourPinDoesNotWorkRequestNewOne")}</p>
                            </div>
                            <Button color="transparent" disabled={!isResendEnabled} className="resend">{t("ResendCode")}</Button>
                        </div>
                    </Form>
                </div>
            </Col>
        </Row>
    )
}
export default EmailVerificationForm;

