import { TabContent, TabPane } from "reactstrap"
import Documents from "../../Widgets/Documents"
import { Profile } from "./Profile"
import { Contact } from "./Contact"

export const Tabs: React.FC<{ active: number }> = ({ active }) => {
    return (
        <TabContent activeTab={active} id="pills-tabContent" className="form-mb-73">
            <TabPane tabId={1} id="pills-contact">
                <Contact />
            </TabPane>
            <TabPane tabId={2} id="pills-profile">
                <Profile />
            </TabPane>
            <TabPane tabId={3} id="pills-documents">
                <Documents />
            </TabPane>
        </TabContent>
    )
}