import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

interface CommonPaginationProps {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    goToPage: (page: number) => void;
    handleSetPageSize: (size: number) => void;
}

const CommonPagination: React.FC<CommonPaginationProps> = ({
    currentPage,
    itemsPerPage,
    totalItems,
    goToPage,
    handleSetPageSize
}) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const maxPagesToShow = 3
    const halfMaxPages = Math.floor(maxPagesToShow / 2);

    let startPage = Math.max(1, currentPage - halfMaxPages);
    let endPage = Math.min(totalPages, currentPage + halfMaxPages);

    if (totalPages > maxPagesToShow) {
        if (currentPage <= halfMaxPages) {
            endPage = maxPagesToShow;
        } else if (currentPage + halfMaxPages >= totalPages) {
            startPage = totalPages - maxPagesToShow + 1;
        }
    }

    return (
        <div className="">
            <Pagination aria-label="Page navigation example">
                <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink previous onClick={(e) => { e.preventDefault(); goToPage(currentPage - 1) }} />
                </PaginationItem>

                {startPage > 1 && (
                    <>
                        <PaginationItem>
                            <PaginationLink onClick={(e) => { e.preventDefault(); goToPage(1) }}>
                                1
                            </PaginationLink>
                        </PaginationItem>
                        {startPage > 2 && (
                            <PaginationItem className="disabled">
                                <PaginationLink>
                                    ...
                                </PaginationLink>
                            </PaginationItem>
                        )}
                    </>
                )}

                {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                    <PaginationItem key={page} active={page === currentPage}>
                        <PaginationLink onClick={(e) => { e.preventDefault(); goToPage(page); }}>
                            {page}
                        </PaginationLink>
                    </PaginationItem>
                ))}

                {endPage < totalPages && (
                    <>
                        {endPage < totalPages - 1 && (
                            <PaginationItem className="disabled">
                                <PaginationLink>
                                    ...
                                </PaginationLink>
                            </PaginationItem>
                        )}
                        <PaginationItem>
                            <PaginationLink onClick={(e) => { e.preventDefault(); goToPage(totalPages) }}>
                                {totalPages}
                            </PaginationLink>
                        </PaginationItem>
                    </>
                )}

                <PaginationItem disabled={currentPage === totalPages}>
                    <PaginationLink next onClick={(e) => { e.preventDefault(); goToPage(currentPage + 1); }} />
                </PaginationItem>
            </Pagination>
        </div>
    );
};

export default CommonPagination;
