import { getStorage } from ".";
import { STORAGE_KEYS } from "../Constants";

const defaultTimeZone = { code: Intl.DateTimeFormat().resolvedOptions().timeZone };
export function getTimeZone(): string | null {
    const storage = getStorage();
    let value = storage.getItem(STORAGE_KEYS.TIME_ZONE);
    if (!value) {
        const json = JSON.stringify(defaultTimeZone);
        storage.setItem(STORAGE_KEYS.TIME_ZONE, json);
        value = json;
    }
    const locale = JSON.parse(value);
    return locale.code;
}
