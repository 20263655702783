import { createContext, useContext } from "react";
import { User } from "../Types/Auth/Auth";

export type AuthInput = { email: string; password: string; rememberMe?: boolean };

interface AuthContext {
    user: Partial<User> | null;
    signin: (input: AuthInput) => Promise<void>;
    signup: (input: AuthInput, callback: VoidFunction) => Promise<void>;
    signout: (callback?: VoidFunction) => Promise<void>;
    googleSignin: (codes: { code: string }, callback: VoidFunction, errCallback: (err: any) => void) => Promise<void>;
    linkedInSignin: (codes: { code: string }, callback: VoidFunction, errCallback: (err: any) => void) => Promise<void>;
    businessPinSignin: (
        input: { email: string; pin: string },
        callback: VoidFunction,
        errCallback: (err: any) => void
    ) => Promise<void>;
    businessSignin: (code: string, callback: (e?: string) => void, errCallback: (err: any) => void) => Promise<void>;
    pinSignin: (code: string, callback: VoidFunction, errCallback: (err: any) => void) => Promise<void>;
    removeUser: VoidFunction;
    verifyBusinessPin: (
        input: { pin: string; verificationToken: string },
        callback: (response: any) => void,
        errCallback: (err: any) => void
    ) => Promise<void>;
}

export const AuthContext = createContext<AuthContext>(null!);

const useAuth = () => {
    return useContext(AuthContext);
};

export default useAuth;
