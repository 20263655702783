import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, Col, Form, Row } from 'reactstrap'

import { useForm } from 'react-hook-form'
import { SolidButton } from '../../../../AbstractElement'
import { mutations, queries } from '../../../../api'
import { ROUTES, SITE_SETTINGS } from '../../../../Constants'
import { useAuth } from '../../../../Hooks'
import useToast from '../../../../views/ToastWrapper'
import OTPInput from '../../Widgets/OTPInput'

const VerificationsEmailForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [timer, setTimer] = useState<number>(SITE_SETTINGS.RESEND_TIMEOUT);
    const [val, setVal] = useState(Array(6).fill(""));
    const [isError, setIsError] = useState(false);
    const [isResendEnabled, setIsResendEnabled] = useState(false);
    const { mutate: requestPin } = mutations.useSendEmailVerificationPin();
    const { mutate: verifyEmail, isLoading } = mutations.useVerifyEmail();
    const { data: accountInfo } = queries.useGetUserDetails(false);
    const email = accountInfo?.email || 'email';
    const isSubmitDisabled = val.some((value) => value === "" || value === undefined);
    const { showToast } = useToast();
    const { signout } = useAuth();
    const { handleSubmit } = useForm();

    useEffect(() => {
        if (timer > 0) {
            const countdown = setTimeout(() => {
                setTimer(timer - 1);
            }, 1000);
            return () => clearTimeout(countdown);
        } else {
            setIsResendEnabled(true);
        }
    }, [timer, showToast]);

    const submitHandler = () => {
        const otpCode = val.join("");
        verifyEmail(otpCode, {
            onSuccess: () => {
                showToast("success", "VerificationEmailForm.YourEmailIsNowVerified")
                navigate(ROUTES.FreelancerProfile);
            },
            onError: (e: any) => {
                setIsError(true)
            }
        });
    };

    const handleRequestPin = () => {
        requestPin(undefined, {
            onSuccess: () => {
                setTimer(SITE_SETTINGS.RESEND_TIMEOUT);
                setIsResendEnabled(false);
            },
        });
    }

    useEffect(() => {
        handleRequestPin();
    }, []);

    return (
        <Row>
            <Col xxl="6" lg="7" md="8" xs="12" className="mx-auto mb-4">
                <div className="verification-form">
                    <div className="title mx-4">
                        <h3 dangerouslySetInnerHTML={{ __html: t("PleaseEnterTheVerificationCodeSentToEmail", { email: email }) }}></h3>
                    </div>
                    <Form noValidate onSubmit={handleSubmit(submitHandler)} className="custom-form-w">
                        <OTPInput val={val} setVal={setVal} isError={isError} setIsError={setIsError} />
                        <div className="resend-code">
                            <div>
                                <p dangerouslySetInnerHTML={{ __html: t("TheCodeWeSentYouWillExpireInSeconds", { timer: timer }) }} />
                                <p>{t("IfYourPinDoesNotWorkRequestNewOne")}</p>
                            </div>
                            <Button color="transparent" disabled={!isResendEnabled} className="resend" onClick={() => handleRequestPin()}>{t("ResendCode")}</Button>
                        </div>
                        <div className="action-btn">
                            <SolidButton type="submit" disabled={isSubmitDisabled} loading={isLoading}>{t("Submit")}</SolidButton>
                            <SolidButton className="btn-gray" onClick={() => signout()}>{t("SignOut")}</SolidButton>
                        </div>
                    </Form>
                </div>
            </Col>
        </Row>
    )
}
export default VerificationsEmailForm;

