import { FC } from "react";
import { Button } from "reactstrap";

import { SolidButtonProps } from "../../Types/CommonElement";

export const SolidButton: FC<SolidButtonProps> = ({ children, color, type, className, loading, disabled, onClick, ...props }) => {
  return (
    <Button {...props} onClick={onClick} type={type ? type : "button"} color={color ? color : "primary"} disabled={loading || disabled ? true : false} className={` ${className ? className : "btn-solid"}`}>
      {loading ? (
        <>
          {children}
          <div className="loader d-inline-flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="animate-spin h-full">
              <path d="M12 21a9 9 0 1 1 0 -18a9 9 0 0 1 0 18z"></path>
              <path d="M18 12a6 6 0 0 1 -6 6"></path>
            </svg>
          </div>
        </>
      ) : (
        children
      )}
    </Button>
  );
};
