import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { useLayoutContext } from "../../../../../views/LayoutProvider"
import { useStateContext } from "../../../../../views/stateProvider"
import { useTranslation } from "react-i18next"
import { SolidButton } from "../../../../../AbstractElement"
import { mutations } from "../../../../../api"

const AccountDeletionConfirm: React.FC<{ reason: string }> = ({ reason }) => {
    const { accountDeleteConfirmModal, toggleAccountDeleteConfirmModal } = useStateContext()
    const { modalPosition } = useLayoutContext()
    const { t } = useTranslation()
    const { mutate: deleteAccount, isLoading } = mutations.useDeleteAccount();

    const handleNext = () => {
        deleteAccount({ deletionReason: reason }, {
            onSuccess: () => {
                toggleAccountDeleteConfirmModal();
            }
        })
    };

    return (
        <Modal centered dir={modalPosition} isOpen={accountDeleteConfirmModal} toggle={() => { toggleAccountDeleteConfirmModal() }} className="theme-modal account-modal">
            <ModalHeader toggle={() => { toggleAccountDeleteConfirmModal() }}>{t("AccountDeletionModal.Title")}</ModalHeader>
            <ModalBody>
                <div className="deletion">
                    <p>{t("AccountDeletionModal.AreYouSureYouWantToDeleteYourAccount")}</p>
                </div>
                <div className="modal-footer">
                    <SolidButton className="btn-gray justify-content-center" disabled={isLoading} onClick={toggleAccountDeleteConfirmModal}>{t("Cancel")}</SolidButton>
                    <SolidButton type="submit" color='transparent' className="btn-dark-gray" disabled={isLoading} onClick={handleNext}>{t("DeleteAccount")}</SolidButton>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default AccountDeletionConfirm
