import SumsubWebSdk from '@sumsub/websdk-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'reactstrap';
import { Image } from '../../../../../AbstractElement';
import { put } from '../../../../../api';
import { appInsights } from '../../../../../api/appInsights';
import { URL_KEYS } from '../../../../../Constants';
import { USER_VERIFICATION } from '../../../../../Types';
import { STEP, STEP_STATE, VerificationTabProp, VerificationToken } from '../../../../../Types/Auth/Auth';
import { DefaultErrorResponse } from '../../../../../Types/ErrorResponse';
import { dynamicImage, getMessages } from '../../../../../utils';
import useToast from '../../../../../views/ToastWrapper';

export const VerificationTab: React.FC<VerificationTabProp> = ({ navActive, stepState, fetchCompleted }) => {

  const { t } = useTranslation();
  const { showToast } = useToast();
  const [verificationToken, setVerificationToken] = useState<VerificationToken | null>(null);

  const errorHandler = (error: string) => {
    showToast("error", "VerificationTab.OnboardingProcessFailedToObtainAnAccessToken");

    appInsights.trackException({
      error: new Error('Onboarding process failed to obtain an access token.'),
      properties: { error: error }
    });
  };

  const messageHandler = (data: string) => {
    console.log(data);
    appInsights.trackTrace({ message: "Onboarding User verification message handle" }, { data });
    showToast("message", "VerificationTab.OnboardingProcessIsGoingOn");

    switch (data) {
      case "idCheck.onApplicantSubmitted":
      case "idCheck.applicantReviewComplete":
        fetchCompleted(STEP.Verification);
        break;
      default:
        break;
    }
  }


  const updateAccessToken = async () => {
    try {
      const result: VerificationToken = await put(URL_KEYS.Account.GenerateVerificationToken);
      setVerificationToken(result);
      const token = result?.token || "";

      if (token !== "") {
      }
      else {
        if (result.userVerification === "Approved") {
        } else {
          showToast("error", "VerificationTab.FailedToGetAValidResponseFromTheServer");
        }
      }
      return token;
    } catch (err) {
      const e = err as DefaultErrorResponse;
      if (e.data && Array.isArray(e)) {
        showToast("error", getMessages(e));
      }
      return "";
    }
  };

  useEffect(() => {
    if (navActive === 2 && (stepState === STEP_STATE.Waiting || stepState === STEP_STATE.Pending)) {
      updateAccessToken();
    }
  }, [navActive, stepState]);

  return (
    <>
      <p>{t("IdentityVerificationHelpsUsKeepJuuliSafeForEveryone")}</p>
      <div className="profile-setup pt-2 pb-3 custom-scroll">
        <Form className="verified">
          <div className="box">
            {(verificationToken?.userVerification === USER_VERIFICATION.Approved || stepState === STEP_STATE.Completed) && (
              <div className='verification-box'>
                <div>
                  <Image src={dynamicImage("svg/verification.svg")} className="img-fluid" alt="verification" />
                  <p>{t("YourAccountIsVerified")}</p>
                </div>
              </div>
            )}

            {(verificationToken?.userVerification === USER_VERIFICATION.Declined || stepState === STEP_STATE.Declined) && (
              <div className='verification-box'>
                <div>
                  <Image src={dynamicImage("svg/verification.svg")} className="img-fluid" alt="verification" />
                  <p>{t("YourAccountIsDeclined")}</p>
                </div>
              </div>
            )}

            {stepState !== STEP_STATE.Completed && verificationToken?.token !== '' &&
              verificationToken && (
                <SumsubWebSdk
                  className="verified-box"
                  accessToken={verificationToken?.token}
                  expirationHandler={updateAccessToken}
                  onError={errorHandler}
                  onMessage={messageHandler}
                />
              )}
          </div>
        </Form>
      </div>
    </>
  )
}