import React, { Fragment, useEffect, useMemo, useState } from "react";
import NotificationSection from "../Layout/Header/NotificationSection";
import ProfileSection from "../Layout/Header/ProfileSection";
import DatePicker from "react-datepicker";
import { Image } from "../AbstractElement";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { dynamicImage, formatDate, formatTime } from "../utils";
import { useTranslation } from "react-i18next";
import { HeaderContent, HeaderRenderProps, ToasterProps } from "../Types/Layout/Layout";
import { useAuth } from "../Hooks";
import { queries } from "../api";
import { USER_ROLE } from "../Types";
import { useStateContext } from "../views/stateProvider";
import ProjectCanvas from "../Components/Projects/Canvas/ProjectCanvas";
import { Button } from "reactstrap";
import { useLayoutContext } from "../views/LayoutProvider";

const CommonHeader: React.FC<ToasterProps> = ({ path }) => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate()
  const { projectModal, toggleProjectModal } = useStateContext();

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [selectedProjectId, setSelectedProjectId] = useState<string>();
  const [currentTime, setCurrentTime] = useState<string>(formatTime(new Date()));
  const [content, setContent] = useState<HeaderContent>({});
  const { user } = useAuth();
  const { isMobile } = useLayoutContext()
  const { data: userDetails } = queries.useGetUserDetails();
  const { refetch: refetchCalendarActivities } = queries.useGetCalendarActivities();
  const { data: projectData } = queries.useGetProject(selectedProjectId);

  useEffect(() => {
    if (id && path[1] === "project") {
      setSelectedProjectId(id);
    }
    else {
      setSelectedProjectId(undefined);
    }
  }, [id, path]);

  const fullName = useMemo(() => {
    if (!userDetails) return null;
    if (user?.role === USER_ROLE.Business) {
      return userDetails?.firstName ? `${userDetails?.firstName} ${userDetails?.lastName}` : userDetails.companyName;
    } else if (userDetails.firstName && userDetails.lastName) {
      return `${userDetails.firstName} ${userDetails.lastName}`;
    }
    return null;
  }, [userDetails]);

  const headerTopTitle: Record<string, HeaderContent> = {
    dashboard: {
      image: dynamicImage("svg/logo/logo.svg"),
      title: !fullName ? `Welcome` : `Hello, ${fullName}`,
      description: "HereIsYourActivitySoFar",
    },
    projects: {
      title: "Projects",
    },
    subProjects: {
      title: "title",
    },
    invoices: {
      title: user?.role === USER_ROLE.Business ? "RequestNewInvoice" : "CreateNewInvoice",
    },
    client: {
      title: "Clients",
    },
    freelancer: {
      title: "Freelancers",
    },
    finance: {
      title: "FinanceInvoice",
    },
    money: {
      title: "Money",
    },
    payment: {
      title: "Payment",
    },
    'payment-agreement': {
      title: "FinalizePayment",
    },
  };

  const pathSegments = location.pathname.split("/").filter(Boolean);
  const headerKey = pathSegments[0] === "project" && pathSegments.length > 1 ? "subProjects" : pathSegments.length > 0 ? pathSegments[0] : "dashboard";


  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(formatTime(new Date()));
    }, 60000); // Update every minute

    return () => clearInterval(timer);
  }, []);


  useEffect(() => {
    let content: HeaderContent = headerTopTitle[headerKey] || {};
    if (headerKey === "subProjects") {
      content.title = projectData?.projectName;
    }

    setContent(content);
  }, [headerKey, projectData]);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    if (date) {
      refetchCalendarActivities();
    }
  };

  const renderNav: React.FC<Partial<HeaderRenderProps>> = ({ content, className, iconSrc, text, linkText }) => (
    <>
      {content?.title && (
        <div className="page-title">
          <div className={`mobile-logo ${isMobile ? "show" : "hide"}`}>
            {isMobile && content.image && path[1] === "dashboard" && <Image src={content.image} alt="logo" className="mobile-logo-image img-fluid" />}
            <div className={`mobile-title ${isMobile && content.image ? "show" : "hide"}`}>
              {path.length === 3 && <Button color="transparent" className="back-btn" onClick={() => navigate(-1)}>
                <Image src={dynamicImage("svg/red-right-arrow.svg")} alt="arrow" className="next" />
              </Button>}
              <div>
                {content.title && (
                  <h2 className="f-w-700">
                    {t(content.title)}
                    {selectedProjectId && projectData?.projectCategory && projectData.inactive === false && <span>({projectData?.projectCategory})</span> }
                    {selectedProjectId && projectData?.projectCategory && projectData.inactive === true && <span className="dark-badge justify-content-center ms-5 badge d-inline rounded-pill"> Inactive </span> }
                  </h2>
                )}
                {content.description && <p>{t(content.description)}</p>}
                {selectedProjectId && projectData?.companyName && user?.role === USER_ROLE.Freelancer && (
                  <p className={`${path[1] === "project" ? "common-f-flex" : ""}`}>
                    <span className="initial-letter d-sm-flex d-none">
                      <span>
                        {projectData?.companyName
                          ?.split(" ")
                          ?.map((letter: string) => letter.charAt(0).toUpperCase())
                          ?.join("")?.slice(0, 2)}
                      </span>
                    </span>
                    <span>{projectData?.companyName}</span>
                    {projectData?.companyVerified && <Image src={dynamicImage("svg/blue-tick.svg")} className="blue-tick-icon" alt="edit" />}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* 
      //TODO: It's not ready yet and has been shut down for now. Once the relevant information arrives, it will be connected to Helpscout here.
      {text && (
        <li className={`${className} form-control d-xl-flex d-none`}>
          <div className="d-flex align-items-center left-title">
            <div className="img-box">{iconSrc && <Image src={dynamicImage(iconSrc)} className="nav-icon" alt={text} />}</div>
            <span>{t(text)}</span>
          </div>
          {linkText && (
            <div className="d-flex align-items-center right-title">
              {t(linkText)}
              <SVG iconId="stroke-short-right-arrow" />
            </div>
          )}
        </li>
      )} */}
    </>
  );

  const onEditProject = () => {
    if (selectedProjectId) {
      toggleProjectModal(selectedProjectId);
    }
  }

  return (
    <>
      <div className="common-header">
        {renderNav({ content })}
        <ul className="nav-right">
          <Fragment>
            {path[1] === "dashboard" && (
              <li className="datepicker-box">
                <div className="datepicker-container">
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="MMMM dd, yyyy"
                    customInput={
                      <button className="change-date-button">
                        <div className="date-display">
                          <div className="calendar-icon"></div>
                          <span>{selectedDate ? formatDate(selectedDate) : "Select Date"}</span>
                        </div>
                        <div>
                          <span>{currentTime}</span>
                        </div>
                      </button>
                    }
                  />
                </div>
              </li>
            )}
            {user?.role === USER_ROLE.Business &&
              path[1] === "projects" &&
              path.length === 2 &&
              renderNav({
                className: "recurring-invoice",
                iconSrc: "svg/star.svg",
                text: "YouCanInviteFreelancersToYourProjectsByClickingTheActionMenu",
                linkText: "ShowMe",
              })}
            {user?.role === USER_ROLE.Freelancer &&
              path[1] === "projects" &&
              path.length === 2 &&
              renderNav({
                className: "recurring-invoice",
                iconSrc: "svg/star.svg",
                text: "DidYouKnowYouCanCreateRecurringInvoices",
                linkText: "ShowMe",
              })}
            {path[1] === "money" &&
              renderNav({
                className: "recurring-invoice",
                iconSrc: "svg/star.svg",
                text: "DidYouKnowYouCanCreateRecurringInvoices",
                linkText: "ShowMe",
              })}
            {projectData?.editable && path[1] === "project" && path.length === 3 && (
              <li className="project-box form-control d-xl-flex d-none" >
                <Button color="transparent" onClick={onEditProject} className={`${path[1] === "project" ? "p-0 d-flex align-items-center" : "d-flex align-items-center gap-4 px-3"}`}>
                  <Image src={dynamicImage("svg/edit.svg")} className="edit-icon" alt="arrow" />
                  <h6>{t("EditProject")}</h6>
                </Button>
              </li>
            )}
            {path[1] === "invoices" &&
              renderNav({
                className: "recurring-invoice",
                iconSrc: "svg/vector-stroke.svg",
                text: "DoYouNeedHelp",
              })}
          </Fragment>
          {!(isMobile && (path.length === 3 || path[1]==="invoices")) && <><NotificationSection /><ProfileSection /></>}
        </ul>
      </div>
      {projectData?.editable && projectModal.toggle && !!selectedProjectId && <ProjectCanvas id={selectedProjectId} />}
    </>
  );
};

export default CommonHeader;
