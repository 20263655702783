import axios, { AxiosRequestConfig } from "axios";
import { getToken, parseResponseBody, trackApiResponse } from "../utils";
import { appInsights } from "./appInsights";

async function getBlob<T>(url: string, fileName: string): Promise<T> {
    const token = getToken();
    const config: AxiosRequestConfig = {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Request-Id": appInsights.context?.telemetryTrace?.traceID ?? '',
        },
        responseType: 'blob'
    };

    try {
        const response = await axios.get(url, config);

        const contentDisposition = response.headers['content-disposition'];
        if (contentDisposition) {
            var x = getFileNameFromContentDisposition(contentDisposition);
            if (x)
                fileName = x;
        }

        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            const body = parseResponseBody(error.response);
            trackApiResponse(url, "GET", body?.status, body?.data);
            throw body;
        }
        throw error;
    }
}

function getFileNameFromContentDisposition(contentDisposition: string) {
    const filenameRegex = /filename\*=[^;=\n]*UTF-8''(.*?)$/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches && matches[1]) {
        return decodeURIComponent(matches[1]);
    }

    const fallbackRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const fallbackMatches = fallbackRegex.exec(contentDisposition);
    if (fallbackMatches && fallbackMatches[1]) {
        return fallbackMatches[1].replace(/['"]/g, '');
    }

    return null;
}
export default getBlob;