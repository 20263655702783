import { useState, createContext, useContext } from "react";
import { ToastMessage, ToastType } from "../Types/CommonElement";
import { CompleteToast, DeleteToast, ErrorToast, InvitationToast, SuccessToast, VerifiedToast } from "../utils";

const ToastContext = createContext<{ showToast: (type: ToastType, message: string) => void }>(null!);

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [toasts, setToasts] = useState<ToastMessage[]>([]);

    const showToast = (type: ToastType, message: string) => {
        if (!message)
            return;

        const id = new Date().getTime();
        const newToast: ToastMessage = { id, type, message, visible: true };

        setToasts((prevToasts) => [...prevToasts, newToast]);

        setTimeout(() => {
            setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
        }, 5000);
    };

    const renderToast = (toast: ToastMessage) => {
        switch (toast.type) {
            case "info":
                return <SuccessToast key={toast.id} message={toast.message} />;
            case "error":
                return <ErrorToast key={toast.id} message={toast.message} />;
            case "success":
                return <VerifiedToast key={toast.id} text={toast.message} />;
            case "complete":
                return <CompleteToast key={toast.id} text={toast.message} />;
            case "delete":
                return <DeleteToast key={toast.id} text={toast.message} />;
            case "invitation":
                return <InvitationToast key={toast.id} text={toast.message} />;
            default:
                return null;
        }
    };

    return (
        <ToastContext.Provider value={{ showToast }}>
            {children}
            <div className="toast-wrapper-box">
                {toasts.map((toast) => toast.visible && renderToast(toast))}
            </div>
        </ToastContext.Provider>
    );
};

const useToast = () => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error("useToast must be used within a ToastProvider");
    }
    return context;
};

export default useToast;

