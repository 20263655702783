import { AxiosResponse } from "axios";

export function parseResponseBody(resp: AxiosResponse<any, any>): { data?: any; status: number } {
    const contentHeader = resp.headers["content-type"]?.toString() ?? ""
    const status = resp.status;
    if (!contentHeader) {
        return { status };
    }

    const contentType = contentHeader?.split(";")[0];

    switch (contentType) {
        case "application/json":
            return { data: resp.data, status };
        case "application/pdf":
            return { data: resp, status };
        case "text/html":
        case "text/plain":
            return { data: resp.data, status };
        default:
            return resp;
    }
}
