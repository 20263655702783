import { Col, Row } from "reactstrap";
import ImageSection from "../../Widgets/ImageSection";
import SignupForm from "./SignupForm";
import { LogoWrapper } from "../../Widgets/LogoWrapper";
import GoogleLinkedinMedia from "../../Widgets/GoogleLinkedinMedia";
import { useLayoutContext } from "../../../../views/LayoutProvider";

const FreelancerSignup = () => {

  const { isMobile } = useLayoutContext();

  return (
    <div className="log-in-section freelancer-signup">
      <Row className="login-content g-0">
        <Col xl="5" lg="6" md="8" sm="10" className="auth-left">
          <div className="login-main">
            <LogoWrapper freelancer tabBadge />
            <SignupForm />
            <ul className="social-media">
              <GoogleLinkedinMedia googleText={isMobile ? "Google" : "LoginWithGoogle"} linkedinText={isMobile ? "LinkedIn" : "LoginWithLinkedIn"} />
            </ul>
          </div>
        </Col>
        <ImageSection userName="Freelancers" />
      </Row>
    </div>
  );
};

export default FreelancerSignup;