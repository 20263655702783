import { createBrowserRouter, Navigate } from "react-router-dom";
import { ROUTES } from "../Constants";
import App from "../App";
import FreelancerLogin from "../Components/Auth/Freelancer/Login";
import ResetPassword from "../Components/Auth/Freelancer/ForgotPassword";
import UpdatePassword from "../Components/Auth/Freelancer/UpdatePassword";
import FreelancerSignup from "../Components/Auth/Freelancer/Signup";
import VerificationsEmail from "../Components/Auth/Freelancer/VerificationsEmail";
import { ProtectedRouteWrapper, RedirectRouteWrapper } from "../views";
import BusinessLogin from "../Components/Auth/Business/Login";
import VerifyAccount from "../Components/Auth/Business/VerifyAccount";
import AgreementPolicy from "../Components/Auth/Widgets/AgreementPolicy";
import LayoutRoutes from "./Layout";
import GoogleSignin from "../Components/Auth/Freelancer/GoogleSignin";
import LinkedInSignin from "../Components/Auth/Freelancer/LinkedInSignin";
import UserRoleWrapper from "../views/UserRoleWrapper";
import { USER_ROLE } from "../Types";
import ProfileSetup from "../Components/Profile/Freelancer/ProfileSetup";
import { BusinessSignin } from "../Components/Auth/Business/Signin";
import { BusinessLoginWithPin } from "../Components/Auth/Business/BusinessLoginWithPin";
import { VerifyInvoice } from "../Components/Auth/Business/VerifyInvoice";
import StripeRedirect from "../Components/Auth/Business/StripeRedirect";
import BusinessSignup from "../Components/Auth/Business/Signup";
import { EmailVerification } from "../Components/Auth/Business/EmailVerification";

export const router = createBrowserRouter([
    {
        path: ROUTES.Home,
        element: (
            <ProtectedRouteWrapper>
                <App />
            </ProtectedRouteWrapper>
        ),
        children: [
            {
                path: ROUTES.Home,
                element: <Navigate to={ROUTES.Dashboard} replace />
            },
            {
                path: ROUTES.FreelancerProfile,
                element: <ProfileSetup />
            },
            {
                path: "*",
                element: <LayoutRoutes />,
            },
        ],
    },
    {
        path: ROUTES.FreelancerLogIn,
        element: (
            <RedirectRouteWrapper>
                <FreelancerLogin />
            </RedirectRouteWrapper>
        )
    },
    {
        path: ROUTES.BusinessLogin,
        element: (
            <RedirectRouteWrapper>
                <BusinessLogin />
            </RedirectRouteWrapper>
        )
    },
    {
        path: ROUTES.VerifyAccount,
        element: (
            <RedirectRouteWrapper>
                <VerifyAccount />
            </RedirectRouteWrapper>
        )
    },
    {
        path: ROUTES.BusinessSignUp,
        element: (
            <RedirectRouteWrapper>
                <BusinessSignup />
            </RedirectRouteWrapper>
        )
    },
    {
        path: ROUTES.FreelancerSignUp,
        element: (
            <RedirectRouteWrapper>
                <FreelancerSignup />
            </RedirectRouteWrapper>
        ),
        children: [
            {
                path: ":referrerCode",
                element: (
                    <RedirectRouteWrapper>
                        <FreelancerSignup />
                    </RedirectRouteWrapper>
                )
            }
        ]
    },
    {
        path: ROUTES.ForgotPassword,
        element: (
            <ResetPassword />
        ),
    },
    {
        path: ROUTES.CreatePassword,
        element: <UpdatePassword />
    },
    {
        path: ROUTES.GoogleSignin,
        element: (
            <RedirectRouteWrapper>
                <GoogleSignin />
            </RedirectRouteWrapper>
        )
    },
    {
        path: ROUTES.LinkedInSignin,
        element: (
            <RedirectRouteWrapper>
                <LinkedInSignin />
            </RedirectRouteWrapper>
        )
    },
    {
        path: ROUTES.BusinessSignin,
        element: <BusinessSignin />
    },
    {
        path: ROUTES.VerifyInvoice,
        element: (
            <UserRoleWrapper role={USER_ROLE.TFAUser}>
                <VerifyInvoice />
            </UserRoleWrapper>
        )
    },
    {
        path: ROUTES.BusinessPinLogin,
        element: (
            <UserRoleWrapper role={USER_ROLE.TFAUser}>
                <BusinessLoginWithPin />
            </UserRoleWrapper>
        )
    },
    {
        path: ROUTES.EmailVerification,
        element: (
            <UserRoleWrapper role={USER_ROLE.Freelancer}>
                <VerificationsEmail />
            </UserRoleWrapper>
        )
    },
    {
        path: ROUTES.AgreementPolicy,
        element: (
            <UserRoleWrapper>
                <AgreementPolicy />
            </UserRoleWrapper>
        )
    },
    {
        path: ROUTES.StripeRedirect,
        element: (
            <UserRoleWrapper role={USER_ROLE.Business}>
                <StripeRedirect />
            </UserRoleWrapper>
        )
    },
    {
        path: ROUTES.BusinessVerificationPin,
        element: (
            <RedirectRouteWrapper>
                <EmailVerification />
            </RedirectRouteWrapper>
        )
    },
]);
