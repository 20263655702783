import React, { Fragment, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Row } from 'reactstrap';

import { BankAccount } from '../../../../../../Types/BankAccount';
import { useCoreData } from '../../../../../../Hooks';
import { mutations, queries } from '../../../../../../api';
import { Image, SolidButton } from '../../../../../../AbstractElement';
import { dynamicImage } from '../../../../../../utils';
import CommonSelect from '../../../../../../CommonComponent/CommonSelect';
import { Fields, Options } from '../../../../../../Types/Profile';
import DatePicker from 'react-datepicker';
import useToast from '../../../../../../views/ToastWrapper';
import { currency } from '../../../../../../Data/Freelancer/CurrencySymbol';
import { useLayoutContext } from '../../../../../../views/LayoutProvider';
import { LayoutLoader } from '../../../../../../CommonComponent/loader';
import ConfirmationDialog from '../../../../../../CommonComponent/ConfirmationDialog';

interface AccountActionProps {
    action: string;
    account?: BankAccount;
    setAddAccount: (value: boolean) => void;
    rowIndex?: any;
    setEdit: (value: boolean) => void;
    isOpenForm: boolean;
    setOpenFormIndex: (index: number | null) => void;
}

// Base interface for static fields
type FieldsInput = {
    label: string;
    currencyCode: string;
    countryCode: string;
    currencyPayoutDetailId: string;
};

type DynamicField = string | number | undefined | DynamicFields;

interface DynamicFields {
    [key: string]: DynamicField;
}

type FormValues = FieldsInput & DynamicFields
export const AccountDetails: React.FC<AccountActionProps> = ({ setAddAccount, action, account, rowIndex, setEdit, isOpenForm, setOpenFormIndex }) => {
    const { t } = useTranslation();
    const { isMobile } = useLayoutContext()
    const [currencyCode, setCurrencyCode] = useState<string>();
    const [countryCode, setCountryCode] = useState<string>();
    const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);
    const { currencyPayOutOptions, countryOptions } = useCoreData({ loadCurrenciesPayOut: true, loadCountries: true });
    const { showToast } = useToast();
    const handleDropdownToggle = (index: number) => {
        setOpenDropdownIndex(openDropdownIndex === index ? null : index);
    }

    const { data: bankAccounts } = queries.useGetBankAccounts();

    // Api calling
    const { mutate: addBankAccount, isLoading: createLoading } = mutations.useAddBankAccount();
    const { mutate: updateBankAccount, isLoading: updateLoading } = mutations.useUpdateBankAccount();
    const { mutate: deleteBankAccount, isLoading: deleteLoading } = mutations.useDeleteBankAccount();

    const { data: types } = queries.useGetCurrencyPayoutTypes(isOpenForm || action === 'create' ? { currencyCode: currencyCode } : {});
    const [selectedTransferTypeOptions, setSelectedTransferType] = useState<string>('');
    const [fields, setFields] = useState<Fields[] | any>();
    const { data: bankAccount, isLoading } = queries.useGetBankAccountDetails(isOpenForm || action === 'create' ? account?.id! : '');

    useEffect(() => {
        if (types && types.items.length) {
            const selectedType = types.items?.find(type => type.id === selectedTransferTypeOptions);
            if (selectedType && selectedType.fields) {
                setFields(selectedType.fields);
            }
        }
    }, [types, selectedTransferTypeOptions])


    const { register, handleSubmit, setValue, watch, unregister, control, formState: { isSubmitted } } = useForm<FormValues>();

    useEffect(() => {
        if (bankAccount) {
            // Set additional state values
            setCurrencyCode(bankAccount.currencyCode);
            setCountryCode(bankAccount.countryCode);
            setSelectedTransferType(bankAccount.currencyPayoutDetailId);
        }
    }, [bankAccount])

    useEffect(() => {
        if (bankAccount) {
            // Set primary values from bankAccount
            setValue('label', bankAccount.label);
            setValue('countryCode', bankAccount.countryCode);
            setValue('currencyCode', bankAccount.currencyCode);
            setValue('currencyPayoutDetailId', bankAccount.currencyPayoutDetailId);

            // Ensure fields are defined before iterating
            if (fields && Array.isArray(fields)) {
                fields.forEach((field: Fields) => {
                    // Check if the field key exists in bankAccount.details
                    if (bankAccount.details && bankAccount.details.hasOwnProperty(field.key)) {
                        // Set the value using setValue from react-hook-form
                        setValue('details.' + field.key, bankAccount.details[field.key]);
                    }
                });
            }
        }

    }, [bankAccount, fields, setValue])

    // Custom validation function
    const validateField = (name: string, value: any) => {
        const field = fields?.find((field: Fields) => field.key === name);
        if (field) {
            if ((field.required && !value) || (field.minLenght && value.length < field.minLenght) || (field.maxLenght && value.length > field.maxLenght)) {
                return false;
            }

            // Regular expression validation
            if (field.validationRegexp) {
                const regex = new RegExp(field.validationRegexp);
                if (!regex.test(value)) {
                    return false;
                }
            }
        }
        return true; // Return true if valid
    };

    //TODO:Validation to show hints
    // const validate = (field: Fields, value: any) => {
    //     if (field) {
    //         // Required validation
    //         // if (field.required && !value) {
    //         //     return `${field.displayName} is required`;
    //         // }

    //         // MinLength validation
    //         if (field.minLenght && value.length < field.minLenght) {
    //             // return `${field.displayName} must be at least ${field.minLenght} characters long`;
    //             return t("FieldNameMustBeAtLeastLengthCharactersLong", { name: field.displayName, length: field.minLenght });

    //         }

    //         // MaxLength validation
    //         if (field.maxLenght && value.length > field.maxLenght) {
    //             // return `${field.displayName} must not exceed ${field.maxLenght} characters`;
    //             return t("FieldNameMustNotExceedLengthCharacters", { name: field.displayName, length: field.maxLenght });
    //         }

    //         // Regular expression validation
    //         if (field.validationRegexp) {
    //             const regex = new RegExp(field.validationRegexp);
    //             if (!regex.test(value)) {
    //                 // return `${field.displayName} is invalid`;
    //                 return t("IsInvalid", { name: field.displayName });
    //             }
    //         }
    //     }
    // };

    const openForm = (val: boolean) => {
        if (val) {
            setOpenFormIndex(rowIndex ?? -1); // Open the form for the current index
        } else {
            setOpenFormIndex(null); // Close the form
        }
        setAddAccount(false);
        setEdit(val);
    };

    const editAccount = () => {
        openForm(true)
    }

    const onSubmit: SubmitHandler<any> = async (data) => {

        if (action === 'create') {
            addBankAccount(data, {
                onSuccess: () => {
                    showToast('success', 'AccountDetails.AddedSuccessfully');
                    openForm(false);
                    setEdit(false)
                },
            })
        }

        if (action === 'edit' && account?.id) {
            updateBankAccount({ id: account?.id, input: data }, {
                onSuccess: () => {
                    showToast('success', 'ProfileSettings.UpdatedSuccessfully');
                    openForm(false);
                    setEdit(false)
                },
            })
        }
    };

    const handlerDelete = () => {
        ConfirmationDialog({
            title: "Delete Bank Account",
            text: "Are you sure you want to delete this bank account? This action cannot be undone and may affect associated data.",
            confirmButtonText: "Delete",
            onConfirm: (isConfirmed) => {
                if (isConfirmed) {
                    deleteBankAccount(account?.id, {
                        onSuccess: () => {
                            // Handle success, e.g., show a notification or refresh data
                            showToast('delete', 'AccountDetails.BankAccountDeletedSuccessfully');
                            openForm(false)
                        },
                    });
                }
            }
        });
    }

    const convertOption = (option: Options[]) => {
        return option?.map(item => { return { value: item.key, label: item.name } })
    }
    const renderError = (field: string, name: string) => {
        return (
            <span className='required-note'><small>
                {t(`${!watch('details.' + field) ? "IsRequired" : "IsInvalid"}`, { name: name })}
            </small></span>)
    }

    return (
        <div className={`transaction-item ${isOpenForm || action === 'create' ? 'open' : ''}`}>
            <div className='transaction-data'>
                <h4>{currency.find(curr => curr.currencyCode === account?.currencyCode)?.currencySymbol}</h4>
                {
                    action !== 'create' &&
                    <div className="data-box d-flex flex-column w-100 gap-2">
                        <span className="country-name">{account?.label}</span>
                        <span className="amount">{t("AccountDetails.DisplayFormat", { currencyCode: account?.currencyCode, displayValue: account?.displayValue })}</span>
                    </div>
                }

                <div className="edit-item-data">
                    {
                        isLoading ? (
                            <LayoutLoader />
                        ) : (
                            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                                <Row className="g-3">
                                    <Col xs="12">
                                        <div className="edit-input-box">
                                            <input
                                                type="text"
                                                className={`form-control ${isSubmitted && !watch('label') ? 'required-field' : ''}`}
                                                id="label"
                                                placeholder={t("EnterALabelForThisAccount")}
                                                {...register('label', {
                                                    validate: (value) => validateField('label', value),
                                                })}
                                                autoComplete="off"
                                            />
                                            {(isSubmitted && !watch('label')) && renderError("label", "Label")}
                                            <Image src={dynamicImage("svg/edit.svg")} className="edit-icon" alt="arrow" />
                                        </div>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup className='mb-0'>
                                            <label htmlFor="currency">{t("Currency")}</label>
                                            <CommonSelect
                                                placeholder={t('Select')}
                                                options={currencyPayOutOptions!}
                                                selectedOptions={currencyCode}
                                                setSelectedOptions={(value) => {
                                                    setCurrencyCode(value)
                                                    setValue('currencyCode', value)
                                                    setSelectedTransferType('')
                                                    setFields([])
                                                    fields?.forEach((field: any) => unregister('details'));
                                                }}
                                                className={isSubmitted && !watch('currencyCode') ? 'required-field' : ''}
                                                multiSelect={false}
                                                search={true}
                                            />
                                            {(isSubmitted && !watch('currencyCode')) && renderError("currencyCode", "Currency")}
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup className='mb-0'>
                                            <label htmlFor="transferType">{t("TransferType")}</label>
                                            <CommonSelect
                                                placeholder={t('Select')}
                                                options={types?.items?.map(type => ({ value: type.id, label: type.displayName }))!}
                                                selectedOptions={selectedTransferTypeOptions}
                                                setSelectedOptions={(value) => {
                                                    setValue('currencyPayoutDetailId', value)
                                                    setSelectedTransferType(value)
                                                    fields?.forEach((field: any) => unregister('details'));
                                                }}
                                                className={isSubmitted && !watch('currencyPayoutDetailId') ? 'required-field' : ''}
                                                multiSelect={false}
                                            />
                                            {(isSubmitted && !watch('currencyPayoutDetailId')) && renderError("currencyPayoutDetailId", t("TransferType"))}
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup className='mb-0'>
                                            <label htmlFor="country">{t("BankAccountCountry")}</label>
                                            <CommonSelect
                                                placeholder={t('Select')}
                                                options={countryOptions!}
                                                selectedOptions={countryCode}
                                                setSelectedOptions={(value) => {
                                                    setCountryCode(value)
                                                    setValue('countryCode', value)
                                                }}
                                                className={isSubmitted && !watch('countryCode') ? 'required-field' : ''}
                                                multiSelect={false}
                                                search={true}
                                            />
                                            {(isSubmitted && !watch('countryCode')) && renderError("countryCode", t("BankAccountCountry"))}
                                        </FormGroup>
                                    </Col>
                                    {
                                        fields?.length > 0 && fields?.map((field: Fields) => (
                                            <Col sm="6" key={field.key}>
                                                <FormGroup className='mb-0 position-relative bank-info-box'>
                                                    <label className='justify-content-start d-flex gap-2'>{field.displayName} {field.required && <span className="required-data">*</span>}
                                                        {/* 
                                                        //TODO:It can be connected to show hints
                                                        {validate(field, '')! && <Info text={validate(field, '')!} className={field.displayName.length > 20 ? 'info-left' : ''} />} */}

                                                    </label>
                                                    {field.type === 'Text' ? (
                                                        <>
                                                            <input
                                                                type="text"
                                                                placeholder={field.example ? field.example : t("EnterYourFieldName", { name: field.displayName.toLocaleLowerCase() })}
                                                                className={`form-control ${isSubmitted && !validateField(field.key, watch('details.' + field.key)) ? 'required-field' : ''}`}
                                                                disabled={field.disabled}
                                                                {...register('details.' + field.key, {
                                                                    validate: (value) => validateField(field.key, value),
                                                                })}
                                                                autoComplete="off"
                                                            />
                                                            {(isSubmitted && !validateField(field.key, watch('details.' + field.key))) && renderError(field.key, field.displayName)}
                                                        </>
                                                    ) : field.type === 'Select' ? (
                                                        <>
                                                            <CommonSelect
                                                                placeholder={field.example || t('Select')}
                                                                options={convertOption(field.allowedValues!)}
                                                                selectedOptions={watch('details.' + field.key)}
                                                                setSelectedOptions={(value) => {
                                                                    register('details.' + field.key)
                                                                    setValue('details.' + field.key, value)
                                                                }}
                                                                className={isSubmitted && !watch('details.' + field.key) ? 'required-field' : ''}
                                                                multiSelect={false}
                                                                search={(field?.allowedValues && field?.allowedValues?.length > 10 ? true : false)}
                                                            />
                                                            {(isSubmitted && !watch('details.' + field.key)) && renderError(field.key, field.displayName)}
                                                        </>
                                                    ) : field.type === 'Radio' ? (
                                                        <Row className='g-sm-4 g-3'>
                                                            {field?.allowedValues?.map((option) => (
                                                                <Col md="6">
                                                                    <div key={option.key} className="bank-form-check">
                                                                        <input
                                                                            type="radio"
                                                                            id={option.key}
                                                                            value={option.key}
                                                                            disabled={field.disabled}
                                                                            className={`form-radio-input ${isSubmitted && !watch('details.' + field.key) ? 'required-radio' : ''}`}
                                                                            {...register('details.' + field.key)} // Register to react-hook-form
                                                                        />
                                                                        <label htmlFor={option.key}>{option.name}</label>
                                                                    </div>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    ) : field.type === 'Date' ? (
                                                        <>
                                                            <Controller
                                                                {...register('details.' + field.key)}
                                                                control={control}
                                                                name={'details.' + field.key}
                                                                render={({ field: { onChange, value } }) => {
                                                                    const parsedDate = value && (typeof value === 'string' || typeof value === 'number' || value instanceof Date)
                                                                        ? new Date(value)
                                                                        : null;

                                                                    return (
                                                                        <DatePicker
                                                                            {...(isMobile && { withPortal: true })}
                                                                            placeholderText={t('SelectDate')}
                                                                            className={`form-control w-100 ${isSubmitted && !watch('details.' + field.key) ? 'required-field' : ''}`}
                                                                            selected={parsedDate instanceof Date && !isNaN(parsedDate.getTime()) ? parsedDate : null} // Ensure valid date
                                                                            onChange={(date) => onChange(date)} // Update form state when date changes
                                                                            onKeyDown={(e: React.KeyboardEvent) => e.preventDefault()} // Prevent manual input
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                            {(isSubmitted && !watch('details.' + field.key)) && renderError(field.key, field.displayName)}
                                                        </>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        ))
                                    }
                                    <Col xs="12" className='m-0'>
                                        <div className="bank-apply-btn">
                                            {
                                                action === 'edit' &&
                                                <SolidButton className="p-0 delete-btn" color='transparent' loading={deleteLoading} onClick={() => handlerDelete()} >{t("DeleteAccount")}</SolidButton>
                                            }
                                            <SolidButton className="px-5 btn-gray" onClick={() => openForm(false)}>{t("Cancel")}</SolidButton>

                                            <SolidButton className="px-5 justify-content-center btn-solid" type='submit' loading={action === "create" ? createLoading : updateLoading}>{action === 'create' ? t("Save") : t("Update")}</SolidButton>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        )
                    }
                </div>
            </div>
            {
                action === 'edit' &&
                <td className="responsive-dropdown">
                    <Dropdown className='select-dropdown-2' isOpen={openDropdownIndex === rowIndex} toggle={() => handleDropdownToggle(rowIndex)}>
                        <DropdownToggle tag="button" className="dropdown-toggle select-dropdown-btn">
                            <Image src={dynamicImage("svg/icon-action.svg")} alt="actions" />
                        </DropdownToggle>
                        <DropdownMenu end className='select-dropdown-menu' container="body">
                            <Button color='transparent' className="img-box" onClick={() => handleDropdownToggle(rowIndex)}>
                                <Image src={dynamicImage("svg/icon-action.svg")} alt="actions" />
                            </Button>
                            <Button color='transparent' className='btn-close d-sm-none d-flex' onClick={() => handleDropdownToggle(rowIndex)}></Button>
                            <Fragment>
                                <DropdownItem onClick={() => editAccount()} >
                                    {t("Edit")}
                                </DropdownItem>
                            </Fragment>
                        </DropdownMenu>
                    </Dropdown>
                </td>
            }
        </div>
    );
};
