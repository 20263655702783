import axios, { AxiosRequestConfig, ResponseType } from "axios";
import { getToken, parseResponseBody, trackApiResponse } from "../utils";
import { Params } from "../Types/CommonElement";
import { appInsights } from "./appInsights";

async function get<T>(url: string, params?: Params, responseType: ResponseType = 'json'): Promise<T> {
    const token = getToken();
    const config: AxiosRequestConfig = {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Request-Id": appInsights.context?.telemetryTrace?.traceID ?? ''
        },
        params,
        paramsSerializer: {
            indexes: null
        }
    };

    try {
        const response = await axios.get(url, config);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            const body = parseResponseBody(error.response);
            trackApiResponse(url, "GET", body?.status, body?.data);
            throw body;
        }
        throw error;
    }
}

export default get;