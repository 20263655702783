import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const CustomerSchema = () => {
  const { t } = useTranslation();

  const Customer = Yup.object().shape({
    name: Yup.string().required(t("IsRequired", { name: "Name" })),
    email: Yup.string().email().required(t("IsRequired", { name: "Email" })),
    addressLine1: Yup.string().required(t("IsRequired", { name: "Address line 1" })),
    city: Yup.string().required(t("IsRequired", { name: "City" })),
    state: Yup.string().required(t("IsRequired", { name: "State" })),
    countryCode: Yup.string().required(t("IsRequired", { name: "Country" })),
    postalCode: Yup.string().required(t("IsRequired", { name: "Postal code" })),
  });

  return {
    Customer
  };
};

export default CustomerSchema;
