import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTION,
        extensions: [reactPlugin],
        enableAutoRouteTracking: true
    }
});
if (appInsights.config.connectionString)
    appInsights.loadAppInsights();

export { reactPlugin, appInsights };
