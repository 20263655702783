export function convertToReadableAmount(value: number, currency: string): string {
    try {
        if (value === undefined)
            return "";

        if (!currency || currency === "") return value.toFixed(2);

        const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency,
            currencyDisplay: "code",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        const formattedValue = formatter.format(value);
        return `${formattedValue.replace(currency, "").trim()} ${currency}`;
    } catch (error) {
        return value?.toFixed(2);
    }
}
