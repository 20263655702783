import { useTranslation } from "react-i18next"
import { useLayoutContext } from "../../../../../views/LayoutProvider"
import { useStateContext } from "../../../../../views/stateProvider"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { Image, SolidButton } from "../../../../../AbstractElement"
import { dynamicImage } from "../../../../../utils"
import { useRef, useState } from "react"
import { mutations, queries } from "../../../../../api"
import useToast from "../../../../../views/ToastWrapper"
import { useAuth, useCoreData } from "../../../../../Hooks"
import { USER_ROLE } from "../../../../../Types"

const ProfilePhotoChange = () => {
    const { changeProfilePhotoModal, toggleProfilePhotoModal } = useStateContext()
    const { modalPosition } = useLayoutContext()
    const { t } = useTranslation()
    const {user} = useAuth();
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [fileName, setFileName] = useState(t("SelectFilesFromYourComputer"));
    const [file, setFile] = useState<File | null>(null);
    const { mutate: uploadProfileImage, isLoading: profileImageLoading } = mutations.useUploadProfileImage();
    const { mutate: deleteProfileImage, isLoading: deleteImageLoading } = mutations.useDeleteProfileImage();
    const { showToast } = useToast()
    const { data: userDetails } = queries.useGetUserDetails();
    const { data: ProfileImageData } = queries.useGetProfileImage(userDetails?.hasProfileImage ?? false)
    const { fullName } = useCoreData({ loadUserDetails: true });

    const allowedFileTypes = [".png", ".jpg", ".jpeg"];

    const handleFileChange = (event: any) => {
        const selectedFile = event.target.files[0];
        const fileExtension = `.${selectedFile?.name?.split('.').pop().toLowerCase()}`;
        const isValidFileType = allowedFileTypes?.includes(fileExtension);

        if (!isValidFileType) {
            showToast("error", "Invalid file type. Please upload a valid file.");
            return;
        }

        if (selectedFile) {
            setFile(selectedFile);
            setFileName(selectedFile.name);
        } else {
            setFileName(t("SelectFilesFromYourComputer"));
        }

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleUploadClick = () => {
        if (file) {
            uploadProfileImage({ file: file }, {
                onSuccess: (res) => {
                    setFile(null);
                    setFileName(t("SelectFilesFromYourComputer"));
                    toggleProfilePhotoModal()
                    showToast("success", "ProfileSettings.ProfileImageUploadedSuccessfully");
                },
            });
        }
    };

    const handleDelete = () => {
        deleteProfileImage({}, {
            onSuccess: () => {
                toggleProfilePhotoModal()
                showToast("delete", "ProfileSettings.ProfileImageDeletedSuccessfully")
            }
        });
    }

    return (
        <Modal centered dir={modalPosition} isOpen={changeProfilePhotoModal} toggle={toggleProfilePhotoModal} className="theme-modal">
            <ModalHeader toggle={toggleProfilePhotoModal}>{t("ChangeProfilePhoto")}</ModalHeader>
            <ModalBody>
                <div className={`upload-profile-box ${user?.role === USER_ROLE.Business ? 'business-header' : ''}`}>
                    {userDetails?.hasProfileImage && ProfileImageData ?
                        <Image className="nav-profile-box profile-upload-img img-fluid" src={`data:image/png;base64, ${ProfileImageData}`} alt="profile" />
                        : (<>{fullName ?
                            (<div className="initial-letter">
                                <span>{fullName?.split(' ')?.map((letter: string) => letter.charAt(0).toUpperCase())?.join('')?.slice(0, 2)}</span>
                            </div>) :
                            (
                            <Image src={dynamicImage("icon_user.png")} className="nav-profile-box profile-image profile-upload-img" alt="logo" />
                        )
                        }</>)
                    }
                    <div className="custom-file-input select-file w-100">
                        <Image src={dynamicImage("svg/attachment.svg")} alt="paper" />
                        <input type="file" accept={allowedFileTypes.join()} ref={fileInputRef} className="form-control" id="file" style={{ display: "none" }} onChange={handleFileChange} />
                        <button type="button" className="form-control text-start" onClick={() => fileInputRef?.current?.click()}>
                            <span>{fileName}</span>
                        </button>
                        {file && (
                            <button
                                type="button"
                                className="btn upload-close-btn btn-outline-secondary ms-2"
                                onClick={() => {
                                    setFileName(t("SelectFilesFromYourComputer"));
                                    setFile(null);
                                }}
                            >
                            </button>
                        )}
                    </div>
                    <div className="modal-footer">
                        <SolidButton className='btn-gray justify-content-center' onClick={toggleProfilePhotoModal}>{t("Cancel")}</SolidButton>
                        <SolidButton className='btn-dark-gray justify-content-center' onClick={handleDelete} disabled={!userDetails?.hasProfileImage} loading={deleteImageLoading}>{t("Delete")}</SolidButton>
                        <SolidButton type="submit" onClick={handleUploadClick} disabled={!file} loading={profileImageLoading}>{t("Update")}</SolidButton>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ProfilePhotoChange
