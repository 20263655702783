import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Container, FormGroup, Row } from "reactstrap";
import { Image, SolidButton } from "../../../../AbstractElement";
import { CommonSelect, Info } from "../../../../CommonComponent";
import SVG from "../../../../CommonElement/SVG/Svg";
import { ROUTES } from "../../../../Constants";
import { useAuth } from "../../../../Hooks";
import Loader from "../../../../Layout/Loader";
import { INVOICE_STATUS } from "../../../../Types";
import { mutations, queries } from "../../../../api";
import { convertToReadableDate, dynamicImage, getMessages, getParam } from "../../../../utils";
import { useLayoutContext } from "../../../../views/LayoutProvider";
import useToast from "../../../../views/ToastWrapper";
import { InvoiceDetails } from "./InvoiceDetails";
import ConfirmationDialog from "../../../../CommonComponent/ConfirmationDialog";

const Approval: React.FC<{
  handleSetState: (value: "approval" | "decline" | "final") => void;
  handleApprove: VoidFunction;
}> = ({ handleSetState, handleApprove }) => {
  const { mutate, isLoading } = mutations.useInvoiceFastApproval();
  const [isChecked, setIsChecked] = useState(false);
  const { showToast } = useToast();
  const { t } = useTranslation()

  return (
    <>
      <div className="form-check">
        <input type="checkbox" className="form-check-input" id="invoiceApprovalCheckbox" checked={isChecked} onChange={() => setIsChecked((prev) => !prev)} />
        <label htmlFor="invoiceApprovalCheckbox">{t("IConfirmThatIHaveReceivedTheCompletedWorkAndApproveTheInvoice")}</label>
        <Info text='' />
      </div>
      <Row className="pb-4 mt-5 verify-footer">
        <Col xs="12">
          <div className="auth-footer px-sm-5 px-3">
            <SolidButton className="custom-confirm-black btn-solid" onClick={() => handleSetState("decline")}>
              {t("Reject")}
            </SolidButton>
            <SolidButton
              className="btn-success"
              loading={isLoading}
              disabled={!isChecked}
              onClick={() => {
                const invoiceScopeId = getParam("invoiceScopeId");
                if (!invoiceScopeId) return;
                mutate(invoiceScopeId, {
                  onSuccess: () => {
                    handleApprove();
                    handleSetState("final");
                  },
                  onError: (e) => {
                    if (Array.isArray(e)) {
                      showToast("error", getMessages(e));
                    }
                  },
                });
              }}
            >
              {t("Approve")}
            </SolidButton>
          </div>
        </Col>
      </Row>
    </>
  );
};

const Decline: React.FC<{ handleSetState: (value: "approval" | "decline" | "final") => void; statusType: string }> = ({ handleSetState, statusType }) => {
  const { data: paginated } = queries.useGetInvoiceDeclineReasons(statusType);
  const { mutate, isLoading } = mutations.useInvoiceFastDecline();
  const [selected, setSelected] = useState<string | null>(null);
  const { showToast } = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate()

  const data = useMemo(() => {
    if (!paginated || !paginated.items) return [];
    return paginated.items;
  }, [paginated]);

  const isValidSelection = !!selected;

  return (
    <div className="flex flex-col gap-4">
      <label>{t("PleaseSelectYourReasonToReject")}</label>
      <CommonSelect
        placeholder={t('Select')}
        options={data?.map(res => ({ value: res.id, label: t(res.name) }))}
        selectedOptions={selected}
        setSelectedOptions={(value) => {
          setSelected(value)
        }}
        multiSelect={false}
      />
      {data.find(res => res.id === selected)?.entryDescriptionIsRequired &&
        <FormGroup className='mb-0 col-12'>
          <textarea className="form-control min-h-96" rows={3} placeholder={t("EnterAnotherReason")}></textarea>
        </FormGroup>
      }
      <Row className="pb-4 mt-5 verify-footer">
        <Col xs="12" className="p-0">
          <div className="auth-footer px-sm-5 px-3">
            <SolidButton className="btn-gray" onClick={() => navigate(-1)}>
              {t("Cancel")}
            </SolidButton>
            <SolidButton className="custom-confirm-black" color=""
              onClick={() => {
                ConfirmationDialog({
                  title: "Reject Invoice",
                  text: "You're about to reject the invoice. This is a permanent change that cannot be undone.Are you sure you want to reject this invoice?",
                  confirmButtonText: "Reject",
                  onConfirm: (isConfirmed) => {
                    if (isConfirmed) {
                      const invoiceScopeId = getParam("invoiceScopeId");
                      if (!invoiceScopeId) return;
                      mutate(
                        { id: invoiceScopeId, statusId: selected },
                        {
                          onSuccess: () => {
                            handleSetState("final");
                          },
                          onError: (e) => {
                            if (Array.isArray(e)) {
                              showToast("error", getMessages(e));
                            }
                          },
                        }
                      );
                    }
                  }
                });

              }}
              loading={isLoading}
              disabled={!isValidSelection}
            >
              {t("ConfirmRejection")}
            </SolidButton>
          </div>
        </Col>
      </Row>

    </div>
  );
};

const Final: React.FC<{ isApproved: boolean }> = ({ isApproved }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="invoice-approved confirm-invoice">
      <span className="main-invoice custom-scrollbar">
        {isApproved ? (
          <>
            <div className="invoice-img">
              <Image src={dynamicImage("svg/draft-invoice.svg")} alt="draft invoice" />
            </div>
            <h2>{t("GreatToHear")}</h2>
            <div className="invoice-detail">
              <p>{t("ThankyouForAcceptingTheInvoiceWehaveInformedYourEmployeeYouCanLogInToTheDashboardForMoreDetailsOnYourInvoicesAndPaymentInfoWehaveAlsoSentYouAnEmailWithTheInvoiceAttached")}</p>
              <p>{t("IfYouWantToPayByCreditCardPleaseLogInToTheDashboard")}</p>
            </div>
          </>
        ) : (
          <div className="flex flex-col gap-10">
            <Image src={dynamicImage("svg/draft-invoice-reject.svg")} alt="draft invoice" />
            <h2>{t("WeAreSorryToHearThatYouRejectedTheInvoice")}</h2>
            <div className="invoice-detail">
              <p>{t("WeHaveNotifiedYourEmployeeAndTheyWillHandleTheMatterYouCanLogInToYourBusinessPanelToGetMoreDetailsAboutYourInvoices")}</p>
            </div>
          </div>
        )}
        <div className="invoice-approved-footer">
          <div className="auth-footer">
            <SolidButton onClick={() => navigate(ROUTES.BusinessPinLogin)}>{t("ContinueToJuuli")}<SVG iconId="stroke-right-arrow" /></SolidButton>
          </div>
        </div>
      </span>
    </div>
  );
};

const Wrapper: React.FC<{ children: React.ReactNode; showContinueToSystemButton?: boolean, state?: "approval" | "decline" | "final" }> = ({ children, showContinueToSystemButton = true, state }) => {
  const { t } = useTranslation();
  const { isMobile } = useLayoutContext();
  const { signout } = useAuth();
  const logOutHandler = () => {
    signout();
  };

  return (
    <div className={`review-invoice custom-scrollbar ${(state === "decline" && showContinueToSystemButton) ? "review-invoice2" : ""}`}>
      <div className="auth-main h-100 invoice-review approve-invoice1 custom-scrollbar">
        <div className="logo-wrapper">
          <SVG iconId="stroke-right-arrow" />
          {isMobile ?
            <>
              <Button className="btn-close" onClick={logOutHandler} color="transparent"></Button>
              {showContinueToSystemButton && <h1>{t("ReviewInvoice")}</h1>}
            </>
            :
            (
              <div className="cursor-pointer" onClick={logOutHandler}>
                <Image src={dynamicImage("svg/logo/logo.svg")} alt="logo" />
              </div>)}
          <div className="owner-project">
            {showContinueToSystemButton && !(state === "decline" && isMobile) && (<div className="invoice-warning">
              <div className="warning">
                <div className="img-box">
                  <SVG iconId="stroke-warning" className="nav-icon" />
                </div>
                <span>{t("YouAreAlreadyTheOwnerOfThisProject")}</span>
              </div>
            </div>)}
            <p className="mb-0" onClick={logOutHandler}>{t("SignOut")}</p>
            {showContinueToSystemButton && (
              <div>
                <Link to={ROUTES.BusinessPinLogin} className="btn btn-gray d-lg-flex d-none">
                  {t("ContinueToJuuli")}
                  <SVG iconId="stroke-right-arrow" />
                </Link>
              </div>
            )}
          </div>
          <Image src={dynamicImage("svg/leaf.svg")} className="invoice-r-img img-fluid" alt="background image" />
        </div>
        <div className="container-fluid d-flex justify-content-center">
          {children}
        </div>
      </div>
    </div>
  );
};

export const VerifyInvoice: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState<"approval" | "decline" | "final">("decline");
  const [isApproved, setIsApproved] = useState(false);
  const invoiceScopeId = getParam("invoiceScopeId");
  if (!invoiceScopeId || invoiceScopeId == null) {
    document.location.href = ROUTES.BusinessPinLogin;
    return null;
  }

  const { data: invoiceDetails } = queries.useGetInvoiceScopeFast(invoiceScopeId);

  function renderBottomPart() {
    switch (state) {
      case "approval":
        return <Approval handleSetState={(e) => setState(e)} handleApprove={() => setIsApproved(true)} />;
      case "decline":
        return <Decline handleSetState={(e) => setState(e)} statusType={INVOICE_STATUS.WaitingForPayment} />;
      default:
        return null;
    }
  }
  if (!invoiceDetails) {
    return <Loader />;
  }

  function getResponseText() {
    if (!invoiceDetails) return "";
    const date = convertToReadableDate(invoiceDetails.responseDate, "long");
    switch (invoiceDetails.responseStatus) {
      case INVOICE_STATUS.DeclineByBusiness:
        return (
          <>
            <div className="invoice-img">
              <Image src={dynamicImage("svg/draft-invoice-reject.svg")} alt="invoice" />
            </div>
            <h2>{t("YouRejectedThisInvoiceOnDate", { date: date })}</h2>
            <div className="invoice-detail">
              <p className="pt-2">{t("PleaseLogInToYourBusinessPanelToSeeTheReasonForTheRejectionIfThisWasAMistakeOrYouNeedMoreInfoContactYourEmployee")}</p>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className="invoice-img">
              <Image src={dynamicImage("svg/draft-invoice.svg")} alt="invoice" />
            </div>
            <div className="invoice-contain">
              <h2>{t("YouApprovedThisInvoiceOnDate", { date: date })}</h2>
              <div className="invoice-detail">
                <p>{t("WeHaveAlsoNotifiedYourEmployeeLogInToTheDashboardForMoreDetailsWeHaveEmailedYouTheInvoiceEarlierAndPaymentDetailsAreAttachedIfYouHaveAlreadyPaidYouCanIgnoreThisMessage")}</p>
                <p>{t("IfYouWantToPayByCreditCardPleaseLogInToTheDashboard")}</p>
              </div>
            </div>
          </>
        );
    }
  }

  if (invoiceDetails?.responseStatus) {
    return (
      <Wrapper showContinueToSystemButton={false}>
        <div className="invoice-approved approve-verify-invoice">
          <div className="main-invoice custom-scrollbar">
            {getResponseText()}
            <div className="invoice-approved-footer">
              <div className="auth-footer">
                <SolidButton onClick={() => navigate(ROUTES.BusinessPinLogin)}>
                  {t("ContinueToJuuli")}
                  <SVG iconId="stroke-right-arrow" />
                </SolidButton>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  if (state === "final") {
    return (
      <Wrapper showContinueToSystemButton={false} state="final">
        <Final isApproved={isApproved} />
      </Wrapper>
    );
  }

  return (
    <Wrapper state={state}>
      <div className="m-0 auth-body h-100 verify-invoice">
        <Container className="h-100">
          <div className="auth-body-title b-padding px-0">
            <div className="auth-sub-title">
              <div className="d-flex align-items-center gap-3">
                <div className="back-btn">
                  <SVG iconId="stroke-right-arrow" />
                </div>
                <h1>{t("ReviewInvoice")}</h1>
              </div>
            </div>
          </div>
          <div className="verify-invoice-content">
            <Row>
              <Col xs="12">
                <div className="verify-invoice-form m-0 custom-scrollbar">
                  <InvoiceDetails invoiceDetails={invoiceDetails} />
                  {renderBottomPart()}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </Wrapper>
  );
};