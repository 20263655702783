import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { Image } from '../../AbstractElement';
import { convertToReadableAmount, convertToReadableDate, dynamicImage } from '../../utils';
import { useLayoutContext } from '../../views/LayoutProvider';
import { Action, Column, COLUMN_TYPE, TableClickedAction, TableConfig } from '../../Types/CommonComponent';
import useMobileDropDown from '../../Hooks/useMobileDropDown';
import CommonPagination from './Pagination';
import { Link } from 'react-router-dom';
import { useLoader } from '../../views/LoaderProvider';
import { USER_ROLE } from '../../Types';
import { useAuth } from '../../Hooks';
interface TableConfigurationProps<T extends object> {
    tableConfiguration: TableConfig<T>;
    pagingDisabled?: boolean;
    currentPage?: number;
    itemsPerPage?: number;
    toggleSortType?: any;
    handleSetSortBy?: any;
    goToPage?: (value: number) => void;
    handleSetPageSize?: (value: number) => void;
    onActionPerform?: (action: TableClickedAction<T>) => void;
}


export const CommonTable = <T extends object>({ tableConfiguration, pagingDisabled = false, currentPage = 1, itemsPerPage = 5, toggleSortType, handleSetSortBy, goToPage, handleSetPageSize, onActionPerform }: TableConfigurationProps<T>) => {
    const { t } = useTranslation();
    const { dropDownOverlay } = useLayoutContext();
    const { isLoading } = useLoader();
    const { user } = useAuth();
    const [dropdownOpen, toggleDropdown] = useMobileDropDown(dropDownOverlay);
    const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);
    const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>(
        tableConfiguration.columns.reduce((acc, column) => {
            acc[column.title] = column?.hidden === true ? false : true; // Set all columns to visible by default
            return acc;
        }, {} as Record<string, boolean>)
    );
    const observerRef = useRef<IntersectionObserver | null>(null);

    const { isMobile } = useLayoutContext()
    // Track the currently active sorted column and direction
    const [activeSort, setActiveSort] = useState<{ field: keyof T; direction: 'asc' | 'desc' | null } | null>(null);

    const [checkDropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    const handleDropdownToggle = (index: number) => {
        setOpenDropdownIndex(openDropdownIndex === index ? null : index);

    };

    const handleCheckboxChange = (columnTitle: string) => {
        setColumnVisibility(prevState => ({
            ...prevState,
            [columnTitle]: !prevState[columnTitle] // Toggle the visibility
        }));
    };

    const toggleSort = (column: Column<T>) => {
        document.body.classList.add('no-loader');
        if (column.sortField) {
            // Toggle between 'asc' and 'desc', and set the active sorted column
            const newDirection = activeSort?.field === column.sortField
                ? (activeSort.direction === 'asc' ? 'desc' : 'asc')
                : 'asc';

            setActiveSort({ field: column.sortField, direction: newDirection });
            toggleSortType?.(column);
            handleSetSortBy?.(column.sortField);
        }
        document.body.classList.add('no-loader');

    };

    const isConditionMet = (action: Action, columnData: any) => {
        switch (action.conditional?.condition) {
            case '?':
                return columnData[action.conditional.field] ? action.conditional?.actionLabel?.split(':')[0] : action.conditional?.actionLabel?.split(':')[1];
            case 'include':
                return action.conditional.conditionValue?.includes(columnData[action.conditional.field])
            case 'notInclude':
                return !action.conditional.conditionValue?.includes(columnData[action.conditional.field])
            case '===':
                return columnData[action.conditional.field]
            case '!=':
                return !columnData[action.conditional.field]
            default:
                return action.label;
        }

    }

    useEffect(() => {
        if ((openDropdownIndex !== null || checkDropdownOpen) && isMobile) {
            document.body.classList.add('dropdown-open');
        } else {
            document.body.classList.remove('dropdown-open');
        }

        // Cleanup function to remove the class when the component unmounts
        return () => {
            document.body.classList.remove('dropdown-open');
        };
    }, [openDropdownIndex, checkDropdownOpen, isMobile]);

    useEffect(() => {
        if (!isLoading) {
            document.body.classList.remove('no-loader');
        }
    }, [isLoading])

    // Calculate paginated data
    const perPageValues = [5, 10, 15]; // Customize dropdown values
    const indexOfLastItem = Math.min(currentPage * itemsPerPage, tableConfiguration.total);
    const indexOfFirstItem = (currentPage - 1) * itemsPerPage;

    useEffect(() => {
        if (goToPage && tableConfiguration.total > 0 && tableConfiguration.data.length === 0) {
            goToPage(Math.ceil(tableConfiguration.total / itemsPerPage));
        }
    }, [tableConfiguration.data, tableConfiguration.total])

    return (
        <>
            <div className="custom-scroll table-responsive">
                <Table borderless className={user?.role === USER_ROLE.Business ? 'business-table' : ''}>
                    <thead>
                        <tr>
                            {tableConfiguration.columns.map((item, headRowIndex) =>
                                columnVisibility[item.title] && (
                                    <th key={`tableHead_${headRowIndex}_${item.title}`} onClick={() => { item.sortable && toggleSort(item) }}>
                                        {item.title &&
                                            <span className={item.class}>
                                                {t(item.title)}
                                                {item.sortable && (activeSort?.field === item.sortField ? (
                                                    activeSort?.direction === 'asc' ? (
                                                        <Image
                                                            src={dynamicImage("svg/ascending.svg")}
                                                            alt="arrow-icon"
                                                            className="arrow-icon up"
                                                        />
                                                    ) : (
                                                        <Image
                                                            src={dynamicImage("svg/descending.svg")}
                                                            alt="arrow-icon"
                                                            className="arrow-icon down"
                                                        />
                                                    )
                                                ) : (
                                                    <Image
                                                        src={dynamicImage("svg/ascending-descending.svg")}
                                                        alt="arrow-icon"
                                                        className="arrow-icon"
                                                    />
                                                ))}
                                            </span>
                                        }
                                    </th>
                                )
                            )}
                            {tableConfiguration.isColumnChooser && (
                                <th className="responsive-dropdown">
                                    <div className="onhover-dropdown">
                                        <Dropdown className='column-dropdown' isOpen={checkDropdownOpen} toggle={toggle}>
                                            <DropdownToggle caret color='transparent'>
                                                <div className="action-img">
                                                    <Image src={dynamicImage("svg/icon_filters_table.svg")} alt="arrow" />
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu container="body" className='column-dropdown-menu custom-scroll'>
                                                {tableConfiguration.columns.filter(f => f.title).map((column, index) => (
                                                    <div key={`columnChooser_${index}`} className='dropdown-item'>
                                                        <DropdownItem toggle={false} key={column.title} onClick={() => handleCheckboxChange(column.title)} >
                                                            <div className="form-check">
                                                                <Input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    checked={columnVisibility[column.title]}
                                                                    disabled={columnVisibility[column.title] && Object.values(columnVisibility).filter(f => f === false).length >= tableConfiguration.columns.filter(f => f.title).length - 1}
                                                                    onClick={(e) => e.stopPropagation()}
                                                                    onChange={(e) => handleCheckboxChange(column.title)}  // Handle checkbox change directly
                                                                    autoComplete="off"
                                                                />
                                                                {t(column.title)}
                                                            </div>
                                                        </DropdownItem>
                                                    </div>
                                                ))}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody className="responsive-body">
                        {
                            isLoading &&
                            <p className="table-loader">Please wait...</p>
                        }
                        {
                            tableConfiguration.data.length > 0 && tableConfiguration.data.map((columnData, rowIndex) => (
                                <tr key={`row_${rowIndex}`} >
                                    {tableConfiguration.columns.map(
                                        (item, headRowIndex) =>
                                            columnVisibility[item.title] && (
                                                <td key={`head_${headRowIndex}_${item.title}`} className={`${item.class ?? ''}${item.dataField?.some(s => s.class?.includes('hover-link')) ? ' hover-link' : ''}`}
                                                    onClick={() => {
                                                        if (item.class?.includes("hover-link") || item.dataField?.some(s => s.class?.includes("hover-link"))) {
                                                            const accessibleAction = tableConfiguration.actionsDropDown.find(
                                                                (action) =>
                                                                    !action.conditional?.condition || isConditionMet(action, columnData)
                                                            );

                                                            if (accessibleAction?.getNavigateUrl) {
                                                                window.location.href = accessibleAction.getNavigateUrl(columnData);
                                                            } else if (accessibleAction?.actionToPerform) {
                                                                onActionPerform?.({
                                                                    actionToPerform: accessibleAction.actionToPerform,
                                                                    data: columnData,
                                                                });
                                                            }
                                                        }
                                                    }}>
                                                    {isMobile && <span className="responsive-span">{t(item.title)}</span>}
                                                    <div className="responsive-box">
                                                        {item.dataField?.map((key) => (
                                                            <React.Fragment key={`${key.field.toString()}_field`}>
                                                                {
                                                                    key.renderer ? key.renderer(columnData)
                                                                        : key.type === COLUMN_TYPE.Amount ? <span className={`${key.class ? key.class : "amount-right"}`}>{convertToReadableAmount(columnData[key.field], key.params ? columnData[key.params.currency] : undefined)}</span>
                                                                            : key.type === COLUMN_TYPE.Date ? <span className={`${key.class}`}>{convertToReadableDate(columnData[key.field], key.params ? key.params.format : undefined, key.params ? key.params.customFormat : undefined)}</span>
                                                                                : key.type === COLUMN_TYPE.TextProfile ?
                                                                                    <div className="initial-letter d-sm-flex d-none">
                                                                                        <span>{
                                                                                            columnData?.[key?.field]?.split(' ')?.map((letter: string) => letter.charAt(0).toUpperCase())?.join('')?.slice(0, 2)
                                                                                        }</span>
                                                                                    </div>
                                                                                    : key.type === 'date' ? <span>{convertToReadableDate(columnData[key.field])}</span>
                                                                                        : <span className={`${key.class}`}>{columnData[key.field]}</span>
                                                                }
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                </td>
                                            )
                                    )}
                                    {tableConfiguration.actionsDropDown.length > 0 ? (
                                        <td className="responsive-dropdown">
                                            <Dropdown className='select-dropdown-2' isOpen={openDropdownIndex === rowIndex} onClick={(e) => e.preventDefault()} toggle={() => handleDropdownToggle(rowIndex)}>
                                                <DropdownToggle tag="button" className="dropdown-toggle select-dropdown-btn">
                                                    <Image src={dynamicImage("svg/icon-action.svg")} alt="actions" />
                                                </DropdownToggle>
                                                <DropdownMenu end className='select-dropdown-menu' container="body">
                                                    <Button type='button' color='transparent' className="img-box" onClick={() => handleDropdownToggle(rowIndex)}>
                                                        <Image src={dynamicImage("svg/icon-action.svg")} alt="actions" />
                                                    </Button>
                                                    {tableConfiguration.actionsDropDown.map((action, actionIndex) => (
                                                        <Fragment key={actionIndex}>
                                                            {(!action.conditional?.condition || isConditionMet(action, columnData)) &&
                                                                <>
                                                                    {
                                                                        action.getNavigateUrl ?
                                                                            <DropdownItem key={actionIndex}>
                                                                                <Link to={action.getNavigateUrl(columnData)} >
                                                                                    {t(action.label)}
                                                                                </Link>
                                                                            </DropdownItem>
                                                                            : action.actionToPerform &&
                                                                            <DropdownItem key={actionIndex} onClick={() => {
                                                                                onActionPerform?.({ actionToPerform: action.actionToPerform!, data: columnData });
                                                                            }}>
                                                                                {(action.conditional?.condition === '?' ? isConditionMet(action, columnData) : t(action.label))}
                                                                            </DropdownItem>
                                                                    }
                                                                </>
                                                            }
                                                        </Fragment>
                                                    ))}
                                                </DropdownMenu>
                                            </Dropdown>
                                        </td>
                                    ) : tableConfiguration.isColumnChooser && <td></td>}
                                </tr>
                            ))
                        }
                    </tbody>
                </Table >
            </div>
            {pagingDisabled !== true && goToPage && handleSetPageSize && <div className="table-footer pagination-box">
                <CommonPagination
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    totalItems={tableConfiguration.total}
                    goToPage={goToPage}
                    handleSetPageSize={handleSetPageSize}
                />

                {/* Items per page dropdown on the right side */}
                <div className="pagination-info">
                    <label className='mr-2 mb-0 text-nowrap d-sm-flex d-none'>{t('Items per page')} :</label>
                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                        <DropdownToggle caret color='transparent'>
                            {itemsPerPage}
                        </DropdownToggle>
                        <DropdownMenu end className='select-dropdown-menu'>
                            {perPageValues.map((number) => (
                                <DropdownItem key={number} onClick={() => handleSetPageSize(number)}>
                                    {number}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                    <span>{`${indexOfFirstItem + 1}-${indexOfLastItem} of ${tableConfiguration.total}`}</span>
                </div>
            </div>
            }
        </>
    );
};

export default CommonTable;
