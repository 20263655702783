import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Image } from "../../AbstractElement";
import { convertToReadableDate, dynamicImage } from "../../utils";
import { useLayoutContext } from "../../views/LayoutProvider";
import useDropdown from "../../Hooks/useDropdown";
import { queries } from "../../api";
import { useAuth } from "../../Hooks";
import SVG from "../../CommonElement/SVG/Svg";
import { useStateContext } from "../../views/stateProvider";
import InvoiceDetailCanvas from "../../Components/Projects/Canvas/InvoiceDetailCanvas";
import { InvoiceHistory, InvoiceScope } from "../../Types";

const NotificationSection: React.FC = () => {
  const { toggleState } = useLayoutContext();
  const { t } = useTranslation();
  const { data: activities } = queries.useGetActivities();
  const { user } = useAuth();
  const notificationDropdown = useDropdown<HTMLLIElement>(toggleState);
  const { invoiceDetail, toggleInvoiceDetail, setInvoiceScope, setInvoiceScopeLog } = useStateContext();
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | null>(null);

  queries.useGetInvoiceScope(selectedInvoiceId!, {
    onSuccess: (data: InvoiceScope) => {
      setInvoiceScope(data);
    },
  });

  queries.useGetInvoiceScopeLog(selectedInvoiceId!, {
    onSuccess: (data: InvoiceHistory[]) => {
      setInvoiceScopeLog(data);
    },
  });

  return (
    <>
      <li className="notification-box onhover-dropdown" ref={notificationDropdown.ref} onClick={notificationDropdown.toggle}>
        <div>
          <Image src={dynamicImage("svg/icon_notification.svg")} className="nav-icon" alt="logo" />
          {activities?.items && activities?.items?.length > 0 && <div className="notify-badge" />}
        </div>
        {notificationDropdown.isOpen && (
          <ul className="notification-box-menu onhover-dropdown-menu open">
            <h6><span>{activities?.totalResults}</span> {t("NewActivity")}</h6>
            <ul> 
              {activities?.items.map((item, index) => (
                <li key={index}>
                  <p>
                    {t(`${user?.role}.Notification.${item.status}`, item)} {convertToReadableDate(item.createdAt, "long")}
                  </p>
                  {item.invoiceScopeId &&
                    <div className="view-more" onClick={() => { toggleInvoiceDetail(); setSelectedInvoiceId(item.invoiceScopeId); }}>
                      {t("View")}
                      <SVG iconId="stroke-right-arrow" className="nav-icon" />
                    </div>
                  }
                </li>
              ))}
            </ul>
          </ul>
        )}
      </li>
      {invoiceDetail && <InvoiceDetailCanvas />}
    </>
  );
};

export default NotificationSection;
