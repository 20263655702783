/* Header logo wrapper file */
import { Link, useLocation } from 'react-router-dom';
import { dynamicImage } from '../../utils';
import { ROUTES } from '../../Constants';
import { Image } from '../../AbstractElement';
import { useLayoutContext } from '../../views/LayoutProvider';

const LogoWrapper = () => {
    const { toggleSidebar } = useLayoutContext();
    const location = useLocation();
    const path = location.pathname.split("/")

    return (
        <div className="header-logo-wrapper col-auto">
            <div className={`toggle-sidebar curser-pointer ${path.length===3 ? "d-none" : ""}`} onClick={toggleSidebar}>
                <Image src={dynamicImage("svg/menu_button.svg")} className="open-icon" alt="menu-icon" />
                <Image src={dynamicImage("svg/close-red.svg")} className="close-icon" alt="menu-icon" />
            </div>
            <div className="logo-wrapper d-md-flex d-none">
                <Link to={ROUTES.Dashboard}>
                    <Image src={dynamicImage("svg/logo/logo.svg")} alt="logo" />
                </Link>
            </div>
        </div>
    );
}
export default LogoWrapper;
