export const INVOICE_STATUS = {
    Draft: "Draft",
    WaitingForSentToBusiness: "WaitingForSentToBusiness",
    DeclineByJuuli: "DeclineByJuuli",
    WaitingForApproval: "WaitingForApproval",
    DeclineByBusiness: "DeclineByBusiness",
    WaitingForPayment: "WaitingForPayment",
    WaitingForPaymentAgreement: "WaitingForPaymentAgreement",
    WaitingForPaymentApprove: "WaitingForPaymentApprove",
    TransferFailedPayment: "TransferFailedPayment",
    WaitingForTransfer: "WaitingForTransfer",
    TransferFailed: "TransferFailed",
    CompletedTransferToFreelancer: "CompletedTransferToTalent",
    InvoiceCancelled: "InvoiceCancelled",
} as const;

export type InvoiceStatus = ObjectValues<typeof INVOICE_STATUS>;
