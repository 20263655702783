import { useEffect, useState } from 'react'
import { Image, SolidButton } from '../../../AbstractElement'
import { Header } from './Header'
import { Col, Container, FormGroup, Row } from 'reactstrap'
import { dynamicImage, getMessages } from '../../../utils'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ROUTES, SITE_SETTINGS } from '../../../Constants'
import { mutations, queries } from '../../../api'
import useToast from '../../../views/ToastWrapper'
import { useAuth } from '../../../Hooks'
import { CONTRACT_TYPE, USER_ROLE } from '../../../Types'
import { appInsights } from '../../../api/appInsights'
import { LayoutLoader } from '../../../CommonComponent/loader'
import { useLayoutContext } from '../../../views/LayoutProvider'

const AgreementPolicy = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const { showToast } = useToast();
  const { signout, user } = useAuth();
  const { isMobile } = useLayoutContext()

  const { data: missingAgreements, isLoading: isLoadingMissing, isFetched } = queries.useGetMissingAgreements();
  const { mutate: approvePolicy, isLoading: isLoadingApprove } = mutations.useApproveAgreement();

  useEffect(() => {
    if (missingAgreements && missingAgreements.length === 0) {
      navigate(ROUTES.Home);
    }
  }, [isFetched, missingAgreements, navigate]);

  const onSubmitAcceptPolicy = async () => {
    try {
      // Filter agreementsToAccept to include only those also in missingAgreements
      const agreementsToApprove = missingAgreements!.filter(f => f == CONTRACT_TYPE.PrivacyPolicy || f == CONTRACT_TYPE.TermsAndConditions);
      for (const agreementToApprove of agreementsToApprove) {

        approvePolicy(
          { contractType: agreementToApprove },
          {
            onSuccess: () => {

              if (agreementToApprove === CONTRACT_TYPE.PrivacyPolicy && (user?.role === USER_ROLE.Freelancer || user?.role === USER_ROLE.Business)) {
                let eventType = user?.role === USER_ROLE.Freelancer ? "ta_signup" : "bu_signup";

                try {
                  (window as any)?.dataLayer.push({ event: eventType });
                } catch (ex) {
                  appInsights.trackException({
                    error: new Error(`Could not trigger ${eventType} event`),
                    properties: { error: ex }
                  });
                }
              }
            },
            onError: (err) => {
              showToast("error", getMessages(err));
            }
          }
        );
      }
    } catch (error) {
      showToast("error", "AgreementPolicy.ErrorApprovingTheAgreementPleaseTryAgain");
    }
  };

  return (
    <div className="vh-100">
      <div className="auth-main h-100">
        <Image src={dynamicImage("svg/flower.svg")} alt="logo" className="flower" />
        {!isMobile && <span className="help-div">
          <Image src={dynamicImage("svg/icon_help1.svg")} alt="fill-star" onClick={() => { (window as any)?.Beacon('open'); }} />
        </span>}
        <Header />
        <div className="auth-body agreements-body h-100">
          <Container className="h-100" >
            <div className="auth-body-title t-padding b-padding">
              <div className="auth-sub-title">
                <h1 className="text-capitalize">{t('Agreements')}</h1>
              </div>
            </div>
            <Row>
              {isLoadingMissing || !missingAgreements
                ? <LayoutLoader />
                : <Col xxl="6" lg="7" md="8" xs="12" className="mx-auto mb-4 custom-form-w">
                  <div className="verification-form">
                    <FormGroup className="form-group mb-0">
                      <div className="form-check mx-sm-4 mx-1 check-required">
                        <input type="checkbox" className={`form-check-input}`} id="terms" checked={isChecked} onChange={() => setIsChecked((prev) => !prev)} />
                        <label htmlFor="terms" className="cursor-pointer" dangerouslySetInnerHTML={{
                          __html: t('IAgreeToPrivacyPolicyAndTermsAndConditions', {
                            privacyPolicy: SITE_SETTINGS.PRIVACY_POLICY,
                            termsAndConditions: SITE_SETTINGS.TERMS_CONDITIONS,
                          })
                        }}></label>
                      </div>
                    </FormGroup>
                    <div className="action-btn">
                      <SolidButton type="submit" disabled={!isChecked} loading={isLoadingApprove} onClick={onSubmitAcceptPolicy}>{t("IAccept")}</SolidButton>
                      <SolidButton className="btn-gray" onClick={() => signout()}>{t("SignOut")}</SolidButton>
                    </div>
                  </div>
                </Col>}
            </Row>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default AgreementPolicy;




