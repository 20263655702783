import { useState, useRef, useEffect } from "react";
import { UseDropdownReturn } from "../Types";

const useDropdown = <T extends HTMLElement>(callback?: VoidFunction): UseDropdownReturn<T> => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const ref = useRef<T>(null);

    const toggle = () => {
        setIsOpen((prev) => !prev);
        if (callback) {
            callback();
        }
    };

    const close = () => setIsOpen(false);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                close();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return { ref, isOpen, toggle, close };
};

export default useDropdown;