import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SolidButton } from '../../../../AbstractElement';
import { mutations, queries } from '../../../../api';
import { ROUTES } from '../../../../Constants';
import Loader from '../../../../Layout/Loader';
import { InvoiceHistory, InvoiceScope } from '../../../../Types';
import { DefaultErrorResponse } from '../../../../Types/ErrorResponse';
import { getMessages, getParam } from '../../../../utils';
import { useStateContext } from '../../../../views/stateProvider';
import useToast from '../../../../views/ToastWrapper';
import InvoiceDetailCanvas from '../../../Projects/Canvas/InvoiceDetailCanvas';
import ViewInvoiceModal from '../../../Projects/Canvas/ViewInvoiceModal';

const StripeRedirect = () => {
    const { mutate: pay } = mutations.usePayWithCreditCard();
    const navigate = useNavigate();
    const [errors, setErrors] = useState<DefaultErrorResponse["data"]>([]);
    const { showToast } = useToast();
    const { toggleInvoiceDetail, invoiceDetail, setInvoiceScopeLog, setInvoiceScope, viewInvoice } = useStateContext();
    const { t } = useTranslation();
    const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false); // New state to track payment success

    queries.useGetInvoiceScope(selectedInvoiceId!, {
        onSuccess: (data: InvoiceScope) => {
            setInvoiceScope(data);
        },
    });

    queries.useGetInvoiceScopeLog(selectedInvoiceId!, {
        onSuccess: (data: InvoiceHistory[]) => {
            setInvoiceScopeLog(data);
        },
    });

    useEffect(() => {
        const invoiceScopeId = getParam("invoiceScopeId");
        if (!invoiceScopeId) {
            navigate(ROUTES.Dashboard);
            return;
        }

        setSelectedInvoiceId(invoiceScopeId);
        let attemptCount = 0;
        const maxAttempts = 20;
        const interval = 3000;

        const attemptPayment = () => {
            if (attemptCount >= maxAttempts || isPaymentSuccessful) {
                setIsLoading(false);
                toggleInvoiceDetail();
                return;
            }
            attemptCount += 1;

            pay(invoiceScopeId, {
                onSuccess: () => {
                    setIsLoading(false);
                    setIsPaymentSuccessful(true);
                    navigate(ROUTES.PaymentAgreement.replace(':id', invoiceScopeId.toString()));
                },
                onError: (e) => {
                    setErrors((e as DefaultErrorResponse).data);
                    setTimeout(attemptPayment, interval);

                    if (Array.isArray(e)) {
                        showToast("error", getMessages(e));
                    }
                },
            });
        };

        attemptPayment();

        const timeoutId = setTimeout(() => {
            if (!isPaymentSuccessful) {
                setIsLoading(false);
                toggleInvoiceDetail();
            }
        }, maxAttempts * interval);

        return () => clearTimeout(timeoutId);
    }, [pay, navigate, showToast, toggleInvoiceDetail, isPaymentSuccessful]);

    if (errors.length > 0)
        return (
            <div>
                <p>{t("SomethingWentWrongDuringStripeRedirection")}</p>:
                <SolidButton onClick={navigate(ROUTES.Dashboard)}>{t("GoToDashboard")}</SolidButton>
            </div>
        );
    return <>
        <>
            {isLoading ? (
                <Loader />
            ) : (
                isPaymentSuccessful && ( // Show invoice details only on successful payment
                    <>
                        {invoiceDetail && <InvoiceDetailCanvas />}
                        {viewInvoice && <ViewInvoiceModal id={selectedInvoiceId!} />}
                    </>
                )
            )}
        </>
    </>
};

export default StripeRedirect;