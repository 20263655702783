import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, FormGroup, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from "reactstrap";
import { useForm } from "react-hook-form";

import { SolidButton } from "../../../AbstractElement";
import { mutations, queries } from "../../../api";
import { CommonSelect } from "../../../CommonComponent";
import { useCoreData } from "../../../Hooks";
import useToast from "../../../views/ToastWrapper";
import { useStateContext } from "../../../views/stateProvider";

const SettingCanvas: React.FC = () => {
  const { t } = useTranslation();
  const { sidebarSetting, toggleSidebarSetting } = useStateContext();
  const [language, setLanguage] = useState<string>();
  const [timeZone, setTimeZone] = useState<string>();
  const [selectedCurrencyOptions, setSelectedCurrencyOptions] = useState<string[] | string>('');
  const { currencyPayInOutOptions, timeZoneOptions, languageOptions } = useCoreData({ loadCurrenciesPayInOut: true, loadTimeZones: true, loadLanguages: true });

  const { data: userDetails } = queries.useGetUserDetails();
  const { mutate: updateAccount, isLoading: updateLoading } = mutations.useUpdateAccount();
  const { showToast } = useToast()

  const { setValue, handleSubmit } = useForm<any>({
    defaultValues: {
      defaultCurrencyCode: null,
      language: null,
      timeZone: null
    }
  });

  useEffect(() => {
    if (userDetails) {
      setLanguage(userDetails.language!)
      setTimeZone(userDetails.timeZone!)
      setSelectedCurrencyOptions(userDetails.defaultCurrencyCode!)
    }
  }, [userDetails, setValue]);

  const onSave = (data: any) => {
    const updatedData = { ...userDetails, ...data, language, timeZone, defaultCurrencyCode: selectedCurrencyOptions, };
    updateAccount(updatedData, {
      onSuccess: () => {
        showToast("success", "SettingsCanvas.UpdatedSuccessfully")
        toggleSidebarSetting();
      },
    });
  };

  return (
    <Offcanvas isOpen={sidebarSetting} toggle={toggleSidebarSetting} direction="end" className="theme-offcanvas-2">
      <div className="theme-offcanvas-box">
        <div className="w-100">
          <OffcanvasHeader toggle={toggleSidebarSetting}>{t("Settings")}</OffcanvasHeader>
          <Button className="btn-close" onClick={toggleSidebarSetting} color="transparent"></Button>
          <OffcanvasBody className="custom-scroll d-block">
            <div className="setting-main">
              <h6 className="setting-title">{t("Localization")}</h6>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label>{t("Language")}</label>
                    <CommonSelect
                      placeholder={t('Select')}
                      options={languageOptions!}
                      selectedOptions={language}
                      setSelectedOptions={(value) => {
                        setLanguage(value);
                        setValue('language', value);
                      }}
                      multiSelect={false}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label>{t("TimeZone")}</label>
                    <CommonSelect
                      placeholder={t('Select')}
                      options={timeZoneOptions!}
                      selectedOptions={timeZone}
                      setSelectedOptions={(value) => {
                        setTimeZone(value);
                        setValue('timeZone', value);
                      }}
                      multiSelect={false}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label>{t("PreferredCurrency")}</label>
                    <CommonSelect
                      placeholder={t('Select')}
                      options={currencyPayInOutOptions!}
                      selectedOptions={selectedCurrencyOptions}
                      setSelectedOptions={(value) => {
                        setSelectedCurrencyOptions(value);
                        setValue('defaultCurrencyCode', value);
                      }}
                      multiSelect={false}
                      search={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="offcanvas-footer">
                <SolidButton className="btn-gray d-flex justify-content-center" onClick={toggleSidebarSetting}>
                  <span>{t("Cancel")}</span>
                </SolidButton>
                <SolidButton className="d-flex justify-content-center btn-solid" onClick={handleSubmit(onSave)} loading={updateLoading}>{t("Save")}</SolidButton>
              </div>
            </div>
          </OffcanvasBody>
        </div>
      </div>
    </Offcanvas>
  );
};

export default SettingCanvas;