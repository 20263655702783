import { KEYS } from "../Constants";
import put from "./put";
import post from "./post";
import remove from "./remove";
import getBlob from "./getBlob";
import { URL_KEYS } from "../Constants/url";
import { useApiDelete, useApiPost } from "../Hooks";
import { Account, Client, ContractType, DraftInvoice, DraftInvoiceCreateRequest, DraftInvoiceUpdateRequest, Freelancer, FreelancerFormInput, QuickInvoiceRequest } from "../Types";
import { QuestionnaireInput } from "../Types/Profile";
import { BankAccount, BankAccountInput } from "../Types/BankAccount";
import { Project, ProjectDocument, ProjectFormInput } from "../Types/Projects";
import { ClientInput } from "../Types/Client";
import { InvoiceLine } from "../Types/InvoiceItem";
import { DuplicateInvoiceResponse, InvoiceScope } from "../Types/InvoiceScope";
import { FreelancerUpdateFormInput } from "../Types/Freelancer";
import { ServiceFeePayer } from "../Types/DraftInvoice";

export const mutations = {
    // Account
    useSendEmailVerificationPin: () =>
        useApiPost<void, void>([KEYS.SEND_EMAIL_VERIFICATION_PIN], () => post(URL_KEYS.Account.FreelancerEmailVerificationPin)),
    useVerifyEmail: () =>
        useApiPost<string, void>([KEYS.ACCOUNT, KEYS.VERIFY_EMAIL], (verifyCode) =>
            put(URL_KEYS.Account.FreelancerEmailVerification, { verifyCode })
        ),
    usePutAccount: () =>
        useApiPost<Partial<Account>, Account>([KEYS.ACCOUNT], (input) => put(URL_KEYS.Account.AccountInfo, input)
        ),

    useApplyForDebitCard: () => useApiPost<undefined, any>([KEYS.DEBIT_CARD], () => post(URL_KEYS.Account.DebitCard)),
    useApplyForIban: () => useApiPost<undefined, any>([KEYS.IBAN_APPLICATION], () => post(URL_KEYS.Account.IbanApplication)),


    // Auth

    useBusinessSignUp: () =>
        useApiPost<any, any>([KEYS.BUSINESS_SIGNUP], (input) =>
            post(URL_KEYS.Auth.BusinessSignUp, input)
        ),

    useSendPinEmail: () =>
        useApiPost<string, void>([KEYS.SEND_PIN_BY_EMAIL], (email) =>
            post(URL_KEYS.Auth.SendPinToBusinessByEmail, {
                email
            })
        ),

    useSendPin: () => useApiPost<void, void>([KEYS.SEND_PIN], () => post(URL_KEYS.Auth.SendBusinessPin)),

    useRequestForgotPassword: () =>
        useApiPost<{ email: string }, void>([KEYS.SEND_PASSWORD_MAIL], (input) => post(URL_KEYS.Auth.SendForgotPasswordMail, input)),

    useResetPassword: () =>
        useApiPost<{ token: string; password: string }, Account>([KEYS.RESET_PASSWORD], (input) =>
            put(URL_KEYS.Auth.FreelancerResetPassword, input)
        ),

    // Delete
    useDeleteAccount: () =>
        useApiPost<{ deletionReason: string }, any>(
            [KEYS.ACCOUNT],
            (input) => post(URL_KEYS.Account.DeleteAccount, input)
        ),

    // Agreements
    useApproveAgreement: () =>
        useApiPost<{ contractType: string }, void>([KEYS.AGREEMENTS], (input) => post(URL_KEYS.UserAgreement.ApproveAgreement, input)),

    useDownloadAgreement: () =>
        useApiPost<{ id: string, fileName?: string }, any>([KEYS.USER_AGREEMENT_DOWNLOAD], ({ id, fileName }) =>
            getBlob(`${URL_KEYS.UserAgreement.UserAgreement}/${id}/Download`, fileName ?? "default_filename")
        ),

    useSignatureLink: () =>
        useApiPost<{ contractType: ContractType }, { url: string }>([KEYS.AGREEMENTS], (input) => post(`${URL_KEYS.UserAgreement.UserAgreement}/GenerateSignatureLink`, input)),

    useGenerateSignatureLink: () =>
        useApiPost<{ id: string }, { url: string }>([KEYS.PAYMENT_AGREEMENTS], ({ id }) => post(`${URL_KEYS.InvoiceScope.InvoiceScope}/${id}/GenerateSignatureLink`)),


    // Questionnaire
    useQuestionnaire: () =>
        useApiPost<QuestionnaireInput, any>([KEYS.HAS_QUESTIONNAIRE, KEYS.ACCOUNT], (input) =>
            put(URL_KEYS.Account.Questionnaire, input)
        ),

    //UpdatePassword
    useUpdatePassword: () =>
        useApiPost<{ oldPassword: string; password: string }, void>([KEYS.UPDATE_PASSWORD], (input) =>
            put(URL_KEYS.Account.UpdatePassword, input),
        ),

    // Account
    useUpdateAccount: () =>
        useApiPost<Partial<Account>, Account>([KEYS.ACCOUNT], (input) =>
            put(URL_KEYS.Account.AccountInfo, input)
        ),

    // Bank Account

    // Add
    useAddBankAccount: () =>
        useApiPost<BankAccountInput, BankAccount>([KEYS.BANK_ACCOUNT, KEYS.ACCOUNT, KEYS.SUGGESTION], (input) =>
            post(URL_KEYS.BankAccount.BankAccount, input)
        ),

    // Update
    useUpdateBankAccount: () =>
        useApiPost<{ id: string; input: BankAccountInput }, BankAccount>([KEYS.BANK_ACCOUNT, KEYS.ACCOUNT, KEYS.SUGGESTION], (params) =>
            put(`${URL_KEYS.BankAccount.BankAccount}/${params.id}`, params.input)
        ),

    // Delete
    useDeleteBankAccount: () =>
        useApiDelete([KEYS.BANK_ACCOUNT, KEYS.SUGGESTION], (id) =>
            remove(`${URL_KEYS.BankAccount.BankAccount}/${id}`)
        ),

    // Project 

    // Add
    useAddProject: () =>
        useApiPost<ProjectFormInput, Project>([KEYS.PROJECT], (input) =>
            post(URL_KEYS.Project.Project, input)
        ),

    // Update
    useUpdateProject: () =>
        useApiPost<{ id: string; input: ProjectFormInput }, Project>([KEYS.PROJECT], (params) =>
            put(`${URL_KEYS.Project.Project}/${params.id}`, params.input)
        ),

    // Delete
    useDeleteProject: () =>
        useApiDelete<{ id: string }, any>([KEYS.PROJECT], (params) =>
            remove(`${URL_KEYS.Project.Project}/${params.id}`)
        ),

    // Upload Document
    useUploadProjectDocument: () =>
        useApiPost<any, ProjectDocument>([KEYS.PROJECT_UPLOAD_DOCUMENT, KEYS.PROJECT_DOCUMENTS], (data) => {
            if (data && data.file) {
                const formData = new FormData();
                formData.append('file', data.file, data.file.name);
                return post(`${URL_KEYS.Project.Project}/${data.id}/Document/Upload`, formData);
            }
            throw new Error('No data provided for document upload');
        }),

    // Document download
    useDownloadProjectDocument: () =>
        useApiPost<{ projectId: string, documentId: string, fileName?: string }, any>([KEYS.PROJECT_DOCUMENT_DOWNLOAD], ({ projectId, documentId, fileName }) =>
            getBlob(`${URL_KEYS.Project.Project}/${projectId}/Document/${documentId}/Download`, fileName ?? "default_filename")
        ),

    useUpdateProjectDocument: () =>
        useApiPost<any, any>([KEYS.SHARE_FREELANCERS, KEYS.PROJECT_DOCUMENTS], (value) =>
            put(`${URL_KEYS.Project.Project}/${value.projectId}/Document/${value.id}`, value.input)
        ),

    // Delete
    useDeleteProjectDocument: () =>
        useApiDelete<any, Project>([KEYS.PROJECT_DELETE_DOCUMENT, KEYS.PROJECT_DOCUMENTS], (params) =>
            remove(`${URL_KEYS.Project.Project}/${params.projectId}/Document/${params.documentId}`)
        ),

    // ArchiveStatus
    useArchiveProject: () =>
        useApiPost<{ id: string, archived: boolean }, Project>([KEYS.PROJECT], ({ id, archived }) =>
            post(`${URL_KEYS.Project.Project}/${id}/ArchiveStatus`, { archived: archived })
        ),

    // Invoice Scope

    // Duplicate
    useDuplicateInvoice: () =>
        useApiPost<any, DuplicateInvoiceResponse>([KEYS.INVOICE_SCOPE_DUPLICATE], (id) =>
            post(`${URL_KEYS.InvoiceScope.InvoiceScope}/${id}/Duplicate`, {})
        ),

    // Send Reminder
    useSendReminderInvoice: () =>
        useApiPost<any, any>([KEYS.INVOICE_SCOPE_SEND_REMINDER], (id) =>
            post(`${URL_KEYS.InvoiceScope.InvoiceScope}/${id}/SendReminder`, {})
        ),

    // ArchiveStatus
    useArchiveInvoiceScope: () =>
        useApiPost<any, any>([KEYS.INVOICE_SCOPE_ARCHIVE, KEYS.INVOICE_SCOPE_BY_ID, KEYS.INVOICE_SCOPE], (params) =>
            post(`${URL_KEYS.InvoiceScope.InvoiceScope}/${params.id}/ArchiveStatus`, { archived: params.archived })
        ),

    // Download pdf
    useDownloadInvoiceScopePdf: () =>
        useApiPost<{ id: string, fileName?: string }, any>([KEYS.INVOICE_SCOPE_DOWNLOAD_PDF], ({ id, fileName }) =>
            getBlob(`${URL_KEYS.InvoiceScope.InvoiceScope}/${id}/DownloadPdf`, fileName ?? "default_filename")
        ),

    // Invoice

    // Create 
    useCreateInvoiceDraft: () =>
        useApiPost<Partial<DraftInvoiceCreateRequest>, DraftInvoice>([KEYS.INVOICE_CREATE, KEYS.CUSTOMER, KEYS.PROJECT, KEYS.CUSTOMERS_PROJECT, URL_KEYS.Lookup.Customers], (input) =>
            post(URL_KEYS.DraftInvoice.Invoice, input)
        ),

    // Update 
    useUpdateInvoiceDraft: () =>
        useApiPost<{ id: string, input: Partial<DraftInvoiceUpdateRequest> }, DraftInvoice>([KEYS.INVOICE_UPDATE, KEYS.INVOICES], (value) =>
            put(`${URL_KEYS.DraftInvoice.Invoice}/${value.id}`, value.input)
        ),

    // Download draft invoice
    useDownloadDraftInvoicePdf: () =>
        useApiPost<{ id: string, fileName?: string }, any>([KEYS.INVOICE_DOWNLOAD_PDF], ({ id, fileName }) =>
            getBlob(`${URL_KEYS.DraftInvoice.Invoice}/${id}/DownloadPdf`, fileName ?? "default_filename")
        ),

    // Send Invoice
    useSendInvoiceDraft: () =>
        useApiPost<{ invoiceId: string; promoCode?: string }, any>([KEYS.INVOICE_SEND, KEYS.INVOICE, KEYS.SUGGESTION, KEYS.INVOICE_SCOPE], (value) =>
            put(`${URL_KEYS.DraftInvoice.Invoice}/${value.invoiceId}/SendInvoice${value.promoCode ? `?promotionCode=${value.promoCode}` : ""}`)
        ),

    // Delete
    useDeleteInvoiceDraft: () =>
        useApiDelete<any, any>([KEYS.INVOICE, KEYS.INVOICE_DELETE], (id) =>
            remove(`${URL_KEYS.DraftInvoice.Invoice}/${id}`)
        ),

    //Change Service Fee Payer
    useChangeServiceFeePayer: () =>
        useApiPost<{ id: string, input: { serviceFeePayer: ServiceFeePayer } }, any>([KEYS.CHANGE_SERVICE_FEE_PAYER], (value) =>
            put(`${URL_KEYS.DraftInvoice.Invoice}/${value.id}/ChangeServiceFeePayer`, value.input)
        ),

    // Check Promotion Code
    useCheckPromotionCode: () =>
        useApiPost<any, any>([KEYS.CHECK_PROMOTION_CODE], (value) =>
            post(URL_KEYS.DraftInvoice.Invoice + '/' + value.id + '/CheckPromotionCode', value.input)
        ),

    //Quick Invoice
    useQuickInvoice: () => useApiPost<QuickInvoiceRequest, DraftInvoice>([KEYS.QUICK_INVOICE, KEYS.CUSTOMER, KEYS.PROJECT, URL_KEYS.Lookup.Customers], (input) =>
        post(URL_KEYS.DraftInvoice.QuickInvoice, input)
    ),

    // Freelancer

    // Invite
    useFreelancerInvite: () =>
        useApiPost<FreelancerFormInput, Freelancer>([KEYS.FREELANCER_INVITE, KEYS.FREELANCERS], (input) =>
            post(URL_KEYS.Freelancer.Freelancer, input)
        ),
    useUpdateFreelancer: () =>
        useApiPost<{ id: string, input: FreelancerUpdateFormInput }, Freelancer>([KEYS.FREELANCER_INVITE, KEYS.FREELANCERS, KEYS.FREELANCER], (input) =>
            put(`${URL_KEYS.Freelancer.Freelancer}/${input.id}`, input.input)
        ),

    // ArchiveStatus
    useArchiveFreelancer: () =>
        useApiPost<any, any>([KEYS.FREELANCER_ARCHIVE, KEYS.FREELANCER], (params) =>
            post(`${URL_KEYS.Freelancer.Freelancer}/${params.id}/ArchiveStatus`, { archived: params.archived })
        ),

    // Customer

    // Create 
    useCreateCustomer: () =>
        useApiPost<ClientInput, Client>([KEYS.CUSTOMER, URL_KEYS.Lookup.Customers], (input) =>
            post(URL_KEYS.Customer.Customer, input)
        ),

    // Update 
    useUpdateCustomer: () =>
        useApiPost<{ id: string, input: ClientInput }, Client>([KEYS.CUSTOMER, URL_KEYS.Lookup.Customers, KEYS.INVOICE], (value) =>
            put(`${URL_KEYS.Customer.Customer}/${value.id}`, value.input)
        ),

    // Delete
    useDeleteCustomer: () =>
        useApiDelete([KEYS.CUSTOMER, URL_KEYS.Lookup.Customers], (id: string) =>
            remove(`${URL_KEYS.Customer.Customer}/${id}`)
        ),

    // ArchiveStatus
    useArchiveCustomer: () =>
        useApiPost<any, any>([KEYS.CUSTOMER], (params) =>
            post(`${URL_KEYS.Customer.Customer}/${params.id}/ArchiveStatus`, { archived: params.archived })
        ),


    // InvoiceLine

    // Post
    usePostInvoiceLine: () =>
        useApiPost<any, InvoiceLine>([[KEYS.INVOICE_LINE], KEYS.INVOICE_LINE_CREATE], (value) =>
            post(URL_KEYS.InvoiceLine.Invoice + `/${value.id}/Lines`, value.input)
        ),

    // Put
    usePutInvoiceLine: () =>
        useApiPost<any, InvoiceLine>(
            [[KEYS.INVOICE_LINE], KEYS.INVOICE_LINE_UPDATE],
            (value) => put(`${URL_KEYS.InvoiceLine.Invoice}/${value.invoiceId}/Lines/${value.id}`, value.input), {}
        ),

    // Delete
    useDeleteInvoiceLine: () =>
        useApiDelete<any, InvoiceLine>(
            [[KEYS.INVOICE_LINE], KEYS.INVOICE_LINE_DELETE],
            (value) => remove(URL_KEYS.InvoiceLine.Invoice + `/${value.invoiceId}/Lines/${value.id}`), {}
        ),

    // InvoiceScopeFast

    //Approve
    useInvoiceFastApproval: () =>
        useApiPost<string, any>([KEYS.INVOICE_SCOPE, KEYS.SUGGESTION], (id) => put(`${URL_KEYS.InvoiceScopeFast.InvoiceScopeFast}/${id}/Approval`)
        ),
    useInvoiceFastDecline: () =>
        useApiPost<{ id: string; statusId: string | null; declineReason?: string }, any>(
            [KEYS.INVOICE_SCOPE, KEYS.SUGGESTION],
            ({ id, declineReason, statusId }) =>
                put(`${URL_KEYS.InvoiceScopeFast.InvoiceScopeFast}/${id}/Decline`, {
                    declineReason,
                    statusId
                })
        ),

    //Payment
    useGeneratePaymentLink: () =>
        useApiPost<string, { url: string }>([KEYS.INVOICE_SCOPE], (id: string) => {
            return post(`${URL_KEYS.InvoiceScope.InvoiceScope}/${id}/GeneratePaymentLink`);
        }),

    usePayWithCreditCard: () =>
        useApiPost<string, InvoiceScope>([KEYS.INVOICE_SCOPE, KEYS.SUGGESTION], (id) =>
            put(URL_KEYS.InvoiceScope.InvoiceScope + `/${id}/PayWithCreditCard`)
        ),


    //Project Invitation

    usePutProjectInvitation: () =>
        useApiPost<any, any>([KEYS.PROJECT_INVITATION, KEYS.SUGGESTION], (value) =>
            put(`${URL_KEYS.Project.Project}/${value.id}/InvitationResponse`, value.input)
        ),

    // Upload Profile Image
    useUploadProfileImage: () =>
        useApiPost<any, void>([KEYS.UPLOAD_PROFILE, KEYS.ACCOUNT, KEYS.PROFILE_IMAGE], (data) => {
            if (data && data.file) {
                const formData = new FormData();
                formData.append('file', data.file, data.file.name);
                return post(`${URL_KEYS.Account.AccountInfo}/ProfileImage`, formData);
            }
            throw new Error('No data provided for profile upload');
        }),

    //Delete Profile Image     
    useDeleteProfileImage: () =>
        useApiDelete([KEYS.DELETE_PROFILE, KEYS.ACCOUNT], () =>
            remove(`${URL_KEYS.Account.AccountInfo}/ProfileImage`)
        ),


    // Send email change pin
    useSendEmailChangePin: () =>
        useApiPost<{ password: string; email: string }, { verificationToken: string }>(
            [KEYS.ACCOUNT],
            (input) => post(URL_KEYS.Account.SendEmailChangePin, input)
        ),

    // Send email change pin
    useChangeEmail: () =>
        useApiPost<{ verificationToken: string; pin: string; }, any>(
            [KEYS.ACCOUNT],
            (input) => post(URL_KEYS.Account.ChangeEmail, input)
        ),
};
