import { useState, useEffect } from 'react';
import { DropDownOverlayHandler } from '../Types';

const useMobileDropDown = (dropDownOverlay: DropDownOverlayHandler): [boolean, VoidFunction] => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  useEffect(() => {
    dropDownOverlay(dropdownOpen);

    const handleResize = () => dropDownOverlay(dropdownOpen);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dropDownOverlay, dropdownOpen]);

  return [dropdownOpen, toggleDropdown];
};

export default useMobileDropDown;