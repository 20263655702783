import { getLocale } from "./getLocale";
import { getTimeZone } from "./getTimeZone";

type DateFormat = "long" | "medium" | "short";

function etcConverter(timeZone: string | undefined): string | undefined {
    if (!timeZone) {
        return timeZone;
    }

    const match = timeZone.match(/^Etc\/GMT([+-]\d+)$/);
    if (!match) {
        return timeZone;
    }

    const offset = match[1];
    const prefix = offset.startsWith("-") ? "Etc/GMT+" : "Etc/GMT-";
    return `${prefix}${offset.slice(1)}`;
}

export function convertToReadableDate(
    date: string,
    format: DateFormat = "short",
    customFormat?: Intl.DateTimeFormatOptions // instead of getting 2 different props for formatting unify the two by either getting a predefined string or a custom format object.
): string {
    try {
        if (!date)
            return "";

        const timeStamp = Date.parse(date);
        const locale = getLocale();
        const timeZone = etcConverter(getTimeZone() ?? Intl.DateTimeFormat().resolvedOptions().timeZone);
        const defaultOptions: Intl.DateTimeFormatOptions = {
            day: "2-digit",
            hourCycle: "h23",
            timeZone
        };
        let formattedOptions: Intl.DateTimeFormatOptions = {};

        switch (format) {
            case "long":
                formattedOptions = {
                    ...defaultOptions,
                    year: "numeric",
                    month: "short",
                    hour: "numeric",
                    minute: "numeric"
                };
                break;
            case "medium":
                formattedOptions = {
                    ...defaultOptions,
                    month: "short",
                    year: "numeric"
                };
                break;
            case "short":
                formattedOptions = { ...defaultOptions, month: "short" };
                break;
            default:
                break;
        }

        if (customFormat) {
            formattedOptions = { ...defaultOptions, ...customFormat };
        }

        return new Intl.DateTimeFormat(locale ?? "en-US", formattedOptions).format(timeStamp);
    } catch (ex) {
        throw { date, ex };
    }

}
