import { useTranslation } from 'react-i18next';
import BankAccounts from '../../../ProfileSettings/Tabs/BankAccounts';

export const BankAccountTab = () => {

  const { t } = useTranslation();

  return (
    <>
      <p className="bank-content">{t("AddYourBankAccountInformationToReceivePayments")}</p>
      <div className="profile-setup mb-0 pb-0 custom-scroll">
        <BankAccounts />
      </div>
    </>
  )
}
