import { UserVerification } from "./UserVerification";

export interface LoginFormData {
  email: string;
  password: string;
}

export const USER_ROLE = {
  Freelancer: "Freelancer",
  Business: "Business",
  TFAUser: "TFAUser",
} as const;

export type UserRole = (typeof USER_ROLE)[keyof typeof USER_ROLE];

export interface EmailFormProps {
  email: string;
}

export interface HeaderProps {
  showNav?: boolean;
  tabBadge?: boolean;
}

export interface PasswordStrengthProps {
  passwordStrength: string;
}

export interface User {
  userGuid: string;
  role: UserRole;
  authToken: string;
  expires: string;
  isSigningOut?: boolean;
}

export interface UserRoleContextProps {
  role: UserRole | null;
  setRole: (role: UserRole) => void;
}

export interface Account {
  // READ ONLY
  id: string;
  firstName: string;
  lastName: string;
  citizenshipCode: string;
  email: string;
  website: string;

  phoneCountryCode: string;
  phone: string;

  // invoice settings
  displayMyNameOnInvoices: boolean;

  // site settings
  defaultCurrencyCode: string;
  language: string;
  timeZone: string;

  // address , sole trader address, business address
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  countryCode: string;
  postalCode: string;

  definedBankAccounts: any[];
  identityVerified: boolean;
  emailVerified: boolean;
  phoneVerified: boolean;
  userVerification: UserVerification;

  avatarThumbnail?: string;
  registrationNumber: string;

  // Business or sole trader only
  companyName?: string;
  vatNumber: string;
  taxOffice: string;
  title: string;

  // questionnaire
  expertiseAreaCode: string;
  invoiceFrequencyCode: string;
  invoiceAmountEstimateCode: string;
  usualCustomerCountCode: string;

  //Profile Image
  hasProfileImage:boolean;
}

export interface SignupFormValues {
  email: string;
  password: string;
  confirmPassword: string;
  terms: boolean;
}

export interface SocialMediaProp {
  position?: string;
}

export interface VerificationToken {
  userVerification: UserVerification;
  token: string;
}

export const STEP = {
  Profile: 0,
  Verification: 1,
  BankAccount: 2,
  Agreements: 3
} as const;

export const STEP_STATE = {
  Completed: "completed",
  Pending: "pending",
  Waiting: "waiting",
  Declined: "declined"
} as const;

export type Step = ObjectValues<typeof STEP>;
export type StepState = ObjectValues<typeof STEP_STATE>;
export interface VerificationTabProp {
  navActive: number;
  stepState: StepState;
  fetchCompleted: (stepName: Step) => void;
}

export interface AgreementTabProp {
  navActive: number;
  stepState: StepState;
  fetchCompleted: (stepName: Step) => void;
}
export interface RouteWrapperProps {
  children: React.ReactElement;
}

export interface ImageSectionProp {
  userName: string;
  bannerClass?: string;
}

export interface GoogleLinkedinMediaProps {
  googleText: string;
  linkedinText: string;
}

export interface BusinessSignupFormValues {
  name: string;
  email: string;
  phone?: string;
  phoneCountryCode?: string;
  registrationNumber?: string;
  taxOffice?: string;
  vatNumber?: string;
  countryCode: string;
  state: string;
  city: string;
  postalCode: string;
  addressLine1: string;
  addressLine2?: string;
  terms: boolean;
}
