import axios, { AxiosRequestConfig } from "axios";

import { getToken, parseResponseBody, trackApiResponse } from "../utils";
import { appInsights } from "./appInsights";

async function put<TInput, TResponse>(url: string, data?: TInput): Promise<TResponse> {
    const token = getToken();
    const config: AxiosRequestConfig = {
        method: "PUT",
        url,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Request-Id": appInsights.context?.telemetryTrace?.traceID ?? ''
        },
        data
    };

    try {
        const response = await axios(config);
        return response.data;
    } catch (error) {
        // Handle errors from Axios
        if (axios.isAxiosError(error) && error.response) {
            const body = parseResponseBody(error.response);
            trackApiResponse(url, "PUT", body?.status, body?.data);
            // Throwing the error with status and data from the response
            throw body;
        }

        // Throwing a generic error if it's not an AxiosError
        throw error;
    }
}

export default put;