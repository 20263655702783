import { FC } from "react";
import { Link } from "react-router-dom";

import { Image } from "../../../AbstractElement";
import { ROUTES } from "../../../Constants";
import { dynamicImage } from "../../../utils";
import { useTranslation } from "react-i18next";
import SVG from "../../../CommonElement/SVG/Svg";
import { useAuth } from "../../../Hooks";

export const LogoWrapper: FC<{ returnUrl?: string | null; freelancer?: boolean; tabBadge?: boolean }> = ({ returnUrl, freelancer, tabBadge }) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <div className="logo-wrapper">
      <Link to={(user ? ROUTES.Dashboard : freelancer ? ROUTES.FreelancerLogIn : ROUTES.BusinessLogin) + (returnUrl ? `?returnUrl=${returnUrl}` : "")}>
        <Image src={dynamicImage("svg/logo/logo.svg")} className="auth-image" alt="logo" />
      </Link>
      {tabBadge &&
        <Link to={(freelancer ? ROUTES.BusinessLogin : ROUTES.FreelancerLogIn) + (returnUrl ? `?returnUrl=${returnUrl}` : "")} className="login-badge">
          <SVG iconId={`stroke-${freelancer ? "business" : "freelancer"}-login`} />
          {freelancer ? t("BusinessLogin") : t("FreelancerLogin")}
        </Link>
      }
    </div>
  );
};
