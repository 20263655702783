import React from 'react'
import { RouteWrapperProps } from '../Types';
import { useAuth } from '../Hooks';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from '../Constants';
import { getStorage } from '../utils';

const RedirectRouteWrapper: React.FC<RouteWrapperProps> = ({ children }) => {
    const { user } = useAuth();
    const location = useLocation();
    const storage = getStorage();

    if (user) {
        let returnUrl = storage.getItem("returnUrl");
        storage.removeItem("returnUrl");
        returnUrl = returnUrl || ROUTES.Home;

        return <Navigate to={returnUrl} state={{ from: { location } }} replace />;
    }

    return children;
}
export default RedirectRouteWrapper;