import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { Image } from '../../../../AbstractElement';
import { ROUTES } from '../../../../Constants';
import { dynamicImage } from '../../../../utils';

// Tabs
import { Header } from '../../../Auth/Widgets/Header';
import { AgreementTab } from './Tabs/Agreement';
import { BankAccountTab } from './Tabs/BankAccount';
import { ProfileTab } from './Tabs/Profile';
import { VerificationTab } from './Tabs/Verification';
import { WelcomeApp } from './Tabs/WelcomeApp';
import { queries } from '../../../../api';
import { USER_VERIFICATION } from '../../../../Types';
import { STEP, Step, STEP_STATE, StepState } from '../../../../Types/Auth/Auth';
import { useLoader } from '../../../../views/LoaderProvider';


type Steps = Record<Step, StepState>;


const ProfileSetup = () => {
  const { t } = useTranslation();
  const firstStep: number = 1;
  const [navActive, setNavActive] = useState(firstStep);
  const [visitedTabs, setVisitedTabs] = useState<number[]>([]);
  const [init, setInit] = useState(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const { isLoading } = useLoader();

  const [steps, setSteps] = useState<Steps>({
    [STEP.Profile]: STEP_STATE.Completed,
    [STEP.Verification]: STEP_STATE.Waiting,
    [STEP.BankAccount]: STEP_STATE.Waiting,
    [STEP.Agreements]: STEP_STATE.Waiting
  });
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [updated, setUpdated] = useState<number>(0);

  const { data: userDetails, isLoading: isUserLoading, refetch } = queries.useGetUserDetails();
  const {
    data: missingAgreements,
    isLoading: isAgreementsLoading,
    refetch: refetchAgreements
  } = queries.useGetMissingAgreements();

  useEffect(() => {
    setVisitedTabs((prevVisitedTabs) => {
      const updatedVisitedTabs = new Set(prevVisitedTabs);
      updatedVisitedTabs.add(navActive);
      return Array.from(updatedVisitedTabs);
    });
  }, [navActive]);

  const updateStep = useCallback((step: Step, value: StepState) => {
    setSteps((prev) => {
      return {
        ...prev,
        [step]: value
      };
    });
  }, []);

  useEffect(() => {
    if (!userDetails || !missingAgreements) return;

    const isVerificationInProgress = userDetails.userVerification === USER_VERIFICATION.InProgress;
    const isVerificationApproved = userDetails.userVerification === USER_VERIFICATION.Approved;
    const isVerificationDeclined = userDetails.userVerification === USER_VERIFICATION.Declined;
    const isBankAccountStepCompleted =
      userDetails.definedBankAccounts && userDetails.definedBankAccounts.length > 0;

    if (isVerificationInProgress) updateStep(STEP.Verification, STEP_STATE.Pending);
    if (isVerificationApproved) updateStep(STEP.Verification, STEP_STATE.Completed);

    if (isVerificationDeclined) updateStep(STEP.Verification, STEP_STATE.Declined);
    else if (isBankAccountStepCompleted) updateStep(STEP.BankAccount, STEP_STATE.Completed);

    if (isVerificationApproved || isVerificationInProgress) {
      let isAgreementStepCompleted = missingAgreements?.length === 0;
      if (isAgreementStepCompleted) {
        refetchAgreements().then((data) => {
          isAgreementStepCompleted = data.data?.length === 0;
          updateStep(STEP.Agreements, isAgreementStepCompleted ? STEP_STATE.Completed : STEP_STATE.Pending);
          setInit(true);
        });
      } else {
        updateStep(STEP.Agreements, isAgreementStepCompleted ? STEP_STATE.Completed : STEP_STATE.Pending);
        setInit(true);
      }
    }
    else
      setInit(true);
  }, [userDetails, missingAgreements]);

  const handleTabClick = (tabIndex: number) => {
    if (tabIndex >= 1 && tabIndex <= profileTabs.length) {
      setNavActive(tabIndex);
      query.delete('tab');
      navigate(`${location.pathname}`);
    }
  };

  const handleClick = () => {
    setIsComplete(true);
    setTimeout(() => {
      navigate(ROUTES.Dashboard);
    }, 2000)
  };

  useEffect(() => {
    const tab = query.get('tab');
    if (tab === 'verification') {
      setNavActive(2);
    }
    else if (tab === 'bank-account') {
      setNavActive(3);
    }
    else if (tab === 'agreements') {
      setNavActive(4);
    }
  }, [query]);

  const profileTabs = [
    { id: 1, title: 'Profile', nextButtonText: 'Identity verification', stepName: STEP.Profile },
    { id: 2, title: 'Verification', nextButtonText: 'Bank accounts', stepName: STEP.Verification },
    { id: 3, title: 'BankAccounts', nextButtonText: 'Agreements', stepName: STEP.BankAccount },
    { id: 4, title: 'Agreements', nextButtonText: 'Finish', stepName: STEP.Agreements },
  ];

  const fetchCompleted = (stepName: Step) => {
    if (stepName === STEP.Verification) {
      setTimeout(() => {
        refetch().then(x => {
          if (x.data?.userVerification === USER_VERIFICATION.Approved)
            refetchAgreements();
        });
      }, 2000);
    }
    else if (stepName === STEP.Agreements) {
      handleClick();
    }
  }

  return isComplete ? (
    <WelcomeApp />
  ) : (
    <div className="vh-100">
      <div className="auth-main h-100">
        <Image src={dynamicImage("svg/leaf.svg")} alt="leaf" className="leaf" />
        <Header showNav />
        <div className="auth-body h-100">
          <Container className="h-100 business-profile">
            <div className="auth-body-title t-padding b-padding-1">
              <div className="auth-sub-title">
                <h1>{navActive === profileTabs.length ? t("AlmostFinished") : t("LetsGetYouSetUp")}</h1>
                <Nav pills className="auth-tab d-lg-flex d-none" id="pills-tab">
                  {profileTabs.map(tab => (
                    <NavItem key={tab.id}>
                      <NavLink
                        className={`${navActive === tab.id ? "active" : ""} ${navActive === tab.id && steps[tab.stepName] !== STEP_STATE.Completed ? "verified-custom-orange" : ""} ${steps[tab.stepName] === STEP_STATE.Completed ? "verified-tab" : ""} ${visitedTabs.includes(tab.id) && steps[tab.stepName] !== STEP_STATE.Completed ? "verified-orange" : ""}`}
                        onClick={() => handleTabClick(tab.id)}
                        id={`pills-${tab.title?.toLowerCase()}-tab`}
                      >
                        <span>{t(tab.title)}</span>
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </div>
            </div>
            <TabContent activeTab={navActive} id="pills-tabContent create-account-tab">
              <TabPane tabId={1} id="pills-profileTab" className="position-relative">
                <ProfileTab />
              </TabPane>
              <TabPane tabId={2} id="pills-verificationTab" className="position-relative custom-scroll">
                <VerificationTab navActive={navActive} stepState={steps[STEP.Verification]} fetchCompleted={fetchCompleted} />
              </TabPane>
              <TabPane tabId={3} id="pills-bankAccountTab" className="position-relative bank-account-tab custom-scroll">
                <BankAccountTab />
              </TabPane>
              <TabPane tabId={4} id="pills-agreementTab" className="position-relative custom-scroll agreementTab-tab">
                <AgreementTab navActive={navActive} stepState={steps[STEP.Agreements]} fetchCompleted={fetchCompleted} />
              </TabPane>
              <div className="auth-footer-main">
                <div className={`auth-footer ${navActive == firstStep ? "justify-content-end" : ""}`}>
                  {navActive !== firstStep && <Button
                    color='transparent'
                    className="btn-gray"
                    onClick={() => handleTabClick(navActive - 1)}
                  >
                    <Image src={dynamicImage("svg/left-arrow.svg")} alt="arrow" />
                    {t("Previous")}
                  </Button>}
                  {navActive === firstStep && <Button
                    color='transparent'
                    className="btn-gray d-block"
                    onClick={() => navigate(ROUTES.Dashboard)}
                  >
                    {t("SkipForNow")}
                  </Button>}

                  {
                    profileTabs[navActive - 1].nextButtonText &&
                    <Button className="btn-solid"
                      color='primary'
                      onClick={() => profileTabs.length === navActive ? handleClick() : handleTabClick(navActive < profileTabs.length ? navActive + 1 : navActive)}
                      disabled={navActive === 2 && steps[STEP.Verification] !== STEP_STATE.Completed || navActive === 4 && steps[STEP.Agreements] !== STEP_STATE.Completed}
                    >
                      {t(profileTabs[navActive - 1].nextButtonText)}
                      <Image src={dynamicImage("svg/icon_arrow_white.svg")} alt="arrow" />
                    </Button>
                  }
                </div>
              </div>
            </TabContent>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ProfileSetup;
