export interface Agreement {
    id: string;
    contractType: ContractType;
    versionNumber: string;
    fileName: string;
    status: string;
    createdAt: string;
}

export const CONTRACT_TYPE = {
    PrivacyPolicy: "PrivacyPolicy",
    TermsAndConditions: "TermsAndConditions",
    FreelancerAgreement: "FreelancerAgreement",
    ServiceContracts: "ServiceContracts",
    PaymentAgreement: "PaymentAgreement"
} as const;

export type ContractType = ObjectValues<typeof CONTRACT_TYPE>;

