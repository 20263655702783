import { DotLottieReact } from "@lottiefiles/dotlottie-react";

const Loader = () => {
  return (
    <div className="loader-wrapper">
      <DotLottieReact src="https://lottie.host/f66edc3f-38bb-4e06-9a16-0c848ec1245a/EkWtXvkrz6.lottie" backgroundColor="transparent" speed={1} style={{ width: "150px", height: "150px" }} loop autoplay />
    </div>
  );
};
export default Loader;
