/* Main Header */
import { Row } from "reactstrap";
import LogoWrapper from "./LogoWrapper";
import CommonHeader from "../../CommonComponent/Header";
import { useLocation } from "react-router-dom";
import { useLayoutContext } from "../../views/LayoutProvider";

const Header = () => {
  const location = useLocation();
  const path = location.pathname.split("/");
  const {openSidebar} = useLayoutContext()
  
  return (
    <Row id="page-headers" className={`${path[1] === "project" ? "project-header" : ""} page-header ${openSidebar?'close_icon':''}`}>
      <LogoWrapper />
      <CommonHeader path={path} />
    </Row>
  );
};
export default Header;
