import { useQuery, UseQueryOptions } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import useAuth from "./useAuth";
import { CombinedErrorResponse } from "../Types/ErrorResponse";
import { HTTP_STATUS, ROUTES } from "../Constants";
import useToast from "../views/ToastWrapper";
import { getMessages } from "../utils";
import { useLoader } from "../views/LoaderProvider";

function useApiGet<T>(
    key: unknown[],
    callback: (param?: any) => Promise<T>,
    options?: UseQueryOptions<T, CombinedErrorResponse>,
    method: string = 'GET' // Default to 'GET'
) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { removeUser } = useAuth();
    const { showToast } = useToast();
    const { showLoader, hideLoader } = useLoader();

    return useQuery<T, CombinedErrorResponse>([...key], async (context) => {
        if (method === 'GET') {
            showLoader();  // Show loader when it's a GET request
        }

        try {
            const result = await callback(context);
            return result;
        } finally {
            // This ensures the loader hides no matter what
            hideLoader();
        }
    }, {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: true,
        keepPreviousData: true,
        onError: (error: CombinedErrorResponse) => {
            if (error.status === HTTP_STATUS.UNAUTHORIZED) {
                removeUser();
                navigate(ROUTES.FreelancerLogIn + `?returnUrl=${pathname}`, {
                    replace: true
                });
            } else {
                if (error.status === HTTP_STATUS.BAD_REQUEST || error.status === HTTP_STATUS.UNPROCESSABLE_ENTITY) {
                    showToast("error", getMessages(error));
                }
                else if (error.status !== HTTP_STATUS.CONFLICT) {
                    showToast("error", "OccurredUnknownError");
                }
            }
        },
        ...options
    });
}

export default useApiGet;

