import React from "react";

import { Image } from "../../../AbstractElement";
import { dynamicImage } from "../../../utils";
import { useTranslation } from "react-i18next";
import { PasswordStrengthProps } from "../../../Types/Auth/Auth";

const PassStrength: React.FC<PasswordStrengthProps> = ({ passwordStrength }) => {
  const { t } = useTranslation();

  return (
    <>
      <ul className={`password-indicate ${passwordStrength.replace(" ", "-")}`}>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div className="password-indicate-note">
        <div className="indicate">
          <span className="text-capitalize">{t(passwordStrength)}</span>
          {passwordStrength !== "strong" &&
            <div className="more-info">
              <Image src={dynamicImage("svg/icon_tip.svg")} alt="" className="tip" />
              <div className="info">
                <div className="info-icon">
                  <Image src={dynamicImage("svg/info.svg")} alt="" />
                </div>
                <p>{t("PasswordHint")}</p>
              </div>
            </div>}
        </div>
      </div>
    </>
  );
};

export default PassStrength;
