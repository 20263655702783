import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../Hooks";
import { queries } from "../api";
import { HTTP_STATUS, ROUTES } from "../Constants";
import { CONTRACT_TYPE, RouteWrapperProps, USER_ROLE } from "../Types";
import Loader from "../Layout/Loader";

const ProtectedRouteWrapper: React.FC<RouteWrapperProps> = ({ children }) => {
    const { user, signout } = useAuth();
    const location = useLocation();
    const { data: missingAgreements } = queries.useGetMissingAgreements(!!user);
    const { data: userDetails, error } = queries.useGetUserDetails(!!user);

    function getReturnUrlQueryParam() {
        if (location.pathname && location.pathname !== "/") {
            return `?returnUrl=${location.pathname}`;
        }
        return "";
    }

    if (!user) {
        setTimeout(() => { window.location.href = ROUTES.FreelancerLogIn + getReturnUrlQueryParam(); }, 100)
        return <Loader />;
    }

    if (user.isSigningOut) {
        setTimeout(() => { window.location.href = (user.role === USER_ROLE.Business ? ROUTES.BusinessLogin : ROUTES.FreelancerLogIn); }, 100)
        return <Loader />;
    }

    if (user.role === USER_ROLE.TFAUser) {
        signout();
        return <Loader />;
    }

    if (error && error.status === HTTP_STATUS.FORBID) {
        signout();
        return <Loader />;
    }

    if (!userDetails || !missingAgreements) return <Loader />;

    if (userDetails && user.role === USER_ROLE.Freelancer && !userDetails.emailVerified) {
        return <Navigate to={ROUTES.EmailVerification} replace />;
    }
    if (
        missingAgreements.length > 0 &&
        (missingAgreements.includes(CONTRACT_TYPE.PrivacyPolicy) || missingAgreements.includes(CONTRACT_TYPE.TermsAndConditions))
    ) {
        return <Navigate to={ROUTES.AgreementPolicy} state={{ agreement: missingAgreements }} replace />;
    }

    return children;
};

export default ProtectedRouteWrapper;