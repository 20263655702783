import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, FormGroup, Input, Row } from "reactstrap";

export const ProfileTab = () => {
  const { t } = useTranslation();
  const [businessType, setBusinessType] = useState("freelancer");

  const handleBusinessTypeChange = (event: any) => {
    setBusinessType(event.target.id);
  };

  return (
    <>
      <p className="mb-0">{t("ToCompleteYourRegistrationAnswerFewQuestionsAboutYourBusiness")}</p>
      <Row>
        <Col xl="10" xs="12">
          <div className="profile-setup mb-0 custom-scroll">
            <Form noValidate>
              <Row className="g-3 profile-setup-field">
                <Col sm="6" xs="12">
                  <label>{t("WhatTypeOfBusinessDoYouHave")}</label>
                  <div className="form-check form-check-white">
                    <Input type="radio" name="profile" className="form-radio-input" defaultChecked id="freelancer" onChange={handleBusinessTypeChange} />
                    <label htmlFor="freelancer">{t("Freelancer")}</label>
                  </div>
                </Col>
                <Col sm="6" xs="12">
                  <label className="opacity-0">{t("WhatTypeOfBusinessDoYouHave")}</label>
                  <div className="form-check form-check-white">
                    <Input type="radio" name="profile" className="form-radio-input" id="sole-trader" disabled onChange={handleBusinessTypeChange} />
                    <label htmlFor="sole-trader">{t("SoleTrader")}</label>
                  </div>
                </Col>
              </Row>
              {businessType === "sole-trader" && (
                <Row className="profile-setup-field g-sm-3 g-0">
                  <label>{t("SoleTraderTaxInformation")}</label>
                  <Col sm="6" xs="12">
                    <FormGroup>
                      <label>{t("TaxOffice")}</label>
                      <input type="text" className="form-control" id="taxOffice" placeholder={t("EnterTaxOffice")} autoComplete="off"/>
                    </FormGroup>
                  </Col>
                  <Col sm="6" xs="12">
                    <FormGroup>
                      <label>{t("RegistrationNumber")}</label>
                      <input className="form-control" id="vat" placeholder={t("EnterRegistrationNumber")} type="text" autoComplete="off"/>
                    </FormGroup>
                  </Col>
                  <Col sm="6" xs="12">
                    <FormGroup>
                      <label>{t("VatNumber")}</label>
                      <input className="form-control" id="vat" placeholder={t("EnterVATNumber")} type="text" autoComplete="off"/>
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </Form>
          </div>
        </Col>
      </Row>
    </>
  )
}
