const strongPasswordRules = [
  (value: string) => value.length >= 8,
  (value: string) => /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value),
  (value: string) => /[a-z]/.test(value),
  (value: string) => /[A-Z]/.test(value),
  (value: string) => /\d/.test(value),
];

function getPasswordStrength(value: string): string {
  const score = strongPasswordRules.reduce((acc, rule) => acc + (rule(value) ? 1 : 0), 0);

  if (score <= 2) {
    return "weak";
  } else if (score === 3 || score === 4) {
    return "medium";
  } else {
    return "strong";
  }
}

export default getPasswordStrength;
