/* Main layout file including header, sidebar, loader, outlet(inner pages in it) */
import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import Header from "./Header";
import Sidebar from "./Sidebar/Sidebar";
import { ROUTES } from "../Constants";
import { queries } from "../api";
import { useAuth } from "../Hooks";
import { appInsights } from "../api/appInsights";
import { getCookie } from "../utils/cookie";
import { useLoader } from "../views/LoaderProvider";
import { LayoutLoader } from "../CommonComponent/loader";
import { useTranslation } from "react-i18next";

const Layout: React.FC = () => {
  const [dashClass, setDashClass] = useState(false);
  const location = useLocation();
  const { data: userDetails } = queries.useGetUserDetails();
  const { user } = useAuth();
  const role = user?.role;
  const { isLoading } = useLoader();
  const { t } = useTranslation();
  // const prevScrollPosRef = useRef(window.scrollY);

  useEffect(() => {
    const body = document.body;
    if (location.pathname === ROUTES.Dashboard) {
      body.classList.add("main-dashboard");
    } else {
      body.classList.remove("main-dashboard");
      if (body.classList.length == 0) {
        body.removeAttribute("class");
      }
    }
  }, [location]);

  //TODO:It can be connected to solve header hide issue
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentScrollPos = window.scrollY;

  //     if (currentScrollPos > prevScrollPosRef.current) {
  //       // Scrolling down
  //       document.body.classList.remove('page-up');
  //       document.body.classList.add('page-down');
  //     } else if (currentScrollPos < prevScrollPosRef.current) {
  //       // Scrolling up
  //       document.body.classList.remove('page-down');
  //       document.body.classList.add('page-up');
  //     }

  //     // At the top of the page
  //     if (currentScrollPos === 0) {
  //       document.body.classList.add('page-fixed');
  //     } else {
  //       document.body.classList.remove('page-fixed');
  //     }

  //     // Update the ref with the current scroll position
  //     prevScrollPosRef.current = currentScrollPos;
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, []);
  const prevScrollPosRef = useRef(0); // Correct: Defined at the top level of the component

  useEffect(() => {

    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset || document.documentElement.scrollTop;

      // At the top of the page
      if (currentScrollPos === 0) {
        document.body.classList.add("page-fixed");
        document.body.classList.remove("page-down", "page-up");
      } else {
        // Remove `page-fixed` when not at the top
        document.body.classList.remove("page-fixed");

        // Detecting scroll direction
        if (currentScrollPos > prevScrollPosRef.current) {
          // Scrolling down
          document.body.classList.add("page-down");
          document.body.classList.remove("page-up");
        } else if (currentScrollPos < prevScrollPosRef.current) {
          // Scrolling up
          document.body.classList.add("page-up");
          document.body.classList.remove("page-down");
        }
      }

      // Update the ref with the current scroll position
      prevScrollPosRef.current = currentScrollPos;
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll); // Cleanup on unmount
  }, []);

  //TODO:It can be connected to solve header hide issue
  // useEffect(() => {
  //   const SCROLL_BUFFER = 10; // pixels from the top/bottom to consider as boundaries
  //   let isAtBottom = false; // Track if we’re at the bottom to prevent bounce
  //   let isAtTop = false; // Track if we’re at the top to prevent bounce
  
  //   const handleScroll = () => {
  //     const currentScrollPos = window.scrollY;
  //     const maxScrollPos = document.documentElement.scrollHeight - window.innerHeight;
  
  //     // Detecting end of page
  //     if (currentScrollPos >= maxScrollPos - SCROLL_BUFFER) {
  //       if (!isAtBottom) {
  //         isAtBottom = true; // Set flag to ignore further bottom scroll events
  //         document.body.classList.remove('page-up');
  //         document.body.classList.add('page-down');
  //       }
  //     } else {
  //       isAtBottom = false; // Reset flag when not at the bottom
  
  //       // Detecting scroll direction
  //       if (currentScrollPos > prevScrollPosRef.current) {
  //         document.body.classList.remove('page-up');
  //         document.body.classList.add('page-down');
  //       } else if (currentScrollPos < prevScrollPosRef.current) {
  //         document.body.classList.remove('page-down');
  //         document.body.classList.add('page-up');
  //       }
  //     }
  
  //     // Detecting top of page
  //     if (currentScrollPos <= SCROLL_BUFFER) {
  //       if (!isAtTop) {
  //         document.body.classList.add('page-fixed');
  //         isAtTop = true;
  //       }
  //     } else {
  //       document.body.classList.remove('page-fixed');
  //       isAtTop = false;
  //     }
  
  //     // Update the ref with the current scroll position
  //     prevScrollPosRef.current = currentScrollPos;
  //   };
  
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, []);
  

  useEffect(() => {
    if (location.pathname === ROUTES.Dashboard) {
      setDashClass(true);
    } else {
      setDashClass(false);
    }
  }, []);

  useEffect(() => {
    const telemetryInitializer = (envelope: any) => {
      envelope.data = envelope.data || {};
      envelope.data["email"] = userDetails?.email ?? "";
    };
    appInsights.addTelemetryInitializer(telemetryInitializer);

    (window as any).dataLayer = (window as any).dataLayer || [];
    if (userDetails?.id) {
      // for google tag manager
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
        user_id: userDetails?.id ?? "",
        email: userDetails?.email ?? "",
        fullName: (userDetails?.firstName ?? "") + (userDetails?.lastName ?? ""),
        customer_type: role ?? ""
      });
    }

    var clientId = getCookie("_ga");
    if (clientId) {
      clientId = clientId.substring(6);
    }
    (window as any).dataLayer.push({ client_id: clientId ?? "" });
    (window as any)?.dataLayer.push({ event: "user_changed" });
  }, [userDetails]);

  useEffect(() => {
    const setVh = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    setVh(); // Set on initial render
    window.addEventListener('resize', setVh); // Update on resize
    // Cleanup event listener on unmount
    return () => window.removeEventListener('resize', setVh);
  }, []);

  return (
    <>
      {/*
      //TODO:It can be connected later
      <div className="rotate-message">
        {t("PleaseRotateYourScreenBackToPortraitMode")}
      </div>
      */}
      <div className="page-wrapper">
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <div className={`page-body ${dashClass ? "w-user-name" : ""}`}>
            {isLoading && <LayoutLoader text="PleaseWait" />}
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
