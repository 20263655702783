import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { Image, SolidButton } from '../../../../../../AbstractElement';
import { dynamicImage } from '../../../../../../utils';
import { queries } from '../../../../../../api';
import { AccountDetails } from './AccountDetails';

const BankAccounts = () => {

  const { t } = useTranslation();

  const [addAccount, setAddAccount] = useState(false);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [openFormIndex, setOpenFormIndex] = useState<number | null>(null); // Track which form is open

  const { data: bankAccounts } = queries.useGetBankAccounts();

  useEffect(() => {
    // Open the form by default if there is only one bank account
    if (bankAccounts?.items.length === 0) {
      setAddAccount(true);
    }
  }, [bankAccounts]);


  return (
    <Row>
      <Col xs="12">
        <div className="setting-main">
          <div className="transaction-detail">
            <ul className="transaction-list custom-scroll">
              {bankAccounts?.items.map((account, i) => (
                <li key={i} >
                  <AccountDetails setAddAccount={setAddAccount} rowIndex={i} action="edit" account={account} setEdit={setEdit} isOpenForm={openFormIndex === i} // Check if the current form is open
                    setOpenFormIndex={setOpenFormIndex} // Pass function to set open form
/>
                </li>
              ))}
              {addAccount && <AccountDetails action="create" setAddAccount={setAddAccount} setEdit={setEdit} isOpenForm={openFormIndex === -1} 
                    setOpenFormIndex={setOpenFormIndex} />}
            </ul>
          </div>
        </div>
      </Col>
      <Col xs="12">
        <div className="d-flex justify-content-sm-end justify-content-center mt-5 bank-btn">
          <SolidButton onClick={() => setAddAccount(true)} disabled={addAccount || isEdit}>
            <Image src={dynamicImage("svg/plus-white.svg")} alt="arrow" />
            {t("AddAccount")}
          </SolidButton>
        </div>
      </Col>
    </Row>
  )
}
export default BankAccounts;