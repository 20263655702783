import { Col, Row } from "reactstrap";
import ImageSection from "../../Widgets/ImageSection";
import BusinessSignInForm from "./LoginForm";
import { LogoWrapper } from "../../Widgets/LogoWrapper";
import { useEffect } from "react";
import { getParam } from "../../../../utils";
import GoogleLinkedinMedia from "../../Widgets/GoogleLinkedinMedia";
import { useLayoutContext } from "../../../../views/LayoutProvider";

const BusinessLogin = () => {
  const { isMobile } = useLayoutContext();
  const returnUrl = getParam("returnUrl");
  useEffect(() => {
    if (!returnUrl) {
      sessionStorage.removeItem("returnUrl");
      return;
    }
    sessionStorage.setItem("returnUrl", returnUrl);
  }, [returnUrl]);

  return (
    <div className="log-in-section">
      <Row className="login-content g-0">
        <Col xl="" lg="7" md="9" sm="11" className="auth-left">
          <div className="login-main">
            <LogoWrapper returnUrl={returnUrl} tabBadge />
            <BusinessSignInForm />
            <ul className="social-media">
              <GoogleLinkedinMedia googleText={isMobile ? "Google" : "LoginWithGoogle"} linkedinText={isMobile ? "LinkedIn" : "LoginWithLinkedIn"} />
            </ul>
          </div>
        </Col>
        <ImageSection userName="Businesses" bannerClass="business-banner" />
      </Row>
    </div>
  );
};

export default BusinessLogin;
