import { FC, useEffect, useState } from 'react'
import { Button, Offcanvas, OffcanvasBody } from 'reactstrap';
import { useStateContext } from '../../../views/stateProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Image, SolidButton } from '../../../AbstractElement';
import { dynamicImage } from '../../../utils';
import { ROUTES, URL_KEYS } from '../../../Constants';
import { useAuth } from '../../../Hooks';
import { mutations, queries } from '../../../api';
import { INVOICE_STATUS, USER_ROLE } from '../../../Types';
import { CommonSelect, Info } from '../../../CommonComponent';
import PdfViewer from '../../../CommonComponent/PdfViewer';
import useToast from '../../../views/ToastWrapper';
import Loader from '../../../Layout/Loader';
import ConfirmationDialog from '../../../CommonComponent/ConfirmationDialog';

const ViewInvoiceModal: FC<{ id: string, draftInvoice?: boolean }> = ({ id, draftInvoice = false }) => {
    const { t } = useTranslation();
    const { user } = useAuth()
    const { showToast } = useToast();
    const navigate = useNavigate();

    const { viewInvoice, toggleInvoiced, toggleInvoiceDetail, invoiceScope } = useStateContext();
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [isApproved, setIsApproved] = useState(false);
    const [isRejecting, setIsRejecting] = useState(false);
    const [reasonSelected, setReasonSelected] = useState<string | null>(null);
    const invoiceFileName = 'default_filename.pdf';

    const pdfEndpointUrl = draftInvoice ? `${URL_KEYS.DraftInvoice.Invoice}/${id}/DownloadPdf` : `${URL_KEYS.InvoiceScope.InvoiceScope}/${id}/DownloadPdf`

    const { mutate: downloadInvoiceScopePdf, isLoading: downloadingInvoiceScope } = mutations.useDownloadInvoiceScopePdf();
    const { mutate: downloadDraftInvoicePdf, isLoading: downloadingDraftInvoice } = mutations.useDownloadDraftInvoicePdf();
    const { mutate: archiveInvoiceScope, isLoading: isArchiving } = mutations.useArchiveInvoiceScope();
    const { mutate: confirmInvoice, isLoading: confirmLoading } = mutations.useInvoiceFastApproval();
    const { data: paginated } = queries.useGetInvoiceDeclineReasons(INVOICE_STATUS.WaitingForPayment, { enabled: user?.role !== USER_ROLE.Freelancer });
    const { mutate: declineInvoice, isLoading: rejectLoading } = mutations.useInvoiceFastDecline();
    const { mutate: refetchDuplicate } = mutations.useDuplicateInvoice();


    const handleDownloadInvoice = async () => {
        if (draftInvoice === true)
            downloadDraftInvoicePdf({ id: id });
        else
            downloadInvoiceScopePdf({ id: id });
    };

    const handleArchive = async () => {
        if (!invoiceScope?.id) return;

        try {
            const newArchiveStatus = !invoiceScope.archived;
            await archiveInvoiceScope({
                id: id,
                archived: newArchiveStatus,
            });
            toggleInvoiced()
            toggleInvoiceDetail()
            if (newArchiveStatus) {
                showToast("delete", 'InvoiceDetailCanvas.InvoiceArchivedSuccessfully');
            } else {
                showToast("success", 'InvoiceDetailCanvas.InvoiceUnarchivedSuccessfully');
            }
        } catch (error) {
        }
    };

    // Handle approval button click: Toggle checkbox and buttons
    const handleApprove = () => {
        setIsCheckboxChecked(true);
        setIsApproved(true);
    };

    // Handle confirm final approval
    const handleConfirmApproval = () => {
        confirmInvoice(id, {
            onSuccess: () => {
                handleApprove();
                toggleInvoiced()
                showToast("success", "ViewInvoiceModal.InvoiceApproved");
            },
        });
    }

    const handleConfirmReject = () => {
        ConfirmationDialog({
            title: "Reject Invoice",
            text: "You're about to reject the invoice. This is a permanent change that cannot be undone.Are you sure you want to reject this invoice?",
            confirmButtonText: "Reject",
            onConfirm: (isConfirmed) => {
                if (isConfirmed) {
                    declineInvoice(
                        { id: id, statusId: reasonSelected },
                        {
                            onSuccess: () => {
                                showToast("error", "ViewInvoiceModal.InvoiceRejected");
                            },
                        }
                    );
                }
            }
        });
    }

    const handleReject = () => {
        setIsRejecting(true)
    }

    const duplicate = () => {
        refetchDuplicate(id, {
            onSuccess: (response) => {
                const newInvoiceId = response?.invoiceId;
                if (newInvoiceId) {
                    toggleInvoiced()
                    navigate(`${ROUTES.InvoiceEdit.replace(':id', newInvoiceId)}`);
                } else {
                    console.error('No invoice ID found in the duplicate response');
                }
            },
        });
    }

    useEffect(() => {
        setIsCheckboxChecked(false);
        setIsApproved(false);
        setIsRejecting(false);
        setReasonSelected(null);
    }, [id]);

    if (!id)
        return <Loader />;

    return (
        <Offcanvas direction="end" isOpen={true} toggle={toggleInvoiced} className='full-offcanvas-theme theme-offcanvas-2'>
            <div className="theme-offcanvas-box">
                <div className="w-100">
                    <Button color='transparent' onClick={toggleInvoiced} typeof="button" className="btn-close" />
                    <OffcanvasBody className='custom-scroll d-grid'>
                        <div className="pdf-box custom-scroll">
                            <PdfViewer
                                pdfEndpointUrl={pdfEndpointUrl}
                                defaultFileName={invoiceFileName}
                            />
                        </div>
                        {!isRejecting && !isApproved && (<ul className="invoice-action">
                            <li className="cursor-pointer" onClick={handleDownloadInvoice}>
                                <Image src={dynamicImage("svg/download.svg")} alt="download" /> {downloadingDraftInvoice || downloadingInvoiceScope ? t("Downloading") : t("Download")}</li>
                            {user?.role === USER_ROLE.Freelancer && !draftInvoice && <li className="cursor-pointer" onClick={() => duplicate()}><Image src={dynamicImage("svg/duplicate.svg")} alt="duplicate" />{t("Duplicate")}</li>}
                            {user?.role === USER_ROLE.Business && !draftInvoice && invoiceScope?.id && <li className="cursor-pointer" onClick={handleArchive}>
                                <Image src={dynamicImage("svg/Vector-1.svg")} alt="archive" />
                                {isArchiving ? t('Archiving') : invoiceScope?.archived ? t('UnarchiveInvoice') : t('ArchiveInvoice')}
                            </li>}
                        </ul>)}

                        {!draftInvoice && isApproved &&
                            <div className="confirm-invoice-approval approved">
                                <h2>{t("ConfirmInvoiceApproval")}</h2>
                                <div className="form-check check-required">
                                    <input
                                        type="checkbox"
                                        id="invoiceApprovalCheckbox"
                                        checked={isCheckboxChecked}
                                        className='form-check-input '
                                        onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                                    />
                                    <label htmlFor="invoiceApprovalCheckbox">
                                        {t("IConfirmThatIHaveReceivedTheCompletedWorkAndApproveTheInvoice")}
                                        <Info text='' />
                                    </label>

                                </div>
                            </div>
                        }
                        {!draftInvoice && isRejecting && paginated?.items && (
                            <div className="confirm-invoice-approval approved">
                                <h2>{t("ConfirmInvoiceRejection")}</h2>
                                <div className="confirm-check-box">
                                    <CommonSelect
                                        placeholder={t('Select')}
                                        options={paginated?.items.map(res => ({ value: res.id, label: t(res.name) }))}
                                        selectedOptions={reasonSelected}
                                        setSelectedOptions={(value) => {
                                            setReasonSelected(value)
                                        }}
                                        multiSelect={false}
                                    />
                                    {paginated.items.find(res => res.id === reasonSelected)?.entryDescriptionIsRequired &&
                                        <textarea className="form-control min-h-96" rows={3} placeholder={t("EnterAnotherReason")}></textarea>
                                    }

                                    <div className='footer-buttons mt-3'>
                                        <SolidButton className="btn-gray" onClick={() => { setIsCheckboxChecked(false); setIsApproved(false); }}>
                                            {t("Cancel")}
                                        </SolidButton>
                                        <SolidButton className="btn-success" onClick={handleConfirmApproval} loading={confirmLoading}
                                            disabled={!isCheckboxChecked}
                                        >
                                            {t("Confirm")}
                                        </SolidButton>
                                    </div>
                                </div>
                            </div>
                        )}
                        {!isRejecting && !isApproved && (
                            <>
                                <div className="footer-buttons">
                                    <SolidButton color="transparent" className="btn-gray close-btn d-block w-100" onClick={toggleInvoiced}>
                                        {t("Close")}
                                    </SolidButton>
                                    {!draftInvoice && user?.role === USER_ROLE.Business && (
                                        <>
                                            {invoiceScope?.invoiceStatus === INVOICE_STATUS.WaitingForApproval &&
                                                <>
                                                    <SolidButton className="btn-dark-gray d-block w-100" onClick={handleReject}>
                                                        {t("Reject")}
                                                    </SolidButton>
                                                    <SolidButton className="btn-success d-block w-100" onClick={handleApprove}>
                                                        {t("Approve")}
                                                    </SolidButton>
                                                </>
                                            }

                                            {invoiceScope?.invoiceStatus === INVOICE_STATUS.WaitingForPayment &&
                                                <SolidButton className="justify-content-center btn-success w-100" onClick={() => {
                                                    navigate(ROUTES.Payment.replace(':id', invoiceScope?.id.toString()));
                                                    toggleInvoiced();
                                                }}>{t("Pay")}</SolidButton>
                                            }

                                            {invoiceScope?.invoiceStatus === INVOICE_STATUS.WaitingForPaymentAgreement &&
                                                <SolidButton className="justify-content-center btn-success w-100" onClick={() => {
                                                    navigate(ROUTES.PaymentAgreement.replace(':id', invoiceScope?.id.toString()));
                                                    toggleInvoiced();
                                                }}>{t("ContinueToAgreement")}</SolidButton>
                                            }
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                         {!draftInvoice && isRejecting && (
                            <div className='footer-buttons'>
                                <SolidButton className="btn-gray" onClick={() => setIsRejecting(false)}>
                                    {t("Cancel")}
                                </SolidButton>
                                <SolidButton className="custom-confirm-black"
                                    disabled={!reasonSelected}
                                    loading={rejectLoading} onClick={handleConfirmReject}>
                                    {t("Reject")}
                                </SolidButton>
                            </div>
                        )}
                        {!draftInvoice && isApproved && (
                            <div className='footer-buttons'>
                                <SolidButton className="btn-gray" onClick={() => { setIsCheckboxChecked(false); setIsApproved(false); }}>
                                    {t("Cancel")}
                                </SolidButton>
                                <SolidButton className="btn-success" onClick={handleConfirmApproval} loading={confirmLoading}
                                    disabled={!isCheckboxChecked}
                                >
                                    {t("Confirm")}
                                </SolidButton>
                            </div>
                        )} 
                    </OffcanvasBody>
                </div>
            </div>
        </Offcanvas>
    )
}

export default ViewInvoiceModal;

/* Html table design */
{/* <table width="100%" border={0} cellPadding="0" cellSpacing="0" align="center" className="" bgcolor="#ffffff" >
    <tr>
        <td height="34" className="tab-view"></td>
    </tr>
    <tr>
        <td>
            <table width="950" cellPadding="0" cellSpacing="0" align="center" className="fullTable" bgcolor="#ffffff" style={{ boxShadow: "-4px 0px 40px 0px #430B0B33", borderCollapse: "separate", borderRadius: "12px 12px 0 0" }}>
                <tbody>
                    <tr>
                        <span onClick={toggleInvoiced} className="curser-pointer close-btn" style={{ marginLeft: "auto", marginTop: 30 + "px", marginRight: 30 + "px", borderRadius: 100 + "%", borderColor: "#d4cfd4", borderStyle: "solid", borderWidth: 1 + "px", height: "40px", width: "40px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Image src={dynamicImage("svg/close.svg")} width="16" height="16" alt="logo" border="0" />
                        </span>
                    </tr>
                    <tr>
                        <td>
                            <table width="800" cellPadding="0" cellSpacing="0" align="center" className="FullPadding" style={{ borderStyle: "dashed", borderWidth: 1 + "px", borderColor: "#D4CFD4", borderRadius: 10 + "px", overflow: "visible", borderCollapse: "separate" }} bgcolor="#ffffff">
                                <tr>
                                    <td height="30"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <PdfViewer
                                            clientId={clientId}
                                            pdfEndpointUrl={pdfEndpointUrl}
                                            bearerToken={bearerToken}
                                            defaultFileName={invoiceFileName}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td height="30"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table width="800" border={0} cellPadding="0" cellSpacing="0" align="center" className="FullPadding">
                                <tbody>
                                    <tr>
                                        <td height="50" className="mobile-view"></td>
                                    </tr>
                                    <tr>
                                        <td height="1" style={{ borderWidth: '1px', borderStyle: 'solid', borderColor: '#D4CFD4' }} colSpan={4}></td>
                                    </tr>
                                    <tr className="mobile-view-tr">
                                        <td style={{ color: "#8588A6", fontSize: "14px", lineHeight: "18px", fontWeight: "500", textAlign: "end", paddingRight: "50px", paddingBlock: "px" }} onClick={handleDownloadInvoice}>
                                            <div className='curser-pointer' style={{ display: "flex", gap: "8px", justifyContent: "end", fontSize: "14px", fontWeight: 700, color: "#433B55" }}>
                                                <Image src={dynamicImage("svg/download.svg")} width="20" height="20" alt="logo" border="0" />
                                                {t("Download")}
                                            </div>
                                        </td>
                                        <td style={{ color: "#8588A6", fontSize: "14px", lineHeight: "18px", fontWeight: "500", textAlign: "left", paddingLeft: "50px", paddingBlock: "16px" }} onClick={handleArchive}>
                                            <div className='curser-pointer' style={{ display: "flex", gap: "8px", justifyContent: "start", fontSize: "14px", fontWeight: 700, color: "#433B55" }}>
                                                <Image src={dynamicImage("svg/Vector-1.svg")} width="20" height="20" alt="logo" border="0" />
                                                {isArchiving ? t('Archiving...') : invoiceScope?.archived ? t('UnarchiveInvoice') : t('ArchiveInvoice')}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td height="1" style={{ borderWidth: '1px', borderStyle: 'solid', borderColor: '#D4CFD4' }} colSpan={4}></td>
                                    </tr>
                                    <tr>
                                        <td height="50" className="mobile-view"></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SolidButton color="transparent" className="justify-content-center btn-gray btn-solid" onClick={toggleInvoiced}>
                                                {t("Close")}
                                            </SolidButton>
                                        </td>
                                        <td>
                                            <SolidButton className="btn-dark-gray btn-solid">
                                                {t("Reject")}
                                            </SolidButton>
                                        </td>
                                        <td>
                                            <SolidButton className="btn-success btn-solid">
                                                {t("Approve")}
                                            </SolidButton>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td height="40" className="mobile-view"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </td>
    </tr>
    <tr>
        <td height="50" className="tab-view"></td>
    </tr>
</table> */}