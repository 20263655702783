import { useCallback, useEffect, useState } from "react";
import { Params } from "../Types/CommonElement";
import useDebounce from "./useDebounce";

const useTableFilterHelper = (initialParams?: Params) => {
    // Options for FilterMenu

    const archiveOptions = [
        { label: 'Show All', value: 'all', archiveStatus: null },
        { label: 'Show Active', value: 'active', archiveStatus: false },
        { label: 'Show Archived', value: 'archived', archiveStatus: true },
    ];

    const [pageNumber, setPageNumber] = useState(initialParams?.PageSize ?? 1);
    const [pageSize, setPageSize] = useState(initialParams?.PageSize ?? 5);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const [sortBy, setSortBy] = useState<string | null>(initialParams?.sortBy ?? null);
    const [sortType, setSortType] = useState<"asc" | "desc">(initialParams?.sortType ?? "desc");
    const [archiveStatus, setArchiveStatus] = useState<boolean | null>(initialParams?.archiveStatus ?? null);

    // TODO: Default values will be prevented from being sent to the API.
    const [params, setParams] = useState<Params>({
        PageNumber: 1,
        PageSize: 5,
        search: "",
        ...initialParams
    });

    useEffect(() => {
        if (!!sortBy && !!sortType) {
            setParams((prevParams) => ({
                ...prevParams,
                OrderBy: `${sortBy} ${sortType ?? "asc"}`
            }));
        }
    }, [sortBy, sortType]);

    useEffect(() => {
        setParams((prevParams) => ({
            ...prevParams,
            PageNumber: 1,
            search: debouncedSearchTerm
        }));
    }, [debouncedSearchTerm]);

    const handleSetSearch = useCallback((e: string) => {
        document.body.classList.add('no-loader');
        setSearchTerm(e);
    }, []);

    const toggleSortType = useCallback((item: any) => {
        setSortType((prev) => {
            if (prev === "asc") {
                item.sortDirection = "desc"
                return "desc";
            } else {
                item.sortDirection = "asc"
                return "asc";
            }
        });
    }, []);

    const handleSetSortBy = useCallback((e: string) => {
        setSortBy(e);
    }, []);

    const goToPage = useCallback((e: number) => {
        setPageNumber(e);

        setParams((prevParams) => ({
            ...prevParams,
            PageNumber: e
        }));
    }, []);

    const handleSetPageSize = useCallback((e: number) => {
        setPageNumber(1);
        setPageSize(e);
        setParams((prevParams) => ({
            ...prevParams,
            PageSize: e,
            PageNumber: 1
        }));
    }, []);

    const handleSetArchiveStatus = useCallback((selectedOptions: string) => {

        let archiveStatus = archiveOptions.find(f => f.value === selectedOptions)?.archiveStatus ?? null;

        setPageNumber(1);
        setArchiveStatus(archiveStatus);

        setParams((prevParams) => ({
            ...prevParams,
            PageNumber: 1,
            archiveStatus: archiveStatus
        }));
    }, []);

    return {
        pageNumber,
        pageSize,
        searchTerm,
        sortBy,
        sortType,
        params,
        archiveStatus,
        archiveOptions,

        setParams,
        handleSetSearch,
        toggleSortType,
        handleSetSortBy,
        goToPage,
        handleSetPageSize,
        handleSetArchiveStatus
    };
};

export default useTableFilterHelper;
