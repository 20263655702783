import { Form, FormGroup, Modal, ModalBody, ModalHeader } from "reactstrap"
import { useStateContext } from "../../../../../views/stateProvider"
import { useLayoutContext } from "../../../../../views/LayoutProvider"
import { useTranslation } from "react-i18next"
import { Image, SolidButton } from "../../../../../AbstractElement"
import { mutations } from "../../../../../api"
import { useForm } from "react-hook-form"
import LoginSchema from "../../../../../Schemas/loginSchema"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup";
import { dynamicImage } from "../../../../../utils"
import { useState } from "react"

const EmailChange = () => {
    const { emailChangeModal, toggleEmailChangeModal, toggleEmailChangeOtpModal } = useStateContext()
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const togglePassword = () => setShowPassword(!showPassword);
    const { modalPosition } = useLayoutContext()
    const { t } = useTranslation()
    const { EmailChangeSchema } = LoginSchema();
    const { mutate: sendEmailChangePin, isLoading } = mutations.useSendEmailChangePin()

    const { register, handleSubmit, formState: { errors } } = useForm<EmailChangeData>({ resolver: yupResolver(EmailChangeSchema) });

    type EmailChangeData = Yup.InferType<typeof EmailChangeSchema>;

    const handleContinue = (data: EmailChangeData) => {
        sendEmailChangePin({ email: data.email, password: data.password }, {
            onSuccess: (response) => {
                toggleEmailChangeOtpModal(response.verificationToken, data.email)
                toggleEmailChangeModal()
            }
        })
    }

    return (
        <Modal centered dir={modalPosition} isOpen={emailChangeModal} toggle={toggleEmailChangeModal} className="theme-modal change-address account-modal">
            <Form onSubmit={handleSubmit(handleContinue)} >
                <ModalHeader toggle={toggleEmailChangeModal}>{t("ChangeEmailAddress")}</ModalHeader>
                <ModalBody>
                    <div className="deletion custom-scrollbar">
                        <div className="mb-20">
                            <label>{t("CurrentPassword")}</label>
                            <FormGroup className="form-group">
                            <div className="position-relative">
                                <input
                                    {...register("password")}
                                    className={`form-control ${errors.password ? "required-field" : ""}`}
                                    id="user-password"
                                    placeholder={t("EnterCurrentPassword")}
                                    type={showPassword ? "text" : "password"}
                                    autoComplete="new-password"
                                />
                                <div className="toggle-password" onClick={togglePassword}>
                                    <Image src={dynamicImage(`svg/${showPassword ? "icon_showpasson.svg" : "icon_showpassoff.svg"}`)} className="auth-image" alt="show-password" />
                                </div>
                            </div>
                            {errors.password && (
                                <span className="required-note">
                                    <small>{t(errors.password.message || "")}</small>
                                </span>
                            )}
                            </FormGroup>
                        </div>
                        <div>
                            <label>{t("EnterNewEmailAddressYouWantToAssociateWithYourAccount")}</label>
                            <input
                                {...register("email")}
                                className={`form-control ${errors.email ? "required-field" : ""}`}
                                id="new-email"
                                placeholder={t("Enter new email address...")}
                                type="email"
                                autoComplete="off"
                            />
                            {errors.email && (
                                <span className="required-note">
                                    <small>{t(errors.email.message || "")}</small>
                                </span>
                            )}
                        </div>
                    </div>
                </ModalBody>
                <div className="modal-footer">
                    <SolidButton className='btn-gray justify-content-center' onClick={toggleEmailChangeModal}>{t("Cancel")}</SolidButton>
                    <SolidButton type="submit" loading={isLoading}>{t("Continue")}</SolidButton>
                </div>
            </Form>
        </Modal>
    )
}

export default EmailChange
