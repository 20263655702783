import { useEffect } from "react";

const useGTM = (tagManagerId: string) => {
  useEffect(() => {
    if (!tagManagerId) {
      console.warn("Google Tag Manager ID is not defined");
      return;
    }
    if (document.querySelector(`iframe[src*="ns.html?id=${tagManagerId}"]`)) {
      console.warn("GTM script is already added to the document.");
      return;
    }

    const script = document.createElement("noscript");
    script.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${tagManagerId}" height="0" width="0" style="display: none; visibility: hidden"></iframe>`;
    document.body.appendChild(script);

    const configScript = document.createElement("script");
    configScript.innerHTML = `(function (w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != "dataLayer" ? "&l=" + l : ""; j.async = true; j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl; f.parentNode.insertBefore(j, f); })(window, document, "script", "dataLayer", "${tagManagerId}")`;
    document.head.appendChild(configScript);

    return () => {
      if (script) document.head.removeChild(script);
      if (configScript) document.head.removeChild(configScript);
    };
  }, [tagManagerId]);
};

export default useGTM;
