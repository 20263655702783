import { useTranslation } from 'react-i18next'
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { SolidButton } from '../../../../../AbstractElement'
import { useStateContext } from '../../../../../views/stateProvider'
import { useLayoutContext } from '../../../../../views/LayoutProvider'
import { useCoreData } from '../../../../../Hooks'
import { useState } from 'react'

const AccountDeletionReason = () => {
    const { accountDeleteReasonModal, toggleAccountDeleteReasonModal, toggleAccountDeleteConfirmModal } = useStateContext()
    const { modalPosition } = useLayoutContext()
    const { t } = useTranslation()
    const { userAccountDeletionReasonOptions } = useCoreData({ loadUserAccountDeletionReasons: true });

    const [selectedReason, setSelectedReason] = useState<string>('');

    const handleRadioChange = (e: any) => {
        setSelectedReason(e.target.value);
    };

    return (
        <Modal centered dir={modalPosition} isOpen={accountDeleteReasonModal} toggle={toggleAccountDeleteReasonModal} className="theme-modal account-deletion account-modal">
            <ModalHeader toggle={toggleAccountDeleteReasonModal}>{t("AccountDeletionModal.Title")}</ModalHeader>
            <ModalBody>
                <div className='deletion'>
                    <p>{t("WeAreSorryToSeeYouGoWouldYouLikeToHelpUsImproveJuuliByDescribingWhyYouWantToDeleteYourAccount")}</p>
                    <div className='deletion-height custom-scrollbar'>
                            <Row>
                                {userAccountDeletionReasonOptions?.map((reason, index) => (
                                    <Col key={index} md="9" xs="12">
                                        <div className="form-control form-control-white mb-3 rounded-form">
                                            <div className="form-check">

                                                <input
                                                    name="deleteQuestion-radio"
                                                    id={`deletion_reason_${reason.value}`}
                                                    type="radio"
                                                    className="form-radio-input form-check-input"
                                                    value={reason.value}
                                                    checked={selectedReason === reason.value}
                                                    onChange={handleRadioChange}
                                                />

                                                <label htmlFor={`deletion_reason_${reason.value}`} className="cursor-pointer w-100 form-label">{t(reason?.label)}</label>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                    </div>
                </div>
                <div className="modal-footer">
                    <SolidButton className="btn-gray justify-content-center" onClick={toggleAccountDeleteReasonModal}>{t("Cancel")}</SolidButton>
                    <SolidButton type="submit" color='transparent' className="btn-dark-gray" disabled={!selectedReason} onClick={() => { toggleAccountDeleteConfirmModal(selectedReason); toggleAccountDeleteReasonModal() }}>{t("Continue")}</SolidButton>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default AccountDeletionReason